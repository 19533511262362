import * as React from 'react';

export function EmptySearchIcon() {
  return (
    <svg width="167px" height="169px" viewBox="0 0 167 169" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>A68F0220-ADCA-4A8F-BEC9-CEF12296AA97</title>
      <g id="V1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="FM_sem-resultado" transform="translate(-107.000000, -216.000000)" fillRule="nonzero">
          <g id="FM_Produto-nao-encontrado" transform="translate(107.000000, 216.000000)">
            <path d="M75.1803715,74.9912714 L142.973954,74.9912714 C143.020307,74.980186 143.068612,74.980186 143.114965,74.9912714 L94.3686672,33.5031221 C92.8591473,32.3969774 90.9132699,32.079595 89.1315929,32.648929 C87.349916,33.2182631 85.94663,34.605869 85.3548324,36.3834888 L65.5157184,81.9693673 C65.6116826,81.6878063 65.7390358,81.4179909 65.8953624,81.1650385 C67.5836705,77.5196268 71.172291,75.1334894 75.1803715,74.9912714 Z" id="Path" fill="#B3B3B3" opacity="0.6" />
            <path d="M133.829956,125.609774 L114.826059,125.609774 C113.819635,125.609774 113.003767,124.792092 113.003767,123.783596 C113.003767,122.7751 113.819635,121.957552 114.826059,121.957552 L133.829956,121.957552 C135.626013,121.980218 137.290657,121.016906 138.168745,119.446742 L147.518836,102.534098 C149.039491,99.8451809 151.886395,98.1841823 154.970707,98.1863729 L165.177708,98.1863729 C166.184132,98.1863729 167,99.0039227 167,100.012419 C167,101.020915 166.184132,101.838463 165.177708,101.838463 L155.003248,101.838463 C153.247628,101.85074 151.633199,102.8048 150.772928,104.338404 L141.336061,121.251047 C139.806999,123.959468 136.935299,125.626986 133.829956,125.609774 L133.829956,125.609774 Z" id="Path" fill="#000000" />
            <path d="M65.5157184,81.9693673 C67.0604019,77.9202532 70.858412,75.178005 75.1803715,74.9912714 L143.114965,74.9912714 L64.051377,46.9484563 C60.198232,45.8812253 56.1950117,48.0697334 55.0050013,51.8939916 L20.9780462,134.500734 C20.466481,136.169286 20.6971231,137.977804 21.6109934,139.46382 C22.5248636,140.949836 24.0333528,141.969264 25.7507145,142.26142 L37.281047,146.81566 L65.5157184,81.9693673 Z" id="Path" fill="#B3B3B3" opacity="0.6" />
            <path d="M52.2064822,125.609646 L33.2025851,125.609646 C30.1150086,125.613466 27.2641129,123.952556 25.7398675,121.261916 L16.2704599,104.338404 C15.4053713,102.787093 13.7701843,101.826418 11.9967524,101.827593 L1.8222915,101.827593 C0.815867697,101.827593 0,101.010046 0,100.00155 C0,98.9930534 0.815867697,98.1755036 1.8222915,98.1755036 L12.0292933,98.1755036 C15.1136054,98.173313 17.9605085,99.8343116 19.4811639,102.523229 L28.9180307,119.435873 C29.78672,120.988905 31.425897,121.949486 33.2025851,121.946683 L52.2064822,121.946683 C53.212906,121.946683 54.0287737,122.76423 54.0287737,123.772727 C54.0287737,124.781223 53.212906,125.59877 52.2064822,125.59877 L52.2064822,125.609646 Z" id="Path" fill="#000000" />
            <path d="M100.963627,167.380391 L143.114965,74.9912714 L75.1803715,74.9912714 C70.8664383,75.1725799 67.0706247,77.9004355 65.5157184,81.9367594 L31.0765783,161.065323 C29.7640946,164.956535 32.844635,168.934702 37.2159652,169 L98.3712003,169 C99.4752939,169.008281 100.485064,168.377462 100.963627,167.380391 Z" id="Path" fill="#838383" />
            <ellipse id="Oval" fill="#000000" transform="translate(81.388871, 111.408529) rotate(-39.920000) translate(-81.388871, -111.408529) " cx="81.3888714" cy="111.408529" rx="5.05897689" ry="3.54039985" />
            <ellipse id="Oval" fill="#000000" transform="translate(99.238849, 111.412604) rotate(-39.920000) translate(-99.238849, -111.412604) " cx="99.2388487" cy="111.412604" rx="5.05897689" ry="3.54039985" />
            <path d="M91.7762406,135.946352 C91.3425792,135.982516 90.9165471,135.816018 90.621827,135.495195 C90.3271069,135.174373 90.196717,134.735167 90.2685113,134.305086 C90.2685113,133.77249 90.5613796,128.935648 84.1291244,128.935648 C77.6968693,128.935648 74.0522863,134.229001 73.8895817,134.457256 C73.208062,135.563287 71.9007533,136.113487 70.6354897,135.826789 C69.6375682,135.457233 69.4531697,134.25074 70.2124578,133.120332 C72.2191478,130.174749 78.0114315,124.609663 85.6368537,124.609663 C93.620226,124.609663 95.1171083,130.261704 94.7916991,133.250763 C94.5426113,134.523857 93.5840895,135.539359 92.3294362,135.859397 C92.1487839,135.908651 91.9632663,135.937812 91.7762406,135.946352 L91.7762406,135.946352 Z" id="Path" fill="#000000" />
            <path d="M69.4748636,30.7314485 C68.7896851,30.9071071 68.0839502,30.5299401 67.8478176,29.8619039 L66.8498961,27.3076165 C66.6765307,26.9626251 66.6646784,26.5583784 66.8175321,26.2037829 C66.9703858,25.8491875 67.2722223,25.5807186 67.6417251,25.4707034 C70.4402442,24.3837726 71.731034,22.916416 71.2320733,20.9925484 L71.2320733,20.9273326 C70.7873474,19.2208512 69.1603014,18.3730451 66.9692128,18.9491185 C65.4109,19.3535443 64.0719302,20.3520387 63.237854,21.7316614 C62.9924932,22.085498 62.6320735,22.3429219 62.2182385,22.459905 C61.5059707,22.6526873 60.7496442,22.3761349 60.3286736,21.7689817 C59.907703,21.1618284 59.9129219,20.3550808 60.3417121,19.7534473 C61.6332679,17.6088846 63.7331894,16.0767432 66.1665368,15.5035478 C70.4402442,14.416617 73.9655105,16.003536 74.9634321,19.8512711 L74.9634321,19.9164869 C75.9722006,23.764222 73.6509483,26.2424243 70.4185503,27.7097809 L70.6680307,29.1553989 C70.7674885,29.8526394 70.3432828,30.5179808 69.6701091,30.7205792 L69.4748636,30.7314485 Z M70.0606002,32.970526 C70.6246556,32.8008772 71.2333024,32.8683088 71.7467502,33.1573336 C72.2601981,33.4463584 72.6343202,33.9321369 72.7831904,34.5030985 L72.8591193,34.8074391 C73.0528818,35.5840851 72.8184414,36.4053194 72.2441096,36.9617912 C71.6697779,37.518263 70.8428093,37.7254308 70.074715,37.5052566 C69.3066207,37.2850824 68.7140925,36.6710159 68.52033,35.8943699 L68.4444011,35.5900293 C68.3110079,35.0278295 68.4064885,34.4355443 68.7097486,33.9440263 C69.0130087,33.4525083 69.4990889,33.1022114 70.0606002,32.970526 Z" id="Shape" fill="#000000" />
            <path d="M88.381138,15.1122527 C87.7137039,14.8757458 87.3338217,14.1708116 87.5025331,13.4818565 L88.0774227,10.7971373 C88.1248161,10.4152157 88.3381147,10.073764 88.6602331,9.86416548 C88.9823515,9.65456699 89.3804031,9.59822065 89.7478566,9.71020653 C92.6439984,10.3949729 94.5639127,9.84063823 95.1713432,7.96024791 L95.1713432,7.89503206 C95.7353858,6.2211586 94.845934,4.63423961 92.6982333,3.88425734 C91.1812632,3.36956784 89.5239014,3.46703113 88.0774227,4.15599005 C87.6736395,4.29765781 87.233804,4.29765781 86.8300208,4.15599005 C85.9512618,3.83326494 85.4757176,2.88021824 85.7453235,1.98212841 C85.9257603,1.47504438 86.3239787,1.07600599 86.8300208,0.895197596 C89.0687231,-0.167841234 91.6388053,-0.289173458 93.9673292,0.558249042 C98.1434139,1.97125911 100.193492,5.26465948 98.9352429,9.0145708 L98.9352429,9.0145708 C97.6227592,12.8405673 94.3686672,13.6340268 90.8325539,13.0362148 L90.2468174,14.3731397 C89.956193,15.013455 89.237083,15.3388831 88.5655365,15.1339913 L88.381138,15.1122527 Z M87.6435438,17.2861143 C88.2060896,17.4577499 88.6750028,17.8508711 88.9428802,18.3754348 C89.2107577,18.8999984 89.2546477,19.5110597 89.0644973,20.0686572 L88.9668745,20.3729978 C88.5104075,21.462902 87.2901479,22.0130464 86.1734191,21.632405 C85.0566902,21.2517637 84.4242894,20.0701329 84.725708,18.9273799 L84.8233307,18.6339085 C85.0119603,18.0756332 85.4187312,17.6180394 85.9504298,17.3659872 C86.4821284,17.1139349 87.0931745,17.0890315 87.6435438,17.2969836 L87.6435438,17.2861143 Z" id="Shape" fill="#000000" />
          </g>
        </g>
      </g>
    </svg>
  );
}
