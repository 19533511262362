import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiTable } from 'react-icons/gi';
import {
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../UI';
import CartFormTableChooseModal from './ChooseModal';

const CartFormTable = ({
  userTable,
  tableSelectedByDefault,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <UICustomInputLabel
        isRequired
        isValited={userTable}
        labelTitle="Mesa do cliente"
      />

      {userTable ? (
        <UIButtonToEdit
          className="mb-20"
          icon={<GiTable />}
          disabled={tableSelectedByDefault}
          noRightIcon={tableSelectedByDefault}
          onClick={tableSelectedByDefault ? null : () => setShowModal(true)}
          firstElement={(
            <strong>
              Mesa
              {' '}
              {userTable}
            </strong>
      )}
        />
      ) : (
        <UIButton
          outline
          className="mb-20"
          text="Selecione a mesa do cliente"
          onClick={() => setShowModal(true)}
        />
      )}

      {showModal && (
        <CartFormTableChooseModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};

CartFormTable.propTypes = {
  userTable: PropTypes.string.isRequired,
  tableSelectedByDefault: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  userTable: state.user.table,
  tableSelectedByDefault: state.user.tableSelectedByDefault,
});

export default connect(mapStateToProps, null)(CartFormTable);
