import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import { GrLocation } from 'react-icons/gr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { UIButton, UIFixedBottom } from '../../../UI';
import * as UserActions from '../../../../store/modules/user/actions';
import * as S from './styles';
import { getWhatsAppLink } from '../../../../utils/whatsapp/getLink';

const CartAddressNeighborhoodSelection = ({
  deliveryRates,
  isOnlineCommand,
  establishmentPhone,
  setSelectedNeighborhood,
}) => {
  const { ratesByNeighborhood } = deliveryRates;
  const [userNeighborhood, setUserNeighborhood] = useState('');
  const linkToTalkWithEstablishment = getWhatsAppLink(establishmentPhone, 'Olá, tentei fazer um pedido mas não achei meu bairro na lista de entrega');

  return (
    <>
      <span className="mb-20" style={{ marginTop: -10 }}>
        {isOnlineCommand ? 'Qual o bairro do cliente ?' : 'Qual o seu bairro ?'}
      </span>
      <S.CartAddressNeighborhoodSelection>
        <ul className="mb-20">
          {ratesByNeighborhood.map(({ neighborhood, active }) => {
            if (!active) return null;

            return (
              <S.Neighborhood key={neighborhood}>
                <label className="container" htmlFor={neighborhood}>
                  <input
                    type="radio"
                    id={neighborhood}
                    name="neighborhood"
                    value={neighborhood}
                    onChange={() => setUserNeighborhood(neighborhood)}
                  />
                  <div className="infos">
                    <strong>{neighborhood}</strong>
                  </div>
                  <GrLocation />
                  <Ink />
                </label>
              </S.Neighborhood>
            );
          })}
        </ul>
        <S.NotFoundNeighborhoodAlert>
          Se o seu bairro não apareceu em nenhuma das opções, o restaurante pode
          ter esquecido de cadastrar ou não existe entrega disponível para sua
          região.
          {' '}
          <a href={linkToTalkWithEstablishment} rel="noreferrer" target="_blank">Entre em contato com o restaurante e confirme</a>
        </S.NotFoundNeighborhoodAlert>
        <UIFixedBottom>
          <UIButton
            text="Confirmar bairro"
            disabledAppearance={!userNeighborhood}
            functionWhenDisable={() => toast.error('Selecione um bairro')}
            onClick={() => {
              setSelectedNeighborhood(userNeighborhood);
            }}
          />
        </UIFixedBottom>
      </S.CartAddressNeighborhoodSelection>
    </>
  );
};

CartAddressNeighborhoodSelection.propTypes = {
  isOnlineCommand: PropTypes.bool.isRequired,
  establishmentPhone: PropTypes.string.isRequired,
  setSelectedNeighborhood: PropTypes.func.isRequired,
  deliveryRates: PropTypes.shape({
    type: PropTypes.string.isRequired,
    ratesByNeighborhood: PropTypes.arrayOf(PropTypes.shape({
      active: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      maxTime: PropTypes.number,
      minTime: PropTypes.number,
      neighborhood: PropTypes.string.isRequired,
      rate: PropTypes.number,
      rateToBeAgreed: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  deliveryRates: state.establishment.deliveryRates,
  isOnlineCommand: state.establishment.isOnlineCommand,
  establishmentPhone: state.establishment.phoneForOrder,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators(UserActions, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartAddressNeighborhoodSelection);
