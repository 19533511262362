import { removeDuplicatesOnArray } from './removeDuplicatesOnArray';
import { orderByLargestToSmallest } from './orderBy';

export const getProductPriceByInfos = (product) => {
  const {
    selectedVariations, price, selectedAdditionals, promotionalPrice, additionals, variations,
  } = product;
  const variationsToUse = selectedVariations || variations;
  const additionalsToUse = selectedAdditionals || additionals;

  const haveSelectedVariations = !!(
    variationsToUse && variationsToUse.length > 0
  );

  function getPriceBySelectedVariations() {
    if (haveSelectedVariations) {
      const varPromotionalPrice = variationsToUse[0].promotionalPrice;
      if (varPromotionalPrice && Number(varPromotionalPrice) > 0) {
        return Number(variationsToUse[0].promotionalPrice);
      }

      return Number(variationsToUse[0].price);
    }

    if (promotionalPrice && Number(promotionalPrice) > 0) {
      return Number(promotionalPrice);
    }

    return Number(price);
  }

  function getAdditionalsPrice() {
    if (additionalsToUse && additionalsToUse.length > 0) {
      const someChargeForTheMostExpensive = additionalsToUse.some(
        ({ chargeForTheMostExpensive }) => chargeForTheMostExpensive === true,
      );

      if (someChargeForTheMostExpensive) {
        const allGroupsName = removeDuplicatesOnArray(
          additionalsToUse.map(({ groupName }) => groupName),
        );

        const additionalsOrganizedByGroup = allGroupsName.reduce(
          (acc, gName) => {
            const additionalsInThisGroup = additionalsToUse.filter(
              ({ groupName }) => groupName === gName,
            );

            acc.push({
              groupName: gName,
              additionals: additionalsInThisGroup,
              chargeForTheMostExpensive: additionalsInThisGroup.some(
                ({ chargeForTheMostExpensive }) => chargeForTheMostExpensive === true,
              ),
            });

            return acc;
          },
          [],
        );

        const additionalsPrice = additionalsOrganizedByGroup.reduce(
          (acc, additionalGroup) => {
            if (additionalGroup.chargeForTheMostExpensive) {
              const orderedAdditionals = orderByLargestToSmallest(
                additionalGroup.additionals,
                'price',
              );

              return acc + Number(orderedAdditionals[0].price);
            }

            return (
              acc
              + additionalGroup.additionals.reduce(
                (acc2, { price: additionalPrice, quantity }) => (
                  (acc2 + Number(additionalPrice) * quantity)
                ), 0,
              )
            );
          },
          0,
        );

        return additionalsPrice;
      }

      return additionalsToUse.reduce(
        (acc, { price: additionalPrice, quantity }) => acc + Number(additionalPrice) * quantity,
        0,
      );
    }

    return 0;
  }

  const priceBySelectedVariation = getPriceBySelectedVariations();

  return parseFloat(priceBySelectedVariation + getAdditionalsPrice());
};
