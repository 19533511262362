import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const addCurrentPathOnRN = (history) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ currentPathUrl: history.location.pathname }),
    );
  }
};

export const URLChangeListenerForRN = () => {
  const history = useHistory();

  useEffect(() => {
    addCurrentPathOnRN(history);

    const unlisten = history.listen(() => {
      addCurrentPathOnRN(history);
      ReactPixel.pageView();
    });

    return unlisten;
  }, [history]);

  return <></>;
};
