import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BsCalendar2Event } from 'react-icons/bs';
import dayjs from 'dayjs';
import * as UserActions from '../../../../store/modules/user/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import CartScheduleOrderModal from './ChooseModal';
import { getCartTotal } from '../../../../store/selectors';
import {
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../UI';
import 'dayjs/locale/pt-br';

const CartFormScheduleOrder = ({
  isOpen,
  isScheduled,
  scheduledData,
  isOnlineCommand,
  informationalUse,
  allowScheduleOrders,
  hasValidDaysToScheduleOrders,
  establishmentAllowJustScheduledOrders,
}) => {
  const [showModal, setShowModal] = useState(false);
  const showScheduledComponent = (isScheduled
  || establishmentAllowJustScheduledOrders
  || (!isOpen && allowScheduleOrders));

  const getScheduledDeliveryDateText = () => {
    if (!scheduledData?.deliveryDateTimeEnd) return '';

    const endDeliveryDate = dayjs(scheduledData.deliveryDateTimeEnd);
    const startDeliveryDate = dayjs(scheduledData.deliveryDateTimeStart);

    const weekday = dayjs(startDeliveryDate).locale('pt-br').format('dddd');
    const day = dayjs(startDeliveryDate).format('DD/MM');
    const startHour = dayjs(startDeliveryDate).format('HH:mm');
    const endHour = dayjs(endDeliveryDate).format('HH:mm');

    return `${weekday} (${day}) entre ${startHour} e ${endHour}`;
  };

  if (!hasValidDaysToScheduleOrders || !showScheduledComponent) {
    return null;
  }

  return (
    <>
      <UICustomInputLabel
        labelTitle="Agendamento"
        isValited={!!scheduledData?.deliveryDateTimeStart}
        isRequired={!isOnlineCommand && !informationalUse}
      />

      {scheduledData?.deliveryDateTimeStart ? (
        <UIButtonToEdit
          className="mb-20"
          icon={<BsCalendar2Event />}
          disabled={informationalUse}
          noRightIcon={informationalUse}
          onClick={() => setShowModal(true)}
          firstElement={(
            <span className="title">
              Entrega agendada para:
            </span>
          )}
          secondElement={(
            <strong>
              {getScheduledDeliveryDateText()}
            </strong>
          )}
        />
      ) : (
        <UIButton
          outline
          className="mb-20"
          text="Agendar pedido"
          onClick={() => setShowModal(true)}
        />
      )}

      <CartScheduleOrderModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

CartFormScheduleOrder.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  allowScheduleOrders: PropTypes.bool.isRequired,
  hasValidDaysToScheduleOrders: PropTypes.bool.isRequired,
  establishmentAllowJustScheduledOrders: PropTypes.bool.isRequired,
  scheduledData: PropTypes.shape({
    deliveryDateTimeEnd: '',
    deliveryDateTimeStart: '',
  }).isRequired,
  userPaymentMethod: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    changeValue: PropTypes.string,
    flag: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  cartTotalNum: getCartTotal(state),
  isOpen: state.establishment.isOpen,
  isScheduled: state.cart.isScheduled,
  scheduledData: state.cart.scheduledData,
  userPaymentMethod: state.user.paymentMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
  allowScheduleOrders: state.establishment.scheduleOrders.active,
  establishmentAllowJustScheduledOrders:
    state.establishment.scheduleOrders.allowJustScheduledOrders,
  hasValidDaysToScheduleOrders:
    state.establishment.scheduleOrders.hasValidDaysToScheduleOrders,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormScheduleOrder);
