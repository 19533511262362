export function addUserId(id) {
  return {
    type: '@user/ADD_ID',
    id,
  };
}

export function addUserName(name) {
  return {
    type: '@user/ADD_NAME',
    name,
  };
}

export function addUserPhone(phone) {
  return {
    type: '@user/ADD_PHONE',
    phone,
  };
}

export function addUserDeliveryMethod(deliveryMethod) {
  return {
    type: '@user/ADD_DELIVERY_METHOD',
    deliveryMethod,
  };
}

export function addUserPaymentMethod(paymentMethod) {
  return {
    type: '@user/ADD_PAYMENT_METHOD',
    paymentMethod,
  };
}

export function resetUserPaymentMethod() {
  return {
    type: '@user/RESET_PAYMENT_METHOD',
  };
}

export function addUserAddress(address) {
  return {
    type: '@user/ADD_ADDRESS',
    address,
  };
}

export function resetUserAddress() {
  return {
    type: '@user/RESET_ADDRESS',
  };
}

export function getUserAddressDistance(address) {
  return {
    type: '@user/GET_ADDRESS_DISTANCE',
    address,
  };
}

export function addUserAdressToFar(address) {
  return {
    type: '@user/ADD_ADRESS_TOO_FAR',
    address,
  };
}

export function addUserAdressNotFound() {
  return {
    type: '@user/ADD_ADRESS_NOT_FOUND',
  };
}

export function addUserTable(tableNumber) {
  return {
    type: '@user/ADD_TABLE',
    tableNumber,
  };
}

export function addTableSelectedByDefault(tableSelectedByDefault) {
  return {
    type: '@user/ADD_TABLE_SELECTED_BY_DEFAULT',
    tableSelectedByDefault,
  };
}

export function resetUser() {
  return {
    type: '@user/RESET',
  };
}

export function addUserInfosFromLastOrder(lastOrder) {
  return {
    type: '@user/ADD_INFOS_FROM_LAST_ORDER',
    lastOrder,
  };
}
