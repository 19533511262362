import { formatRealToNumber } from '../../../utils/formatting';

export function addToCart(product, amount) {
  return {
    type: '@cart/PRODUCT_ADD',
    product,
    amount,
  };
}

export function removeFromCart(id) {
  return {
    type: '@cart/PRODUCT_REMOVE',
    id,
  };
}

export function updateItem(newProduct, amount, id) {
  return {
    type: '@cart/PRODUCT_UPDATE_ITEM',
    id,
    newProduct,
    amount,
  };
}

/* export function updateAmount(id, amount) {
  return {
    type: "@cart/PRODUCT_UPDATE_AMOUNT",
    id,
    amount,
  }
} */

export function addDelivery(delivery) {
  return {
    type: '@cart/DELIVERY_ADD',
    delivery,
  };
}

export function addDeliveryRate(deliveryRate) {
  return {
    formattedRate: deliveryRate,
    type: '@cart/DELIVERY_RATE_ADD',
    deliveryRate: formatRealToNumber(deliveryRate),
  };
}

export function resetCartDeliveryState() {
  return {
    type: '@cart/DELIVERY_RESET',
  };
}

export function addActiveCoupon(activeCoupon) {
  return {
    type: '@cart/COUPON_ADD',
    activeCoupon,
  };
}

export function addOrderOrigin(orderOrigin) {
  return {
    type: '@cart/ORDER_ORIGIN_ADD',
    orderOrigin,
  };
}

export function addScheduleOrder(scheduledData) {
  return {
    type: '@cart/SCHEDULE_ORDER_ADD',
    scheduledData,
  };
}

export function addOrderInfosFromLastOrder(lastOrder) {
  return {
    type: '@cart/INFOS_FROM_LAST_ORDER_ADD',
    lastOrder,
  };
}

export function addIsScheduled(isScheduled) {
  return {
    type: '@cart/IS_SCHEDULED_ADD',
    isScheduled,
  };
}

export function resetCart() {
  return {
    type: '@cart/RESET',
  };
}
