import removeAccents from 'remove-accents';

const parseAddressToCompare = (address) => {
  const number = address?.number?.toString();
  const street = address?.street?.trim()?.toLowerCase()
    ? removeAccents(address?.street?.trim()?.toLowerCase())
    : '';

  return `${street},${number}`;
};

export const getIfAddressAreEquals = (address1, address2) => {
  const parsedAddress1 = parseAddressToCompare(address1);
  const parsedAddress2 = parseAddressToCompare(address2);

  return parsedAddress1 === parsedAddress2;
};
