import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { FiClock } from 'react-icons/fi';
import * as S from './styles';
import { UIFixedBottom, UIButton } from '../../UI';

const HomeInfos = ({
  isOpen,
  welcomeText,
  hasValidDaysToScheduleOrders,
}) => {
  const { establishment } = useParams();

  return (
    <S.HomeInfos>
      <S.Title>{welcomeText}</S.Title>
      {!isOpen && hasValidDaysToScheduleOrders ? (
        <S.OpenBorder isOpen>
          <FiClock />
          Aberto para agendamentos
        </S.OpenBorder>
      ) : (
        <>
          {isOpen ? (
            <S.OpenBorder isOpen>
              <FiClock />
              aberto
            </S.OpenBorder>
          ) : (
            <S.OpenBorder isOpen={false}>
              <FiClock />
              fechado no momento
            </S.OpenBorder>
          )}
        </>
      )}
      <UIFixedBottom>
        <UIButton
          text="Ver Cardápio"
          linkTo={`/${establishment}/produtos`}
        />
      </UIFixedBottom>
    </S.HomeInfos>
  );
};

HomeInfos.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  welcomeText: PropTypes.string.isRequired,
  hasValidDaysToScheduleOrders: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: state.establishment.isOpen,
  welcomeText: state.establishment.welcomeText,
  hasValidDaysToScheduleOrders: state.establishment.scheduleOrders.hasValidDaysToScheduleOrders,
});

export default connect(mapStateToProps, null)(HomeInfos);
