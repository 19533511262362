export function addAdressOnAdressGroup(address) {
  return {
    type: '@addresses/ADD',
    address,
  };
}

export function addAdressesOnAdressGroup(addresses) {
  return {
    type: '@addresses/ADD_LIST',
    addresses,
  };
}

export function editAdressOnAdressGroup(address, indexPosition) {
  return {
    type: '@addresses/EDIT',
    address,
    indexPosition,
  };
}

export function removeAdressOnAdressGroup(indexPosition) {
  return {
    type: '@addresses/REMOVE',
    indexPosition,
  };
}

export function resetAdresses() {
  return {
    type: '@addresses/RESET',
  };
}
