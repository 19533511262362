import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const baseUIButtonStyles = css`
  height: 50px;
  border: none;
  color: #fff;
  display: flex;
  font-size: 14px;
  transition: 0.6s;
  text-align: center;
  padding: 10px 15px;
  position: relative;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  
  width: ${(props) => (props.$customWidth ? `${props.$customWidth}px` : '100%')};

  ${(props) => props.is_outline === 'true'
    && css`
      background: transparent;
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      &:not(:disabled) {
        &:hover {
          background: darken(#a30a38, 5%);
          box-shadow: 0px 0px 10px rgba(63, 63, 66, 0.7);
        }
      }
    `}

  & > span {
    color: #fff;
  }

  & > div {
    display: flex;
    padding: 10px;
    align-items: center;
    svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  svg {
    margin-left: -5px;
  }

  ${(props) => props.is_outline === 'false'
    && css`
      svg {
        &,
        * {
          color: #fff;
        }
      }
  `}

  ${(props) => props.disabled_appearance === 'true'
    && css`
      opacity: 0.4;
      cursor: not-allowed;
    `}

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  ${({ $transparent }) => ($transparent
    ? css`
          background: none;
          color: var(--primary-color);

          svg {
            &, * {
              color: var(--primary-color);
            }
          }
        `
    : css`
          &:not(:disabled) {
            &:hover {
              background: darken(#a30a38, 5%);
              box-shadow: 0px 0px 10px rgba(63, 63, 66, 0.7);
            }
          }
        `)}
`;

export const UIButton = styled.button`
  ${baseUIButtonStyles}
`;

export const UIButtonLink = styled(Link)`
  ${baseUIButtonStyles}
`;
