import { createSelector } from 'reselect';
import { getProductsTotal } from '../products';

const minOrderState = (state) => state.establishment.minOrder;
const deliveryMethodState = (state) => state.user.deliveryMethod;

export const getValidatedMinOrder = createSelector(
  [minOrderState, deliveryMethodState],
  (minOrder, deliveryMethod) => {
    if (deliveryMethod.name && deliveryMethod.label === 'delivery') {
      return minOrder;
    }

    return 0;
  },
);

export const getIfCartProductsIsBiggerThanMinOrder = createSelector(
  getValidatedMinOrder,
  getProductsTotal,
  (minOrder, productsTotal) => {
    if (minOrder) {
      return productsTotal >= minOrder;
    }
    return true;
  },
);
