import React from 'react';
import { IoStorefrontOutline } from 'react-icons/io5';
import { FaMotorcycle /* ,  FaCashRegister */ } from 'react-icons/fa';

export const getOrderDeliveryTypeIcon = (label) => {
  if (label === 'delivery') {
    return <FaMotorcycle />;
  }

  if (label === 'local-pickup') {
    return <IoStorefrontOutline />;
  }

  return null;
};
