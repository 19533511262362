import React from 'react';
import PropTypes from 'prop-types';
import { UIRequiredBlock } from '../../../UI';

import * as S from './styles';

const CustomDescription = ({ header }) => {
  if (header.min === 1 && header.max === 1) {
    return (
      <span className={!header.name ? 'infos__title' : 'infos__description'}>Escolha 1 opção</span>
    );
  }

  if (header.min >= 1) {
    return (
      <span className={!header.name ? 'infos__title' : 'infos__description'}>{`Escolha de ${header.min} até ${header.max} opções`}</span>
    );
  }

  if (header.min === 0) {
    return (
      <span className={!header.name ? 'infos__title' : 'infos__description'}>{`Escolha até ${header.max} opções`}</span>
    );
  }

  return null;
};

CustomDescription.propTypes = {
  header: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    name: PropTypes.string,
    repeatOptions: PropTypes.bool,
    chargeForTheMostExpensive: PropTypes.bool,
  }).isRequired,
};

const ProductSelectionGroupHeader = ({
  header, numOfSelectedOptions, simpleStyle,
}) => (
  <S.ProductSelectionGroupHeader simpleStyle={simpleStyle}>
    <div className="header__infos">
      <strong className="infos__title">{header.name}</strong>
      <CustomDescription header={header} />
      {header.chargeForTheMostExpensive && (
        <span className="infos__alert">
          *Cobraremos pela opção de maior valor
        </span>
      )}
    </div>
    {header.min >= 1 && (
      <UIRequiredBlock
        darkStyle={!simpleStyle}
        isValited={numOfSelectedOptions >= header.min}
      />
    )}
  </S.ProductSelectionGroupHeader>
);

ProductSelectionGroupHeader.propTypes = {
  simpleStyle: PropTypes.bool,
  numOfSelectedOptions: PropTypes.number.isRequired,
  header: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    name: PropTypes.string,
    repeatOptions: PropTypes.bool,
    chargeForTheMostExpensive: PropTypes.bool,
  }).isRequired,
};

ProductSelectionGroupHeader.defaultProps = {
  simpleStyle: false,
};

export default ProductSelectionGroupHeader;
