import React from 'react';
import { IoTicketOutline } from 'react-icons/io5';
import PropTypes from 'prop-types';
import { UIButton } from '../../../UI';

export const CartCouponAdd = ({ setShowModal }) => (
  <UIButton
    outline
    className="mb-20"
    text="Adicionar cupom"
    onClick={() => setShowModal(true)}
    icon={(
      <div style={{ padding: 0 }}>
        <IoTicketOutline size={18} stroke="var(--primary-color)" />
      </div>
    )}
  />
);

CartCouponAdd.propTypes = {
  setShowModal: PropTypes.func.isRequired,
};
