import React from 'react';
import { FlashLogoSVG } from '../../assets/svgs/flash-logo';
import * as S from './styles';

export const DevelopBy = () => (
  <S.DevelopBy>
    <div>
      <span>Desenvolvido por</span>
      <FlashLogoSVG />
    </div>
  </S.DevelopBy>
);
