import { app } from '../../../index';
import { store } from '../../../../store';
import { getActiveHourData } from '../../../../utils/hour/getActiveHourData';

const getProductAvailableNow = (product) => {
  if (product?.disponilityType === 'custom' && product?.availabilityHours?.length) {
    const activeHourData = getActiveHourData(product?.availabilityHours);
    return !!activeHourData?.startHour;
  }

  return true;
};

export const getAllProducts = async () => {
  const db = app.firestore();
  const {
    establishment: { id, isOnlineCommand },
    cart: { orderOrigin },
  } = store.getState();
  const data = await db
    .collection('products')
    .where('establishmentId', '==', id)
    .orderBy('sequence')
    .get();

  const products = data.docs.reduce((acc, doc) => {
    const productData = doc.data();
    const productAvailableNow = getProductAvailableNow(productData);
    const validVariations = productData.variations.some((variation) => !variation.paused);
    const hasValidVariations = productData.variations.length <= 0 ? true : validVariations;

    if (!productData.paused && productAvailableNow && hasValidVariations) {
      if (!productData.onlyHall) {
        acc.push({
          id: doc.id, ...productData,
        });
      }

      if ((productData.onlyHall && isOnlineCommand) || (productData.onlyHall && orderOrigin === 'table')) {
        acc.push({
          id: doc.id, ...productData,
        });
      }
    }

    return acc;
  }, []);

  return products;
};

export const getProductById = async (productId) => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();
  const data = await db
    .collection('products')
    .doc(productId)
    .get();

  const productData = data.data();

  if (productData && productData.establishmentId === id) {
    return {
      ...productData,
      id: data.id,
    };
  }

  return false;
};
