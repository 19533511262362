import * as React from 'react';

export function Flash() {
  return (
    <svg
      width={11}
      height={12}
      viewBox="0 0 11 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>58ED5524-221D-4F08-9DCD-282909EB2185</title>
      <defs>
        <filter colorInterpolationFilters="auto" id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.949898 0 0 0 0 0.583515 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        transform="translate(-28 -430)"
        filter="url(#prefix__a)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill="#F80"
          d="M34.111 430L28 437.2h5.5l-.611 4.8L39 434.8h-5.5z"
        />
      </g>
    </svg>
  );
}
