import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

export const getScheduledDeliveryDateText = (scheduled) => {
  if (!scheduled?.deliveryDateTimeEnd) return '';

  const endDeliveryDate = dayjs(scheduled.deliveryDateTimeEnd);
  const startDeliveryDate = dayjs(scheduled.deliveryDateTimeStart);

  const day = dayjs(startDeliveryDate).format('DD/MM');
  const startHour = dayjs(startDeliveryDate).format('HH:mm');
  const endHour = dayjs(endDeliveryDate).format('HH:mm');

  return `Agendado para ${day} entre ${startHour} e ${endHour}`;
};
