import dayjs from 'dayjs';
import { store } from '../../store';
import { hoursOptions } from './hoursOptions';
import { getActiveHourData } from '../hour/getActiveHourData';

export const getHoursOptions = (selectedDate, dateInfos) => {
  if (!selectedDate) return null;
  const { establishment: { businessHours } } = store.getState();

  const validHours = hoursOptions.reduce((acc, hour) => {
    const { min, max } = hour;
    const weekdayInfos = businessHours;
    const dateMin = dayjs(selectedDate).set('hour', min.hour).set('minute', min.minute);
    const dateMax = dayjs(selectedDate).set('hour', max.hour).set('minute', max.minute);

    if (dateMin >= dateInfos.minDate && dateMax <= dateInfos.maxDate) {
      const minHourIsInsideHours = getActiveHourData(
        weekdayInfos, undefined, dateMin,
      );
      const maxHourIsInsideHours = getActiveHourData(
        weekdayInfos, undefined, dateMax,
      );

      if (minHourIsInsideHours && maxHourIsInsideHours) {
        acc.push({
          value: hour.value,
          label: hour.label,
        });
      }
    }

    return acc;
  }, []);

  return validHours;
};
