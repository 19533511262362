import React from 'react';
import ContentLoader from 'react-content-loader';
import { useWindowSize } from '@react-hook/window-size';

export const ProductLoader = () => {
  const [width, height] = useWindowSize();
  return (
    <ContentLoader
      width="100%"
      height="100%"
      foregroundColor="#e9e8e8"
      backgroundColor="#f3f3f3"
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect x="0" y="0" rx="5" ry="5" width={width} height="200" />
      <rect x="20" y="220" rx="5" ry="5" width="350" height="20" />
      <rect x="20" y="290" rx="5" ry="5" width="200" height="15" />
      <rect x="20" y="315" rx="5" ry="5" width={width - 40} height="130" />
      <rect x="0" y={height - 72 - 50} rx="5" ry="5" width={width} height="72" />
    </ContentLoader>
  );
};
