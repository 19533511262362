import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

export const UIDivider = ({ margin }) => (
  <S.Divider margin={margin} />
);

UIDivider.propTypes = {
  margin: PropTypes.string.isRequired,
};
