import styled, { css } from 'styled-components';

export const ProductSelectionGroupHeader = styled.div`
  z-index: 10;
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: space-between;
  background: var(--default-gray);
  padding: 10px var(--grid-margin-space);
  .header {
    &__infos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .infos {
        &__title {
          font-weight: 500;
          font-size: 1rem;
        }
        &__description {
          font-size: 0.8rem;
        }
        &__alert {
          margin-top: 2px;
          font-size: 0.75rem;
        }
      }
    }
  }
  ${({ simpleStyle }) => simpleStyle && css`
    min-height: 25px;
    margin-bottom: -2px;
    background: transparent;
    .header {
      &__infos {
        .infos {
          &__title {
            font-size: 14px;
            font-weight: bold;
          }
          &__description {
            font-size: 0.8rem;
          }
        }
      }
    }
  `}  
`;
