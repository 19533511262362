import React, { useContext } from 'react';
import Ink from 'react-ink';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
import { RequiredLabelContext } from '../../../context/RequiredLabelContext';

import * as S from './styles';

const UIButton = ({
  text,
  type,
  icon,
  linkTo,
  onClick,
  outline,
  children,
  disabled,
  className,
  transparent,
  customWidth,
  isSimpleModel,
  disabledAppearance,
  functionWhenDisable,
}) => {
  const { setGoToTheRequiredElement } = useContext(RequiredLabelContext);
  return (
    linkTo ? (
      <S.UIButtonLink
        to={linkTo}
        $transparent={transparent}
        is_outline={outline.toString()}
        className={`UIButton text__links ${className}`}
        disabled_appearance={disabledAppearance.toString()}
        $customWidth={customWidth && customWidth.toString()}
      >
        {text}
        <Ink />
      </S.UIButtonLink>
    ) : (
      <S.UIButton
        disabled={disabled}
        $transparent={transparent}
        is_outline={outline.toString()}
        simple_model={isSimpleModel.toString()}
        type={type === 'submit' ? 'submit' : 'button'}
        className={`UIButton text__links ${className}`}
        disabled_appearance={disabledAppearance.toString()}
        $customWidth={customWidth && customWidth.toString()}
        onClick={() => {
          if (disabledAppearance) {
            if (functionWhenDisable) {
              functionWhenDisable();
            } else {
              setGoToTheRequiredElement(true);
            }
            // toast.error('Preencha todos os campos obrigatórios');
          } else if (onClick) {
            onClick();
          }
        }}
      >
        {icon}
        {text}
        {children}
        <Ink />
      </S.UIButton>
    ));
};

UIButton.propTypes = {
  text: PropTypes.string,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  linkTo: PropTypes.string,
  transparent: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string,
  customWidth: PropTypes.number,
  isSimpleModel: PropTypes.bool,
  disabledAppearance: PropTypes.bool,
  functionWhenDisable: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
};

UIButton.defaultProps = {
  text: '',
  linkTo: '',
  icon: null,
  type: 'button',
  onClick: null,
  disabled: null,
  className: '',
  outline: false,
  children: null,
  customWidth: null,
  transparent: false,
  isSimpleModel: false,
  disabledAppearance: false,
  functionWhenDisable: null,
};

export default UIButton;
