import { createSelector } from 'reselect';
import { formatNumberToReal } from '../../../utils/formatting';

const deliveryRateState = (state) => state.cart.delivery.rate;
const deliveryMethodState = (state) => state.user.deliveryMethod;

export const getDeliveryRate = createSelector(
  deliveryRateState,
  deliveryMethodState,
  (deliveryRate, deliveryMethod) => {
    if (deliveryMethod.name && deliveryMethod.label === 'local-pickup') {
      return 0;
    }
    return deliveryRate;
  },
);

export const getFormattedDeliveryRate = createSelector(
  [getDeliveryRate],
  (deliveryRate) => formatNumberToReal(deliveryRate),
);
