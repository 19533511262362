import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UIButton, UIModal } from '../../../UI';
import animationData from '../../../../assets/animations/success.json';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as UserActions from '../../../../store/modules/user/actions';
import * as AddressesActions from '../../../../store/modules/addresses/actions';
import * as S from './styles';

const SuccessOrderModal = ({
  orderId,
  showModal,
  resetUser,
  resetCart,
  linkAccessed,
  resetAdresses,
  iframeRunningOn,
}) => {
  const defaultOptions = {
    loop: false,
    animationData,
    autoplay: true,
  };

  useEffect(() => {
    // is called exactly after the time the order is made
    if (window.ReactNativeWebView && showModal) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ newOrderId: orderId }));
    }
  }, [showModal, orderId]);

  useEffect(() => () => {
    if (showModal) {
      resetUser();
      resetCart();
      resetAdresses();
    }
  }, [resetAdresses, resetCart, resetUser, showModal]);

  return (
    <UIModal isSmall={false} showModal={showModal} modalTitle="Pedido gerado com sucesso">
      <S.ModalInfosContainer>
        <Lottie
          width={120}
          height={120}
          options={defaultOptions}
        />

        <div>
          <UIButton
            text="Realizar um novo pedido"
            onClick={() => {
              window.location.replace(linkAccessed);
            }}
          />

          {iframeRunningOn && (
            <>
              {iframeRunningOn === 'app' ? (
                <UIButton
                  outline
                  text="Imprimir pedido"
                  onClick={() => {
                    if (window.ReactNativeWebView) {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({ newOrderId: orderId }),
                      );
                    }
                  }}
                />
              ) : (
                <>
                  <UIButton
                    outline
                    text="Imprimir pedido tipo 1"
                    onClick={() => {
                      if (window.parent) {
                        window.parent.postMessage({ newOrderId: orderId, type: 'new-printer' }, '*');
                      }
                    }}
                  />

                  <UIButton
                    outline
                    text="Imprimir pedido tipo 2"
                    onClick={() => {
                      if (window.parent) {
                        window.parent.postMessage({ newOrderId: orderId, type: 'old-printer' }, '*');
                      }
                    }}
                  />
                </>
              )}
            </>
          )}

        </div>
      </S.ModalInfosContainer>
    </UIModal>
  );
};

SuccessOrderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  resetUser: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  resetAdresses: PropTypes.func.isRequired,
  linkAccessed: PropTypes.string.isRequired,
  iframeRunningOn: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  linkAccessed: state.system.linkAccessed,
  iframeRunningOn: state.establishment.iframeRunningOn,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...CartActions,
    ...UserActions,
    ...AddressesActions,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SuccessOrderModal);
