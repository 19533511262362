import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const SliderContainer = styled.div`
  flex: none;
  display: flex;
  overflow-x: hidden;
  margin-bottom: 20px;
  margin-right: -20px;
`;

export const OptionsDelivery = styled(Swiper)`
  width: 100%;
  display: flex;

  .swiper-wrapper {
    display: flex;
  }

  .slide {
    margin-right: 10px;
  }
`;

export const LabelDelivery = styled.button`
  width: 62px;
  display: flex;
  padding: 12px 12px;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  border: 1.5px solid var(--disabled-color);

  strong {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--primary-color);
  }

  span {
    font-size: 12px;
    font-weight: 500;
  }

  ${({ selected }) => selected && css`
    border: 1.5px solid var(--primary-color);
  `}
`;
