import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UIInputCurrency } from '../../../UI';
import { getFormattedDeliveryRate, getDeliveryRate } from '../../../../store/selectors';
import * as CartActions from '../../../../store/modules/cart/actions';

const CartFormAddCustomDeliveryRate = ({
  userAddress,
  deliveryRate,
  clientDistance,
  isOnlineCommand,
  addDeliveryRate,
  userDeliveryMethod,
  formattedDeliveryRate,
}) => {
  const [deliveryRateValue, setDeliveryRateValue] = useState('');
  const { street, addressNotFound, addressTooFar } = userAddress;

  if (
    isOnlineCommand
    && userDeliveryMethod.label === 'delivery'
    && street
    && !addressTooFar
    && !addressNotFound
  ) {
    return (
      <UIInputCurrency
        required
        label="Taxa de entrega"
        infoMessage={clientDistance ? `Endereço do cliente à ${clientDistance} de distância` : ''}
        value={
          deliveryRateValue
          || (deliveryRate !== null ? formattedDeliveryRate : null)
        }
        setValue={(newRate) => {
          setDeliveryRateValue(newRate);
          addDeliveryRate(newRate);
        }}
      />
    );
  }

  return null;
};

CartFormAddCustomDeliveryRate.propTypes = {
  deliveryRate: PropTypes.number,
  isOnlineCommand: PropTypes.bool.isRequired,
  addDeliveryRate: PropTypes.func.isRequired,
  clientDistance: PropTypes.string.isRequired,
  formattedDeliveryRate: PropTypes.string.isRequired,
  userAddress: PropTypes.shape({
    addressNotFound: PropTypes.bool,
    addressTooFar: PropTypes.bool,
    city: PropTypes.string,
    complement: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    referencePoint: PropTypes.string,
    street: PropTypes.string,
    uf: PropTypes.string,
  }).isRequired,
  userDeliveryMethod: PropTypes.objectOf(PropTypes.node).isRequired,
};

CartFormAddCustomDeliveryRate.defaultProps = {
  deliveryRate: null,
};

const mapStateToProps = (state) => ({
  userAddress: state.user.address,
  deliveryRate: getDeliveryRate(state),
  userDeliveryMethod: state.user.deliveryMethod,
  clientDistance: state.cart.delivery.clientDistance,
  isOnlineCommand: state.establishment.isOnlineCommand,
  formattedDeliveryRate: getFormattedDeliveryRate(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormAddCustomDeliveryRate);
