import produce from 'immer';

const INITIAL_STATE = [];

export default function address(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@addresses/ADD':
      return produce(state, (draft) => {
        draft.push(action.address);
      });

    case '@addresses/ADD_LIST':
      return action.addresses;

    case '@addresses/EDIT': {
      const newState = [...state];
      newState[action.indexPosition] = action.address;

      return newState;
    }

    case '@addresses/REMOVE': {
      const newState = [...state];
      newState.splice(action.indexPosition, 1);

      return newState;
    }

    case '@addresses/RESET':
      return INITIAL_STATE;

    default:
      return state;
  }
}
