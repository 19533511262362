import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import ReactPixel from 'react-facebook-pixel';

import * as S from './styles';
import * as CartActions from '../../store/modules/cart/actions';

import CartItems from './Items';
import CartValues from './Values';
import CartHeader from './Header';
import CartAddItem from './AddItem';

import CartForm from './Form';
import CartAskToo from './AskToo';
import CartMinOrder from './MinOrder';
import { getCartTotal } from '../../store/selectors';
import { UIDivider } from '../UI';

const Cart = ({
  cart, removeFromCart, cartTotal, informationalUse,
}) => {
  useEffect(() => {
    if (!informationalUse) {
      ReactPixel.track('InitiateCheckout', {
        value: cartTotal,
        currency: 'BRL',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationalUse]);

  return (
    <S.Cart informationalUse={informationalUse}>
      {!informationalUse && (
        <CartHeader />
      )}

      <CartItems
        cartItems={cart}
        removeFromCart={removeFromCart}
        informationalUse={informationalUse}
      />

      {!informationalUse && (
        <CartAddItem />
      )}

      {informationalUse && (
        <UIDivider margin="20px 0 20px 0" />
      )}

      <CartValues />

      {!informationalUse && (
        <CartAskToo />
      )}

      <UIDivider margin="0 0 20px 0" />

      {!informationalUse && (
        <CartMinOrder />
      )}

      <CartForm informationalUse={informationalUse} />
    </S.Cart>
  );
};

Cart.propTypes = {
  cartTotal: PropTypes.number.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  cart: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  cart: state.cart.products,
  cartTotal: getCartTotal(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
