import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import expireReducer from 'redux-persist-expire';
import reducers from './modules/reducers';
import rootSaga from './modules/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  storage,
  key: 'root',
  blacklist: ['cart'],
  transforms: [
    createWhitelistFilter('user', ['name', 'phone']),
    expireReducer('system', {
      autoExpire: true,
      expireSeconds: 10800, // 3 hours
    }),
  ],
};

const persistedReducer = persistCombineReducers(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
