import styled from 'styled-components';

export const ModalInfosContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    margin-top: 20px;
  }
`;
