import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const MenuItemsGroupHeader = ({ groupName }) => (
  <S.MenuItemsGroupHeader>
    {groupName}
  </S.MenuItemsGroupHeader>
);

MenuItemsGroupHeader.propTypes = {
  groupName: PropTypes.string.isRequired,
};

export default MenuItemsGroupHeader;
