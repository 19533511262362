import React from 'react';
import PropTypes from 'prop-types';
import { orderBySmallestToLargest } from '../../../../utils/orderBy';
import { UIPrice } from '../../../UI';
import * as S from './styles';

export const MenuItemPrice = ({ item }) => {
  const {
    price, promotionalPrice, variations, priceType,
  } = item;

  if (priceType === 'single') {
    return (
      <UIPrice
        price={price}
        promotionalPrice={promotionalPrice}
      />
    );
  }

  const validVariations = variations.filter((variation) => !variation.paused);
  const parsedVariations = validVariations.map((variation) => {
    const priceNum = Number(variation.price);
    const promotionalPriceNum = Number(variation.promotionalPrice);

    const getValueToConsider = () => {
      if (promotionalPriceNum > 0) {
        return priceNum < promotionalPriceNum ? priceNum : promotionalPriceNum;
      }

      return priceNum;
    };

    return {
      ...variation,
      valueToConsider: getValueToConsider(),
    };
  });

  const variationWithSmallValue = orderBySmallestToLargest(
    parsedVariations,
    'valueToConsider',
  )[0];

  return (
    <S.PriceContainer>
      {variationWithSmallValue.price
        && Number(variationWithSmallValue.price) > 0 && (
          <span className="text-price-from">A partir de</span>
      )}
      <UIPrice
        price={variationWithSmallValue.price}
        promotionalPrice={variationWithSmallValue.promotionalPrice}
      />
    </S.PriceContainer>
  );
};

MenuItemPrice.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    menu: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    priceIsNotFixed: PropTypes.bool,
    promotionalPrice: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    priceType: PropTypes.oneOf(['single', 'multiple']),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
  }),
};

MenuItemPrice.defaultProps = {
  item: {},
};
