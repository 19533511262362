import React, { useLayoutEffect } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import * as S from './styles';

import './UIModal.scss';
import HeaderSimpleWithReturn from '../../Header/SimpleWithReturn';

const UIModal = ({
  isSmall,
  children,
  showModal,
  autoHeight,
  modalTitle,
  rightAction,
  setShowModal,
  noBottomPadding,
  noHorizontalPadding,
  noMarginBottomOnHeader,
}) => {
  useLayoutEffect(() => {
    if (showModal) {
      document.querySelector('body').classList.add('remove-scroll');
    } else {
      document.querySelector('body').classList.remove('remove-scroll');
    }
    return () => {
      document.querySelector('body').classList.remove('remove-scroll');
    };
  }, [showModal]);

  if (!showModal) return null;
  return (
    ReactDom.createPortal(
      <motion.div
        tabIndex="0"
        role="button"
        initial="hidden"
        animate="visible"
        className="UIModal"
        variants={{
          hidden: {
            scale: 0.8,
            opacity: 0,
          },
          visible: {
            scale: 1,
            opacity: 1,
          },
        }}
        onKeyDown={(e) => { if (e.key === 'Escape' && setShowModal) setShowModal(false); }}
        onClick={(e) => { if (e.target.className === 'UIModal' && setShowModal) setShowModal(false); }}
      >
        <S.WhiteContainer
          $isSmall={isSmall}
          $autoHeight={autoHeight}
          id="UIModal__white-container"
          $noBottomPadding={noBottomPadding}
          $noHorizontalPadding={noHorizontalPadding}
        >
          <HeaderSimpleWithReturn
            title={modalTitle}
            rightAction={rightAction}
            noMarginBottom={noMarginBottomOnHeader}
            $noHorizontalPadding={noHorizontalPadding}
            onClick={setShowModal ? () => setShowModal(false) : null}
          />
          {children}
        </S.WhiteContainer>
      </motion.div>, document.getElementById('portal'),
    ));
};

UIModal.propTypes = {
  isSmall: PropTypes.bool,
  autoHeight: PropTypes.bool,
  modalTitle: PropTypes.string,
  setShowModal: PropTypes.func,
  noBottomPadding: PropTypes.bool,
  noHorizontalPadding: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  noMarginBottomOnHeader: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  rightAction: PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

UIModal.defaultProps = {
  isSmall: false,
  modalTitle: '',
  rightAction: {},
  autoHeight: false,
  setShowModal: null,
  children: undefined,
  noBottomPadding: false,
  noHorizontalPadding: false,
  noMarginBottomOnHeader: false,
};

export default UIModal;
