import styled, { css } from 'styled-components';
import { UIButton } from '../../../UI';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CopyPixKeyButton = styled(UIButton)`
  ${({ successOnCopyKey }) => successOnCopyKey && css`
    background: var(--sucess-color);
  `};
`;

export const FinishOrderButton = styled(UIButton)`
  margin-top: auto;
  ${({ disabledAppearance }) => disabledAppearance && css`
    opacity: 0.8 !important;
    background: var(--default-dark-gray);
  `};
`;
