import styled, { css } from 'styled-components';

export const UIButtonToEdit = styled.button`
  width: 100%;
  display: flex;
  text-align: left;
  min-height: 50px;
  padding: 10px 12px;
  border-radius: 4px;
  position: relative;
  align-items: center;
  // margin-bottom: 20px;
  justify-content: space-between;
  border: 1px solid var(--text-color);

  &:disabled {
    opacity: 0.4;
    color: black;
    cursor: not-allowed;
    background: var(--default-gray);
  }

  ${({ redBorder }) => redBorder && css`
    border: 1px solid var(--alert-color);
  `}

  & > div {
    width: 100%;
    display: flex;

    .icon {
      margin-right: 10px;
      &, * {
        font-size: 16px;
      }
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      span {
        font-size: 12px;
      }
      strong {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
      & > *:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  & > svg {
    font-size: 18px;
  }
`;
