import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--default-gray);
  padding: 30px var(--grid-margin-space) 10px var(--grid-margin-space); 
  margin: -30px calc(-1 * var(--grid-margin-space)) 20px calc(-1 * var(--grid-margin-space));
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptySearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchNotFindText = styled.span`
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const SearchNotFindSearchedText = styled.span`
  display: block;
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;
