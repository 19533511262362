import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import UICustomInputLabel from '../../CustomInputLabel';

const CustomInputRadio = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid red;
  justify-content: space-between;
`;

/* const CustomInputRadioCircle = styled.button`
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid var(--default-gray);

  div {
    background: transparent;
  }

  ${(props) => props.isChecked && css`
    div {
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: var(--primary-color);
    }
  `}
`; */

const UIInputRadios = ({
  isRequired, options, register, name, labelTitle,
}) => (
  <>
    <UICustomInputLabel
      isValidate={false}
      isRequired={isRequired}
      labelTitle={labelTitle}
    />

    {options.map(({
      value, extraInfo, id,
    }) => (
      <CustomInputRadio
        key={id}
        className="CustomInputRadio"
      >
        <div className="CustomInputRadio__infos">
          <span className="infos__title">{value}</span>
          {extraInfo && (
          <span className="infos__extra-info">{extraInfo}</span>
          )}
        </div>
        <input type="radio" name={name} value={value} ref={register} />
        {/* <CustomInputRadioCircle isChecked={isChecked}>
        <div className="radio__checked" />
      </CustomInputRadioCircle> */}
      </CustomInputRadio>

    ))}

  </>
);

UIInputRadios.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  labelTitle: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default UIInputRadios;

/* {options.map(({
        title, isChecked, extraInfo, id,
      }) => (
        <CustomInputRadio
          type="button"
          className="CustomInputRadio"
          onClick={() => handleSetSelected(id)}
        >
          <div className="CustomInputRadio__infos">
            <span className="infos__title">{title}</span>
            {extraInfo && (
            <span className="infos__extra-info">{extraInfo}</span>
            )}
          </div>
          <CustomInputRadioCircle isChecked={isChecked}>
            <div className="radio__checked" />
          </CustomInputRadioCircle>
        </CustomInputRadio>
      ))} */
