import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { UIBottomModal, UIButton, UIInput } from '../../../UI';
import * as CartActions from '../../../../store/modules/cart/actions';
import { formatNumberToReal } from '../../../../utils/formatting';
import { getCartTotal } from '../../../../store/selectors';
import { FirebaseCoupons } from '../../../../firebase/functions';

const CartCouponAddModal = ({
  cartTotal, showModal, setShowModal, addActiveCoupon,
}) => {
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddCoupon = async (couponName) => {
    const { coupon, invalidInfoOnCoupon } = await FirebaseCoupons.getCouponByName(couponName);

    if (coupon?.name) {
      if (!invalidInfoOnCoupon && cartTotal >= coupon.minOrderValue) {
        setErrorMessage('');
        setShowModal(false);
        setCouponCode('');
        addActiveCoupon(coupon);
        return;
      }

      if (invalidInfoOnCoupon === 'INVALID_ACTIVE') {
        setErrorMessage('Este cupom foi desativado pelo estabelecimento');
        return;
      }

      if (invalidInfoOnCoupon === 'INVALID_QUANTITY') {
        setErrorMessage('Cupom esgotado');
        return;
      }

      if (invalidInfoOnCoupon === 'INVALID_DATE') {
        setErrorMessage('Este cupom está expirado');
        return;
      }

      if (invalidInfoOnCoupon === 'INVALID_DAY_OF_WEEK') {
        const customDaysOfWeek = coupon.availability.map((day, index, arr) => {
          if ((index + 1) === arr.length) {
            return `${day}`;
          }

          return `${day}`;
        });

        setErrorMessage(`Cupom disponível somente ${customDaysOfWeek.join(', ')}`);
        return;
      }

      if (invalidInfoOnCoupon === 'INVALID_HOUR') {
        setErrorMessage(`Cupom disponível somente entre ${coupon.hour.initial} e ${coupon.hour.final}`);
      }

      if (cartTotal < coupon.minOrderValue) {
        setErrorMessage(`Para ativar o cupom, você precisa ter um pedido acima de ${formatNumberToReal(coupon.minOrderValue)}`);
      }
    } else {
      setErrorMessage('Cupom não encontrado. Verifique o código e tente novamente');
    }
  };

  return (
    <>
      <UIBottomModal
        showModal={showModal}
        modalTitle="Cupom de desconto"
        setShowModal={(show) => {
          setShowModal(show);
          setCouponCode('');
          setErrorMessage('');
        }}
      >
        <UIInput
          type="text"
          value={couponCode}
          placeholder="Nome do cupom"
          errorMessage={errorMessage}
          setValue={(value) => setCouponCode(value.toUpperCase())}
        />
        <UIButton
          text="Aplicar o cupom"
          onClick={() => {
            handleAddCoupon(couponCode);
          }}
        />
      </UIBottomModal>
    </>
  );
};

CartCouponAddModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  cartTotal: PropTypes.number.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addActiveCoupon: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cartTotal: getCartTotal(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartCouponAddModal);
