import styled from 'styled-components';

export const UIMenuActions = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .actions {
    right: 0;
    top: 40px;
    z-index: 10;
    min-width: 120px;
    background: white;
    position: absolute;
    border: 1px solid #ddd;

    button {
      width: 100%;
      height: 40px;
      display: flex;
      padding: 0 10px;
      font-weight: 500;
      align-items: center;

      svg {
        font-size: 16px;
        margin-right: 7px;
      }

      &:not(:first-of-type) {
        border-top: 1px solid var(--default-gray);
      }

      &:hover {
        background: var(--default-gray);
      }
    }
  }
`;
