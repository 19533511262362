import React from 'react';
import PropTypes from 'prop-types';

import './ProductDescription.scss';

const ProductDescription = ({ productName, productDescription }) => (
  <div className="ProductDescription">
    <h2 className="ProductDescription__name">{productName}</h2>
    <span className="ProductDescription__description">{productDescription}</span>
  </div>
);

ProductDescription.propTypes = {
  productName: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
};

export default ProductDescription;
