import { app } from '../../../index';
import { store } from '../../../../store';
import { getInvalidMsgOnCoupon } from '../../../../utils/coupon/getInvalidMsgOnCoupon';

export const getCouponByName = async (couponName) => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();
  const data = await db
    .collection('coupons')
    .where('establishmentId', '==', id)
    .where('name', '==', couponName)
    .get();

  const coupon = { id: data.docs[0]?.id, ...data.docs[0]?.data() };

  const invalidInfoOnCoupon = coupon?.name ? getInvalidMsgOnCoupon(coupon) : '';

  return {
    coupon,
    invalidInfoOnCoupon,
  };
};

export const getIfEstablishmentHasActiveCoupons = async () => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();
  const data = await db
    .collection('coupons')
    .where('establishmentId', '==', id)
    .get();

  const hasActiveCoupons = data.docs.some((doc) => doc.data().active === true);

  return hasActiveCoupons;
};
