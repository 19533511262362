import React from 'react';
import { IoTicketOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFixedCouponValue, getProductsTotal } from '../../../../store/selectors';
import * as S from './styles';
import CartCouponInfosCustomMessage from './CustomMessage';

const CartCouponInfos = ({
  productsTotal,
  setShowModal,
  activeCoupon,
  couponValue,
  informationalUse,
}) => (
  <S.CartCoupon
    icon={<IoTicketOutline />}
    disabled={informationalUse}
    noRightIcon={informationalUse}
    onClick={() => setShowModal(true)}
    firstElement={(
      <S.CartCouponDescription>
        <strong>{activeCoupon.name}</strong>
        {(activeCoupon.minOrderValue > productsTotal
          || activeCoupon.maxValue > 0)
          && (couponValue === 'FRETE GRÁTIS' ? (
            <S.CartCouponValue text={couponValue} />
          ) : (
            <S.CartCouponValue text={`${couponValue} de desconto`} />
          ))}
      </S.CartCouponDescription>
    )}
    secondElement={<CartCouponInfosCustomMessage />}
  />
);

CartCouponInfos.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  couponValue: PropTypes.string.isRequired,
  productsTotal: PropTypes.number.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  activeCoupon: PropTypes.shape({
    name: PropTypes.string,
    couponType: PropTypes.string,
    value: PropTypes.string,
    maxValue: PropTypes.string,
    minOrderValue: PropTypes.string,
    quantity: PropTypes.number,
    availability: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.arrayOf(PropTypes.string),
    hour: PropTypes.shape({
      final: PropTypes.string,
      initial: PropTypes.string,
    }),
    date: PropTypes.shape({
      final: PropTypes.number,
      initial: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  activeCoupon: state.cart.activeCoupon,
  couponValue: getFixedCouponValue(state),
  productsTotal: getProductsTotal(state),
});

export default connect(mapStateToProps, null)(CartCouponInfos);
