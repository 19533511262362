import styled from 'styled-components';

export const ProductHeader = styled.button`
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 27px;
`;

export const ProductHeaderImage = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  img {
    margin: 0 -20px;
    max-width: 100%;
    max-height: 30vh;
  }
`;

export const ProductHeaderZoomIcon = styled.div`
  right: 0;
  bottom: 0;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 5px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  svg {
    fill: white;
  }
`;

export const ModalDarkContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  overflow: hidden;
  max-height: 100vh;
  background: #100d0d;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;

  img {
    max-width: 100%;
    margin: 0 -20px;
    max-height: 72vh;
    height: fit-content;
  }

  div {
    width: 100%;
  }

  p {
    margin: 0px 0px 12px;
    padding: 0px;
    text-align: start;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    color: rgb(255, 255, 255);
  }

  p:last-child {
    margin: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
`;
