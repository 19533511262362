import styled, { css } from 'styled-components';

export const UIQuantityControl = styled.div`
  display: flex;
  align-items: center;
  .UIQuantityControl {
    &__quantity {
      display: flex;
      background: #fff;
      font-weight: 500;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--primary-color);
      width: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '40px')};
      height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '40px')};
    }

    &__button {
      padding: 0;
      width: 40px;
      height: 40px;
      display: flex;
      border-radius: 0;
      position: relative;
      align-items: center;
      justify-content: center;
      width: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '40px')};
      height: ${({ customHeight }) => (customHeight ? `${customHeight}px` : '40px')};
      svg {
        width: 20px;
        height: 20px;
        margin-left: 0;
        font-size: 20px;
        * {
          fill: #fff;
          color: #fff;
          stroke: #fff;
          outline: #fff;
        }
      }
      &:disabled {
        opacity: 0.4;
        cursor: no-drop;
      }
    }

    @media screen and (max-width: 340px) {
      &__quantity {
        width: 35px;
        height: 35px;
      }
      &__button {
        width: 35px;
        height: 35px;
      }
    }

    ${({ simpleModel }) => simpleModel && css`
      &__quantity {
        width: 30px;
        height: 30px;
        border: none;
        font-size: 16px;
        background: transparent;
      }

      &__button {
        padding: 0;
        width: 30px;
        height: 30px;
        border: none;
        background: transparent;
        svg {
          * {
            fill: var(--primary-color);
            color: var(--primary-color);
            stroke: var(--primary-color);
            outline: var(--primary-color);
          }
        }
        &:hover {
          box-shadow: none;
        }
      }
    `}

    ${({ disableButtons }) => disableButtons && css`
      &__quantity {
        opacity: 0.4;
      }
    `}
  }
`;
