export const getSeparetedStrings = (strings, separationSymbol) => {
  const validStrings = strings.filter((string) => !!string);

  if (validStrings.length <= 1) return validStrings[0];

  const newSeparetedString = validStrings.map((string, index) => {
    if (string) {
      return index + 1 < validStrings.length ? `${string} ${separationSymbol}` : string;
    }
    return null;
  });

  return newSeparetedString.join(' ');
};
