import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import ReactPixel from 'react-facebook-pixel';
import { ScreensPage404 } from '../../screens/Page404';
import {
  FirebaseEstablishment,
  FirebaseCoupons,
} from '../../firebase/functions';
import * as CartActions from '../../store/modules/cart/actions';
import * as UserActions from '../../store/modules/user/actions';
import * as SystemActions from '../../store/modules/system/actions';
import * as AddressesActions from '../../store/modules/addresses/actions';
import * as EstablishmentActions from '../../store/modules/establishment/actions';
import { UILoader } from '../UI';
import { orderOrigins } from '../../utils/orderOrigin/orderOrigins';
import { getPlanIsActive } from '../../utils/plan/getPlanIsActive';
import CartLastOrderSuccess from '../Cart/LastOrderSuccess';

const HandleEstablishmentInfos = ({
  children,
  addEstablishment,
  addActiveCoupon,
  addOrderOrigin,
  addUserName,
  resetUser,
  resetAdresses,
  addUserTable,
  addTableSelectedByDefault,
  addLinkAccessed,
}) => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function init() {
      if (window.location.pathname !== '/') {
        const {
          params: { establishment: establishmentSlug },
        } = matchPath(window.location.pathname, {
          path: '/:establishment',
        });
        const query = new URLSearchParams(window.location.search);
        const couponOnQuery = query.get('cupom');
        const orderType = query.get('ot');
        const orderOrigin = query.get('or');
        const iframeRunningOn = query.get('iframe-running-on');
        const isOnlineCommand = orderType === 'comanda-online';
        const isTableOrder = orderOrigin === 'table';
        const tableNumber = Number(query.get('tn'));

        if (establishmentSlug && establishmentSlug !== '404') {
          const firebaseEstablishmentInfos = await FirebaseEstablishment.getEstablishmentBySlug(
            establishmentSlug, isOnlineCommand,
          );

          if (!firebaseEstablishmentInfos) {
            Sentry.withScope((scope) => {
              scope.setTag('establishment', establishmentSlug);
              scope.setLevel('warning');
              scope.setUser({ establishmentInfos: firebaseEstablishmentInfos });
              Sentry.captureException(new Error('Establishment not found'));
            });
            setNotFound(true);
            setLoading(false);
            return;
          }

          const planIsActive = await getPlanIsActive(
            firebaseEstablishmentInfos,
          );

          if (!planIsActive) {
            Sentry.withScope((scope) => {
              scope.setTag('establishment', establishmentSlug);
              scope.setLevel('info');
              scope.setUser({
                establishmentPlanStatus: firebaseEstablishmentInfos?.planStatus,
              });
              Sentry.captureException(
                new Error('Establishment with inactive plan'),
              );
            });
            setNotFound(true);
            setLoading(false);
            return;
          }

          const getEstablishmentColor = () => {
            if (isOnlineCommand) {
              if (iframeRunningOn === 'app') {
                return '#dd1b32';
              }
              return '#7367f0';
            }
            return firebaseEstablishmentInfos.color || '#a30a38';
          };

          document.documentElement.style.setProperty(
            '--primary-color',
            getEstablishmentColor(),
          );
          document.documentElement.style.setProperty(
            '--primary-color-opacity',
            `${getEstablishmentColor()}60`,
          );

          const hasActiveCoupons = await FirebaseCoupons.getIfEstablishmentHasActiveCoupons();

          if (couponOnQuery && hasActiveCoupons) {
            const {
              coupon, invalidInfoOnCoupon,
            } = await FirebaseCoupons.getCouponByName(couponOnQuery);

            if (coupon) {
              if (!invalidInfoOnCoupon) {
                addActiveCoupon(coupon);
              }
            }
          }

          addEstablishment({
            ...firebaseEstablishmentInfos,
            hasActiveCoupons,
            isOnlineCommand,
            iframeRunningOn: isOnlineCommand ? iframeRunningOn : '',
          });

          if (firebaseEstablishmentInfos?.facebookPixelId && !isOnlineCommand) {
            ReactPixel.init(firebaseEstablishmentInfos?.facebookPixelId);
            ReactPixel.pageView();
          }

          if (isOnlineCommand) {
            resetUser();
            resetAdresses();
          }

          if (isTableOrder) {
            if (!Number.isNaN(tableNumber) && tableNumber !== 0) {
              addUserTable(tableNumber);
              addTableSelectedByDefault(true);
            }
          }

          if (!isOnlineCommand || (isOnlineCommand && orderOrigin)) {
            const isAnValidOriginOnQuery = orderOrigins.some(
              ({ label }) => label === orderOrigin,
            );

            if (isAnValidOriginOnQuery) {
              addOrderOrigin(orderOrigin);
            } else {
              // Online pois o pedido esta sendo feito pelo cliente via link do cardapio digital
              addOrderOrigin('online');
            }
          }

          addLinkAccessed(window.location.href);
          setLoading(false);
        }
      } else {
        window.location.replace('https://site.flashmenu.app');
      }
    }
    init();
  }, [
    addEstablishment,
    addActiveCoupon,
    addOrderOrigin,
    addUserTable,
    resetUser,
    addUserName,
    resetAdresses,
    addTableSelectedByDefault,
    addLinkAccessed,
  ]);

  if (notFound) {
    return <ScreensPage404 />;
  }

  if (loading) {
    return <UILoader />;
  }

  return (
    <>
      <CartLastOrderSuccess />
      {children}
    </>
  );
};

HandleEstablishmentInfos.propTypes = {
  resetUser: PropTypes.func.isRequired,
  resetAdresses: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  addUserName: PropTypes.func.isRequired,
  addUserTable: PropTypes.func.isRequired,
  addOrderOrigin: PropTypes.func.isRequired,
  addActiveCoupon: PropTypes.func.isRequired,
  addEstablishment: PropTypes.func.isRequired,
  addTableSelectedByDefault: PropTypes.func.isRequired,
  addLinkAccessed: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...CartActions,
    ...EstablishmentActions,
    ...UserActions,
    ...AddressesActions,
    ...SystemActions,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(HandleEstablishmentInfos);
