import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as S from './styles';
import { formatNumberToReal } from '../../../utils/formatting';

const MinimumOrderAndIsOpen = ({ isOpen, minOrder, hasValidDaysToScheduleOrders }) => (
  <S.FlexContainer>
    {minOrder > 0 && (
      <span>
        Pedido mínimo para entrega
        {' '}
        {formatNumberToReal(minOrder)}
      </span>
    )}
    {!isOpen && hasValidDaysToScheduleOrders ? (
      <S.OpenBorder open>
        Agendar pedido
      </S.OpenBorder>
    ) : (
      <S.OpenBorder open={isOpen}>{isOpen ? 'aberto' : 'fechado'}</S.OpenBorder>
    )}
  </S.FlexContainer>
);

MinimumOrderAndIsOpen.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  minOrder: PropTypes.number.isRequired,
  hasValidDaysToScheduleOrders: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: state.establishment.isOpen,
  minOrder: state.establishment.minOrder,
  hasValidDaysToScheduleOrders: state.establishment.scheduleOrders.hasValidDaysToScheduleOrders,
});

export default connect(mapStateToProps, null)(MinimumOrderAndIsOpen);
