import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BsCalendar2Event } from 'react-icons/bs';
import { getCartTotal } from '../../../../store/selectors';
import * as CartActions from '../../../../store/modules/cart/actions';
import {
  UIButton,
  UICustomInputLabel,
} from '../../../UI';

import * as S from './styles';

const CartFormHowToReceive = ({
  isOpen,
  isScheduled,
  addIsScheduled,
  isOnlineCommand,
  informationalUse,
  userDeliveryMethod,
  establishmentAllowSchedule,
  hasValidDaysToScheduleOrders,
  establishmentAllowJustScheduledOrders,
}) => {
  if (
    !isOpen
    || !userDeliveryMethod?.label
    || !establishmentAllowSchedule
    || establishmentAllowJustScheduledOrders
    || !hasValidDaysToScheduleOrders) {
    return null;
  }

  return (
    <>
      <UICustomInputLabel
        isValited={isScheduled !== null}
        labelTitle="Pra quando é este pedido ?"
        isRequired={!isOnlineCommand && !informationalUse}
      />

      <S.ButtonsContainer>
        <UIButton
          text="Agora"
          disabled={informationalUse}
          outline={isScheduled !== false}
          onClick={() => addIsScheduled(false)}
        />
        <UIButton
          text="Agendar"
          disabled={informationalUse}
          outline={isScheduled !== true}
          onClick={() => addIsScheduled(true)}
          icon={<BsCalendar2Event size={16} style={{ marginRight: 7 }} />}
        />
      </S.ButtonsContainer>
    </>
  );
};

CartFormHowToReceive.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  addIsScheduled: PropTypes.func.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  establishmentAllowSchedule: PropTypes.bool.isRequired,
  hasValidDaysToScheduleOrders: PropTypes.bool.isRequired,
  establishmentAllowJustScheduledOrders: PropTypes.bool.isRequired,
  userDeliveryMethod: PropTypes.objectOf(PropTypes.node).isRequired,
};

const mapStateToProps = (state) => ({
  cartTotalNum: getCartTotal(state),
  isOpen: state.establishment.isOpen,
  isScheduled: state.cart.isScheduled,
  userDeliveryMethod: state.user.deliveryMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
  establishmentAllowSchedule: state.establishment.scheduleOrders.active,
  establishmentAllowJustScheduledOrders:
    state.establishment.scheduleOrders.allowJustScheduledOrders,
  hasValidDaysToScheduleOrders:
    state.establishment.scheduleOrders.hasValidDaysToScheduleOrders,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormHowToReceive);
