import styled, { css } from 'styled-components';

export const EstablishmentBrand = styled.div`
  z-index: 1;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--primary-color-opacity);

  & > img {
    width: 100%;
    margin: auto;
    border-radius: 10px;
  }


  ${({ smallSize }) => smallSize && css`
    @media screen and (max-width: 375px) {
      width: ${`${smallSize}px`};
      height: ${`${smallSize}px`};
      min-width: ${`${smallSize}px`};
      min-height: ${`${smallSize}px`};
    }
  `}
`;
