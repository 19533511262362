import styled from 'styled-components';

export const CartAddressNeighborhoodSelection = styled.div`
`;

export const Neighborhood = styled.li`
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--default-gray);
  margin: 0 calc(-1 * var(--grid-margin-space));
  &, * {
    cursor: pointer;
  }
  &:not(:first-of-type) {
    margin-top: -1px;
    border-top: 1px solid var(--default-gray);
  }

  .container {
    width: 100%;
    display: flex;
    padding: 15px 20px;
    align-items: center;

    .infos {
      display: flex;
      margin-left: 20px;
      flex-direction: column;

      strong {
        font-weight: 700;
      }
    }

    svg {
      font-size: 24px;
      margin-left: auto;
    }
  }
`;

export const NotFoundNeighborhoodAlert = styled.small`
  a {
    color: red
  }
`;
