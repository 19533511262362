import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { CgMathPlus } from 'react-icons/cg';
import { uuid } from 'uuidv4';
import { UIButton, UIInputHookForm } from '../UI';
import * as S from './styles';

const AddressForm = ({
  addressToEdit,
  handleOnSubmit,
  isOnlineCommand,
  selectedNeighborhood,
}) => {
  const [CEPData, setCEPData] = useState({});
  const [dontKnowMyCEP, setDontKnowMyCEP] = useState(false);
  const [showExtraInfos, setShowExtraInfos] = useState(false);
  const showExtraInputs = (isOnlineCommand && showExtraInfos) || !isOnlineCommand;

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      uf: addressToEdit.uf,
      cep: addressToEdit.cep,
      city: addressToEdit.city,
      number: addressToEdit.number,
      street: addressToEdit.street,
      complement: addressToEdit.complement,
      neighborhood: addressToEdit.neighborhood,
      referencePoint: addressToEdit.referencePoint,
    },
  });
  const watchCEP = watch('cep', false);

  useEffect(() => {
    if (!Number.isSafeInteger(Number(watchCEP))) {
      const onlyNumberArray = watchCEP.split('').filter((e) => !!Number(e));
      setValue('cep', onlyNumberArray.join(''));
    }
  }, [watchCEP, setValue]);

  useEffect(() => {
    if (watchCEP.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${watchCEP.replace('-', '')}/json`)
        .then(({ data }) => {
          if (data.erro) {
            toast.error('CEP não encontrado');
          } else {
            if (data.logradouro) {
              setValue('street', data.logradouro);
            }

            if (data.bairro && !selectedNeighborhood) {
              setValue('neighborhood', data.bairro);
            }

            if (data.localidade) {
              setValue('city', data.localidade);
            }

            if (data.uf) {
              setValue('uf', data.uf);
            }

            setCEPData(data);
          }
        })
        .catch(() => {
          toast.error('CEP não encontrado');
        });
    } else if (watchCEP.length !== 8) {
      setCEPData({});
    }
  }, [watchCEP, setValue, selectedNeighborhood]);

  const getIfCEPIsValid = (CEPProp) => {
    if (CEPProp.length === 0 || CEPProp.length === 8) {
      return true;
    }

    if ((CEPProp.length > 0 && CEPProp.length < 8) || CEPProp.length > 8) {
      return false;
    }

    return true;
  };

  const handleParseSubmit = (data) => {
    const dataWithId = { ...data, id: addressToEdit?.id || uuid() };
    handleOnSubmit(dataWithId);
  };

  return (
    <form onSubmit={handleSubmit(handleParseSubmit)}>
      <UIInputHookForm
        name="cep"
        type="tel"
        label="CEP"
        maxLength={8}
        errors={errors}
        className="mb-20"
        register={register}
        hideInput={dontKnowMyCEP}
        validate={getIfCEPIsValid}
        validateErrorMsg="O seu CEP precisa ter 8 digítos númericos"
      />

      {!dontKnowMyCEP && (
        <S.DontKnowMyCEPButton
          transparent
          className="mb-20"
          text={isOnlineCommand ? 'Não sei o CEP do cliente' : 'Não sei meu CEP'}
          onClick={() => {
            setDontKnowMyCEP(true);
            setValue('cep', '');
          }}
        />
      )}

      <UIInputHookForm
        required
        type="text"
        name="street"
        errors={errors}
        register={register}
        readOnly={!!CEPData.logradouro}
        errorMessage="Adicione sua rua."
        defaultValue={CEPData.logradouro}
        label="Rua, Avenida, Praça, Travessa..."
      />

      <UIInputHookForm
        required
        type="text"
        name="number"
        label="Número"
        errors={errors}
        register={register}
        errorMessage="Adicione o número do seu endereço."
      />

      <UIInputHookForm
        type="text"
        label="Bairro"
        errors={errors}
        name="neighborhood"
        register={register}
        required={!isOnlineCommand}
        errorMessage="Adicione seu bairro."
        readOnly={!!selectedNeighborhood || !!CEPData.bairro}
        defaultValue={selectedNeighborhood || CEPData.bairro}
      />

      {isOnlineCommand && !showExtraInfos && (
        <UIButton
          transparent
          className="mb-20"
          text="Adicionar mais informações"
          onClick={() => setShowExtraInfos(true)}
          icon={<CgMathPlus size={20} color="red" />}
        />
      )}

      <UIInputHookForm
        type="text"
        errors={errors}
        name="complement"
        register={register}
        label="Complemento"
        hideInput={!showExtraInputs}
        errorMessage="Adicione o complemento do seu endereço."
      />

      <UIInputHookForm
        type="text"
        errors={errors}
        register={register}
        name="referencePoint"
        label="Ponto de referência"
        hideInput={!showExtraInputs}
        errorMessage="Adicione um ponto de referência."
      />

      <UIInputHookForm
        type="text"
        name="city"
        label="Cidade"
        errors={errors}
        register={register}
        required={!isOnlineCommand}
        hideInput={!showExtraInputs}
        readOnly={!!CEPData.localidade}
        defaultValue={CEPData.localidade}
        errorMessage="Adicione sua cidade."
      />

      <UIInputHookForm
        name="uf"
        type="text"
        label="UF"
        errors={errors}
        register={register}
        placeholder="Ex: SP"
        readOnly={!!CEPData.uf}
        defaultValue={CEPData.uf}
        required={!isOnlineCommand}
        hideInput={!showExtraInputs}
        errorMessage="Adicione a UF do seu estado."
      />

      <UIButton type="submit" text="Confirmar endereço" />
    </form>
  );
};

AddressForm.propTypes = {
  addressToEdit: PropTypes.any,
  selectedNeighborhood: PropTypes.string,
  handleOnSubmit: PropTypes.func.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
};

AddressForm.defaultProps = {
  selectedNeighborhood: '',
  addressToEdit: {},
};

const mapStateToProps = (state) => ({
  isOnlineCommand: state.establishment.isOnlineCommand,
});

export default connect(mapStateToProps, null)(AddressForm);
