const INITIAL_STATE = {
  linkAccessed: '',
  lastOrderId: '',
};

export default function system(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@system/ADD_LINK_ACCESSED':
      return {
        ...state,
        linkAccessed: action.linkAccessed,
      };

    case '@system/ADD_LAST_ORDER_ID':
      return {
        ...state,
        lastOrderId: action.lastOrderId,
      };

    default:
      return state;
  }
}
