import styled from 'styled-components';
import { UIFixedBottom } from '../../UI';

export const ProductAddToCart = styled(UIFixedBottom)`
  z-index: 5;
  display: flex;
  align-items: center;
  flex-direction: column;
  .ProductAddToCart {
    &__button {
      height: 42px;
      border-radius: 0;
      margin-left: 10px;
      padding: 10px 15px;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        * {
          color: #FFF;
        }
      }
    }
  }
  @media screen and (max-width: 340px){
    padding: 15px 10px;
    &__button {
      height: 37px;
    }
  }
`;

export const IsClosedInfos = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 10px;
  align-items: center;
  flex-direction: column;
`;
