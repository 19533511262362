import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UIButton } from '../../../UI';
import { formatNumberToReal } from '../../../../utils/formatting';
import * as S from './styles';

const MinValueAlertModal = ({ showModal, setShowModal, minOrder }) => {
  const { establishment } = useParams();

  return (
    <S.CartFixedButtonAlertModal
      showModal={showModal}
      modalTitle="Valor mínimo"
      setShowModal={(show) => setShowModal(show)}
    >
      <span className="alert">
        O pedido mínimo para entrega desse restaurante é
        {' '}
        {formatNumberToReal(minOrder)}
        , sem
        contar com a taxa de entrega.
      </span>
      <UIButton
        text="Adicionar mais itens"
        linkTo={`/${establishment}/produtos`}
      />
      <UIButton
        transparent
        text="Cancelar"
        onClick={() => setShowModal(false)}
      />
    </S.CartFixedButtonAlertModal>
  );
};

MinValueAlertModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  minOrder: PropTypes.number.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  minOrder: state.establishment.minOrder,
});

export default connect(mapStateToProps, null)(MinValueAlertModal);
