import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../../../store/modules/user/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import CartPaymentChooseModal from '../../Payment/ChooseModal';
import { getPaymentIcon } from '../../../../utils/getPaymentIcon';
import { getCartTotal } from '../../../../store/selectors';
import { formatRealToNumber } from '../../../../utils/formatting';
import {
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../UI';

const CartFormPaymentMethod = ({
  cartTotalNum,
  isOnlineCommand,
  informationalUse,
  userPaymentMethod,
  resetUserPaymentMethod,
}) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // validated if the changeValue is smaller than the cart Total
    if (
      userPaymentMethod
      && userPaymentMethod.changeValue
      && formatRealToNumber(userPaymentMethod.changeValue) !== 0
      && formatRealToNumber(userPaymentMethod.changeValue) < cartTotalNum
    ) {
      resetUserPaymentMethod();
    }
  }, [userPaymentMethod, resetUserPaymentMethod, cartTotalNum]);

  const getChangeValue = () => {
    const numChangeValue = formatRealToNumber(userPaymentMethod.changeValue);
    if (userPaymentMethod.id === 'dinheiro' && numChangeValue > 0) {
      if (cartTotalNum === numChangeValue) {
        return (
          <span>
            Não precisa de troco
          </span>
        );
      }

      return (
        <span>
          Troco para:
          {' '}
          {userPaymentMethod.changeValue}
        </span>
      );
    }

    return null;
  };

  const getLabel = () => {
    if (informationalUse || isOnlineCommand) {
      return 'Forma de pagamento';
    }

    return 'Como você vai pagar?';
  };

  return (
    <>
      <UICustomInputLabel
        labelTitle={getLabel()}
        isValited={!!userPaymentMethod.id}
        isRequired={!isOnlineCommand && !informationalUse}
      />

      <>
        {userPaymentMethod.id ? (
          <UIButtonToEdit
            className="mb-20"
            disabled={informationalUse}
            noRightIcon={informationalUse}
            icon={getPaymentIcon(userPaymentMethod)}
            onClick={() => setShowModal(true)}
            secondElement={(
              <strong>
                {userPaymentMethod.flag?.name
                  ? `${userPaymentMethod.name} ${userPaymentMethod.flag.name}`
                  : userPaymentMethod.name}
              </strong>
            )}
            firstElement={(
              <span className="title">
                {userPaymentMethod.id === 'pix'
                  ? 'Pagar após finalizar pedido'
                  : 'Pagar na entrega'}
              </span>
            )}
            thirdElement={getChangeValue()}
          />
        ) : (
          <UIButton
            outline
            className="mb-20"
            text="Forma de pagamento"
            onClick={() => setShowModal(true)}
          />
        )}
      </>

      {showModal && (
        <CartPaymentChooseModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );
};

CartFormPaymentMethod.propTypes = {
  cartTotalNum: PropTypes.number.isRequired,
  resetUserPaymentMethod: PropTypes.func.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  userPaymentMethod: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    changeValue: PropTypes.string,
    flag: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  cartTotalNum: getCartTotal(state),
  userPaymentMethod: state.user.paymentMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormPaymentMethod);
