import React from 'react';
import { GiTable } from 'react-icons/gi';
import { ImPhone } from 'react-icons/im';
import { FaCashRegister, FaWifi } from 'react-icons/fa';

export const orderOrigins = [
  { name: 'Online', label: 'online', icon: <FaWifi /> },
  { name: 'Mesa', label: 'table', icon: <GiTable /> },
  { name: 'Balcão', label: 'counter', icon: <FaCashRegister /> },
  { name: 'Telefone', label: 'phone', icon: <ImPhone /> },
];
