import { call, put, select } from 'redux-saga/effects';
import { orderBySmallestToLargest } from '../../../utils/orderBy';
import {
  addUserAddress,
  addUserAdressToFar,
  addUserAdressNotFound,
} from './actions';
import { addDelivery, resetCartDeliveryState } from '../cart/actions';
import { getSeparetedStrings } from '../../../utils/getSeparetedStrings';
import { formatNumberToReal } from '../../../utils/formatting';
import { getDistanceFromAdresses } from '../../../utils/getDistanceFromAdresses';
import { checkIfAddressHasEnoughInfos } from '../../../utils/addressInfos';

const getEstablishmentLocation = (state) => state.establishment.location;
const getEstablishmentDeliveryRates = (state) => state.establishment.deliveryRates;
const getEstablishmentIsOnlineCommand = (state) => state.establishment.isOnlineCommand;

export function* getUserDistance(action) {
  const deliveryRates = yield select(getEstablishmentDeliveryRates);
  const establishmentAddress = yield select(getEstablishmentLocation);
  const isOnlineCommand = yield select(getEstablishmentIsOnlineCommand);

  const getRatesBySelectedDeliveryType = () => {
    const {
      rateUnique,
      rateToBeAgreed,
      ratesByDistance,
      ratesByNeighborhood,
    } = deliveryRates;

    if (deliveryRates.type === 'rateUnique') return rateUnique;
    if (deliveryRates.type === 'rateToBeAgreed') return rateToBeAgreed;
    if (deliveryRates.type === 'rateByDistance') return ratesByDistance;
    if (deliveryRates.type === 'rateByNeighborhood') return ratesByNeighborhood;

    return [];
  };

  const rates = getRatesBySelectedDeliveryType();

  if (deliveryRates.type === 'rateByNeighborhood') {
    const rateBySelectedNeighborhood = rates.filter(
      ({ neighborhood, active }) => neighborhood === action.address.neighborhood && active === true,
    );
    const {
      minTime, maxTime, rate, rateToBeAgreed,
    } = rateBySelectedNeighborhood[0];
    const estimatedTime = (minTime && maxTime) ? `${getSeparetedStrings([minTime, maxTime], '-')}min` : '';

    if (rateToBeAgreed) {
      yield put(
        addDelivery({
          rate: null,
          estimatedTime,
          formattedRate: '',
          rateToBeAgreed: true,
        }),
      );
    } else {
      yield put(
        addDelivery({
          rate,
          estimatedTime,
          rateToBeAgreed: false,
          formattedRate: formatNumberToReal(rate),
        }),
      );
    }

    yield put(addUserAddress(action.address));

    return;
  }

  if (deliveryRates.type === 'rateByDistance' || (deliveryRates.type === 'rateToBeAgreed' && isOnlineCommand)) {
    try {
      const clientAddressHasEnoughInfos = checkIfAddressHasEnoughInfos(action.address);

      if (isOnlineCommand && !clientAddressHasEnoughInfos) {
        yield put(
          addDelivery({
            rate: null,
            estimatedTime: '',
            formattedRate: '',
            rateToBeAgreed: false,
          }),
        );
        yield put(addUserAddress(action.address));

        return;
      }

      const orderedDeliveryRates = orderBySmallestToLargest(
        rates,
        'rateUntilMeter',
      );

      const {
        text: clientDistanceText,
        value: clientDistanceInMeters,
      } = yield call(getDistanceFromAdresses, {
        destiny: action.address,
        origin: establishmentAddress,
      });

      if (clientDistanceInMeters >= 0) {
        if (deliveryRates.type === 'rateToBeAgreed') {
          const {
            minTime, maxTime,
          } = rates[0];
          const estimatedTime = (minTime && maxTime) ? `${getSeparetedStrings([minTime, maxTime], '-')}min` : '';
          yield put(
            addDelivery({
              rate: null,
              estimatedTime,
              formattedRate: '',
              rateToBeAgreed: false, // is false because is required the establishment set one rate
              clientDistance: clientDistanceText,
            }),
          );
          yield put(addUserAddress(action.address));

          return;
        }

        const rateForClientDistance = orderedDeliveryRates.find(
          ({ rateUntilMeter, active }) => (
            rateUntilMeter >= clientDistanceInMeters && active === true
          ),
        );

        if (rateForClientDistance) {
          const {
            rate, minTime, maxTime, rateToBeAgreed,
          } = rateForClientDistance;
          const estimatedTime = (minTime && maxTime) ? `${getSeparetedStrings([minTime, maxTime], '-')}min` : '';
          if (rateToBeAgreed) {
            yield put(
              addDelivery({
                rate: null,
                estimatedTime,
                formattedRate: '',
                rateToBeAgreed: true,
                clientDistance: clientDistanceText,
              }),
            );
          } else {
            yield put(
              addDelivery({
                rate,
                estimatedTime,
                rateToBeAgreed: false,
                clientDistance: clientDistanceText,
                formattedRate: formatNumberToReal(rate),
              }),
            );
          }

          yield put(addUserAddress(action.address));
        } else {
          yield put(addUserAdressToFar(action.address));
          yield put(resetCartDeliveryState());
        }
      } else {
        yield put(addUserAdressNotFound());
        yield put(resetCartDeliveryState());
      }
    } catch (err) {
      yield put(addUserAdressNotFound());
      yield put(resetCartDeliveryState());
    }
  }

  if (deliveryRates.type === 'rateUnique' || deliveryRates.type === 'rateToBeAgreed') {
    const {
      minTime, maxTime, rate, rateToBeAgreed,
    } = rates[0];
    const estimatedTime = (minTime && maxTime) ? `${getSeparetedStrings([minTime, maxTime], '-')}min` : '';

    yield put(
      addDelivery({
        estimatedTime,
        rateToBeAgreed,
        rate: deliveryRates.type === 'rateToBeAgreed' ? null : rate,
        formattedRate: deliveryRates.type === 'rateToBeAgreed' ? '' : formatNumberToReal(rate),
      }),
    );
    yield put(addUserAddress(action.address));
  }
}
