import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../../../store/modules/user/actions';
import { UIInputPhone } from '../../../UI';

const CartFormPhone = ({
  userPhone,
  orderOrigin,
  addUserPhone,
  isOnlineCommand,
  userDeliveryMethod,
}) => {
  const getIfIsRequired = () => {
    if (isOnlineCommand) {
      return false;
    }

    if (orderOrigin === 'counter' && userDeliveryMethod.label === 'delivery') {
      return true;
    }

    if (orderOrigin === 'online' || orderOrigin === 'phone') {
      return true;
    }

    return false;
  };
  const isRequired = getIfIsRequired();

  return (
    <UIInputPhone
      value={userPhone}
      required={isRequired}
      placeholder="Ex: (11) 90000-0000"
      label={(isOnlineCommand) ? 'Número do celular do cliente' : 'Número do seu celular'}
      description={!isRequired ? 'Informação necessária para cadastrar o cliente no programa de fidelidade' : ''}
      setValue={(newPhone) => {
        addUserPhone(newPhone);
      }}
    />
  );
};

CartFormPhone.propTypes = {
  userPhone: PropTypes.string.isRequired,
  addUserPhone: PropTypes.func.isRequired,
  orderOrigin: PropTypes.string.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  userDeliveryMethod: PropTypes.objectOf(PropTypes.node).isRequired,
};

const mapStateToProps = (state) => ({
  userPhone: state.user.phone,
  orderOrigin: state.cart.orderOrigin,
  userDeliveryMethod: state.user.deliveryMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormPhone);
