import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { EmptySearchIcon } from '../../../../assets/svgs/EmptySearch';
import * as S from './styles';

const MenuFilterEmptySearch = ({ search }) => {
  const { establishment } = useParams();

  return (
    <S.NegativeResult>
      <EmptySearchIcon />
      <S.TextSupport>Poxa, não encontramos nenhum resultado para</S.TextSupport>
      <S.Result>
        {`"${search}"`}
      </S.Result>
      <S.SearchButton
        outline
        text="Ver todos os produtos"
        linkTo={`/${establishment}/produtos`}
      />
    </S.NegativeResult>
  );
};

MenuFilterEmptySearch.propTypes = {
  search: PropTypes.string,
};

MenuFilterEmptySearch.defaultProps = {
  search: '',
};

export default MenuFilterEmptySearch;
