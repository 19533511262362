import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { CgMathPlus } from 'react-icons/cg';
import Loader from 'react-loader-spinner';
import { FaUserPlus, FaUser } from 'react-icons/fa';
import ClientFormModal from '../ClientFormModal';
import ClientDetailsModal from '../DetailsModal';
import * as CartActions from '../../../../../store/modules/cart/actions';
import * as UserActions from '../../../../../store/modules/user/actions';
import * as AddressesActions from '../../../../../store/modules/addresses/actions';
import { FirebaseCustomers } from '../../../../../firebase/functions';
import * as S from './styles';
import {
  UIModal,
  UIButton,
  UIButtonToEdit,
  UIInputHookForm,
} from '../../../../UI';

const SelectCustomerModal = ({
  showSelectCustomerModal,
  setShowSelectCustomerModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [currentSearch, setCurrentSearch] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [showFormClientModal, setShowFormClientModal] = useState(false);
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSearch = async (value) => {
    setLoading(true);
    setCurrentSearch(value);
    const dataCustomers = await FirebaseCustomers.putSearchCustomer(value);
    setCustomers(dataCustomers);
    setLoading(false);
  };

  const handleOnSubmit = (data) => {
    const { clientNameOrPhone } = data;
    onSearch(clientNameOrPhone);
  };

  return (
    <>
      <UIModal
        modalTitle="Selecione o cliente"
        showModal={showSelectCustomerModal}
        setShowModal={setShowSelectCustomerModal}
      >
        <S.InputContainer>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <UIInputHookForm
              required
              type="text"
              minLength={3}
              errors={errors}
              register={register}
              name="clientNameOrPhone"
              label="Nome ou número do cliente"
              placeholder="Nome ou número do cliente"
            />

            <UIButton type="submit" className="mb-20" disabled={loading}>
              {loading ? (
                <Loader width={20} height={20} color="#FFF" type="TailSpin" />
              ) : (
                <span>Pesquisar cliente</span>
              )}
            </UIButton>
          </form>

          <UIButton
            transparent
            text="Cadastrar novo cliente"
            icon={<CgMathPlus size={18} />}
            onClick={() => setShowFormClientModal(true)}
          />
        </S.InputContainer>

        {loading && (
          <S.LoaderContainer>
            <Loader type="TailSpin" color="#a30a38" />
          </S.LoaderContainer>
        )}

        {!loading && (
          <>
            {!currentSearch && (
              <S.EmptySearchContainer>
                <span className="mb-20">
                  Pesquise o seu cliente pelo nome ou número de telefone, ou
                </span>
                <UIButtonToEdit
                  noRightIcon
                  icon={<FaUserPlus size={18} />}
                  firstElement={<strong>Cadastre um novo cliente</strong>}
                  onClick={() => {
                    setShowFormClientModal(true);
                  }}
                />
              </S.EmptySearchContainer>
            )}

            {currentSearch && (
              <div>
                {customers?.length <= 0 && (
                  <div>
                    <S.SearchNotFindText>
                      Não encontramos nenhum cliente para a pesquisa
                    </S.SearchNotFindText>
                    <S.SearchNotFindSearchedText>
                      {` "${currentSearch}"`}
                    </S.SearchNotFindSearchedText>
                  </div>
                )}

                {customers?.length > 0
                  && customers.map((customer) => (
                    <UIButtonToEdit
                      noRightIcon
                      key={customer.id}
                      className="mb-20"
                      icon={<FaUser />}
                      onClick={() => {
                        setShowClientDetailsModal(true);
                        setSelectedCustomer(customer);
                      }}
                      secondElement={<span>{customer.phone}</span>}
                      firstElement={<strong>{customer.name}</strong>}
                    />
                  ))}
              </div>
            )}
          </>
        )}
      </UIModal>

      {showClientDetailsModal && (
        <ClientDetailsModal
          showModal={showClientDetailsModal}
          selectedCustomer={selectedCustomer}
          setShowModal={setShowClientDetailsModal}
          setShowFormModal={setShowFormClientModal}
          closeChooseClientModal={() => setShowSelectCustomerModal(false)}
        />
      )}

      {showFormClientModal && (
        <ClientFormModal
          showModal={showFormClientModal}
          selectedCustomer={selectedCustomer}
          setShowModal={setShowFormClientModal}
          closeChooseClientModal={() => setShowSelectCustomerModal(false)}
        />
      )}
    </>
  );
};

SelectCustomerModal.propTypes = {
  showSelectCustomerModal: PropTypes.bool.isRequired,
  setShowSelectCustomerModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { ...CartActions, ...UserActions, ...AddressesActions },
  dispatch,
);

export default connect(null, mapDispatchToProps)(SelectCustomerModal);
