import PropTypes from 'prop-types';

export const PropCustomerAddress = PropTypes.shape({
  uf: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  complement: PropTypes.string.isRequired,
  neighborhood: PropTypes.string.isRequired,
  referencePoint: PropTypes.string.isRequired,
});
