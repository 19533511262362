import cart from './cart/reducer';
import user from './user/reducer';
import system from './system/reducer';
import addresses from './addresses/reducer';
import establishment from './establishment/reducer';

export default {
  cart,
  user,
  system,
  addresses,
  establishment,
};
