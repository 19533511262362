import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartCouponAddModal from './AddModal';
import * as S from './styles';
import { CartCouponAdd } from './Add';
import CartCouponInfos from './Infos';

const CartCoupon = ({ establishmentHasActiveCoupons, activeCoupon, informationalUse }) => {
  const [showModal, setShowModal] = useState(false);

  if (!establishmentHasActiveCoupons) {
    return null;
  }

  if (informationalUse && !activeCoupon?.name) {
    return null;
  }

  return (
    <>
      <S.CartCouponTitle labelTitle="Cupom" />
      {activeCoupon && activeCoupon.name ? (
        <CartCouponInfos setShowModal={setShowModal} informationalUse={informationalUse} />
      ) : (
        <CartCouponAdd setShowModal={setShowModal} />
      )}

      <CartCouponAddModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

CartCoupon.propTypes = {
  informationalUse: PropTypes.bool.isRequired,
  establishmentHasActiveCoupons: PropTypes.bool.isRequired,
  activeCoupon: PropTypes.shape({
    name: PropTypes.string,
    couponType: PropTypes.string,
    value: PropTypes.number,
    maxValue: PropTypes.number,
    minOrderValue: PropTypes.number,
    quantity: PropTypes.number,
    availability: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.arrayOf(PropTypes.string),
    hour: PropTypes.shape({
      final: PropTypes.string,
      initial: PropTypes.string,
    }),
    date: PropTypes.shape({
      final: PropTypes.number,
      initial: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  activeCoupon: state.cart.activeCoupon,
  establishmentHasActiveCoupons: state.establishment.hasActiveCoupons,
});

export default connect(mapStateToProps, null)(CartCoupon);
