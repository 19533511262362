import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';

import './UIFixedBottom.scss';

const UIFixedBottom = ({ className, children, hideModal }) => {
  const fixedBottomRef = useRef();
  const windowWidth = useWindowWidth();
  const [currentSize, setCurrentSize] = useState(0);

  useLayoutEffect(() => {
    if (fixedBottomRef && fixedBottomRef.current) {
      setCurrentSize(fixedBottomRef.current.offsetHeight);
    }
  }, [windowWidth]);

  return (
    <>
      {!hideModal && (
        <div
          ref={fixedBottomRef}
          className={`UIFixedBottom ${className}`}
        >
          {children}
        </div>
      )}
      <div style={{ minHeight: currentSize }} />
    </>
  );
};

UIFixedBottom.propTypes = {
  className: PropTypes.string,
  hideModal: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

UIFixedBottom.defaultProps = {
  className: '',
  hideModal: false,
};

export default UIFixedBottom;
