import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AiFillCheckCircle, AiOutlineWhatsApp } from 'react-icons/ai';
import dayjs from 'dayjs';
import { UIButton, UIFixedBottom, UIModal } from '../../UI';
import { FirebaseOrders } from '../../../firebase/functions';
import Cart from '../index';
import * as CartActions from '../../../store/modules/cart/actions';
import * as UserActions from '../../../store/modules/user/actions';
import * as SystemActions from '../../../store/modules/system/actions';
import EstablishmentBrand from '../../EstablishmentBrand';
import { whatsappSendMessage } from '../../../utils/whatsapp/sendMessage';
import * as S from './styles';

const CartLastOrderSuccess = ({
  name,
  lastOrderId,
  linkAccessed,
  addLastOrderId,
  establishmentId,
  addUserInfosFromLastOrder,
  addOrderInfosFromLastOrder,
}) => {
  const [lastOrder, setLastOrder] = useState(null);

  useEffect(() => {
    (async () => {
      if (lastOrderId) {
        const orderData = await FirebaseOrders.getOrderById(lastOrderId);

        if (orderData.establishmentId === establishmentId) {
          // necessary this parser because the cart redux wait
          // the additionals and variations with these names
          const newItemsValue = orderData.items.map((item) => ({
            ...item,
            selectedVariations: item.variations,
            selectedAdditionals: item.additionals,
          }));

          const newOrderDataForRedux = {
            ...orderData,
            items: newItemsValue,
          };

          setLastOrder(orderData);
          addUserInfosFromLastOrder(newOrderDataForRedux);
          addOrderInfosFromLastOrder(newOrderDataForRedux);
        }
      }
    })();
  }, [
    lastOrderId,
    establishmentId,
    addUserInfosFromLastOrder,
    addOrderInfosFromLastOrder,
  ]);

  const handleCloseModal = () => {
    addLastOrderId('');
    window.location.replace(linkAccessed);
  };

  if (!lastOrder?.id) return null;

  const date = lastOrder?.serverTimestamp.toDate();
  const orderHour = dayjs(date).format('HH:mm');
  const completeOrderDate = dayjs(date).format('DD/MM/YYYY - HH:mm');

  return (
    <UIModal
      showModal
      setShowModal={handleCloseModal}
      modalTitle="Informações do último pedido"
    >
      <S.LastOrderDescription>
        Veja abaixo os dados do seu
        {' '}
        <b>último pedido</b>
      </S.LastOrderDescription>

      <S.EstablishmentInfos>
        <EstablishmentBrand size={70} />
        <div className="infos">
          <span>{name}</span>
          <small>
            Pedido nº
            {lastOrder?.orderNumber}
            {` - ${completeOrderDate}`}
          </small>
        </div>
      </S.EstablishmentInfos>

      <S.SuccessContainer>
        <AiFillCheckCircle
          size={24}
          color="var(--sucess-color)"
        />
        <small>
          Pedido concluído às
          {' '}
          {orderHour}
        </small>
      </S.SuccessContainer>

      <Cart
        informationalUse
      />

      <UIFixedBottom>
        <S.ButtonsContainer>
          <small>Deseja confirmar seu pedido no Whatsapp do restaurante ?</small>

          <UIButton
            outline
            type="button"
            className="mb-20"
            text="Enviar pedido no Whatsapp"
            onClick={() => whatsappSendMessage(lastOrder)}
            icon={(
              <div style={{ padding: 0 }}>
                <AiOutlineWhatsApp size={22} />
              </div>
            )}
          />

          <UIButton
            type="button"
            onClick={handleCloseModal}
          >
            <span>Ver cardápio</span>
          </UIButton>
        </S.ButtonsContainer>
      </UIFixedBottom>
    </UIModal>
  );
};

CartLastOrderSuccess.propTypes = {
  name: PropTypes.string.isRequired,
  lastOrderId: PropTypes.string.isRequired,
  linkAccessed: PropTypes.string.isRequired,
  addLastOrderId: PropTypes.func.isRequired,
  establishmentId: PropTypes.string.isRequired,
  addUserInfosFromLastOrder: PropTypes.func.isRequired,
  addOrderInfosFromLastOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  name: state.establishment.name,
  lastOrderId: state.system.lastOrderId,
  establishmentId: state.establishment.id,
  linkAccessed: state.system.linkAccessed,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...CartActions,
    ...UserActions,
    ...SystemActions,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CartLastOrderSuccess);
