export function getIfNowIsBetweenTwoHours(fromTime, untilTime) {
  const dateNow = new Date();
  const fromTimeObj = {
    hour: Number(fromTime.slice(0, 2)),
    minutes: Number(fromTime.slice(3, 5)),
  };
  const untilTimeObj = {
    hour: Number(untilTime.slice(0, 2)),
    minutes: Number(untilTime.slice(3, 5)),
  };
  // until menor que from
  const untilSmallerThanFromTime = untilTimeObj.hour < fromTimeObj.hour;

  const timeNow = dateNow.getHours() + dateNow.getMinutes() / 100;
  const fromTimeComplete = fromTimeObj.hour + fromTimeObj.minutes / 100;
  const untilTimeComplete = untilTimeObj.hour + untilTimeObj.minutes / 100;

  const customFromTimeHour = (
    untilSmallerThanFromTime
        && timeNow < fromTimeComplete
        && timeNow < untilTimeComplete
  )
    ? fromTimeObj.hour - 24
    : fromTimeObj.hour;

  const customUntilTimeHour = (
    untilSmallerThanFromTime
        && timeNow > untilTimeComplete
        && timeNow >= fromTimeComplete
  )
    ? untilTimeObj.hour + 24
    : untilTimeObj.hour;

  const openTimestamp = new Date().setHours(
    customFromTimeHour,
    fromTimeObj.minutes,
  );
  const closeTimestamp = new Date().setHours(
    customUntilTimeHour,
    untilTimeObj.minutes,
  );
  const nowTimestamp = new Date().setHours(
    dateNow.getHours(),
    dateNow.getMinutes(),
  );

  return nowTimestamp >= openTimestamp && nowTimestamp < closeTimestamp;
}
