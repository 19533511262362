import { separateParagraphs } from './separateParagraphs';
import { getSeparetedStrings } from './getSeparetedStrings';

export const checkIfAddressHasEnoughInfos = (address) => {
  const {
    street, number, neighborhood, latitude, longitude,
  } = address;

  if (latitude && longitude) {
    return true;
  }

  if (street && number && neighborhood) {
    return true;
  }

  return false;
};

export const getAddressInfosInLine = (address) => {
  const {
    city, street, number, neighborhood, uf, cep, latitude, longitude,
  } = address;

  if (latitude && longitude) {
    return `${latitude}, ${longitude}`;
  }

  return `${street}, ${number} - ${neighborhood}, ${city} - ${uf}, ${cep}, Brasil`;
};

export const getNeighborhoodCityAndUF = (address) => {
  const {
    uf,
    city,
    neighborhood,
  } = address;
  const cityAndUF = getSeparetedStrings([city, uf], '/');
  const neighborhoodCityAndUF = neighborhood || city || uf
    ? getSeparetedStrings([neighborhood, cityAndUF], ', ')
    : '';

  return neighborhoodCityAndUF;
};

export const getAddressInfosInSeparatedLines = (address, separateInsideDiv) => {
  const {
    cep,
    number,
    street,
    complement,
    referencePoint,
  } = address;
  const neighborhoodCityAndUF = getNeighborhoodCityAndUF(address);

  const generateSeparatedTexts = () => {
    if (separateInsideDiv) {
      return separateParagraphs([
        `<div>${street}, ${number} ${
          complement ? `- ${complement}` : ''
        }</div>`,
        `${neighborhoodCityAndUF ? `<div>${neighborhoodCityAndUF}</div>` : ''}`,
        `${cep ? `<div>${cep}</div>` : ''}`,
        `${referencePoint ? `<div>${referencePoint}</div>` : ''}`,
      ]);
    }

    return separateParagraphs([
      `${street}, ${number} ${complement ? `- ${complement}` : ''}`,
      `${neighborhoodCityAndUF || ''}`,
      `${cep || ''}`,
      `${referencePoint || ''}`,
    ]);
  };

  return generateSeparatedTexts();
};
