import styled, { css } from 'styled-components';
import { StickyContainer } from 'react-sticky';

export const WhiteContainer = styled(StickyContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  transition: 1s;
  max-width: 600px;
  min-height: 200px;
  background: #fff;
  position: relative;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

  ${(props) => props.$isSmall
    && css`
      width: 70%;
      height: 70%;
      @media screen and (max-width: 500px) {
        width: 90%;
        height: 90%;
      }
    `}

  ${(props) => props.$autoHeight
    && css`
      height: unset;
      @media screen and (max-width: 500px) {
        height: unset;
      }
    `}

  ${(props) => props.$noHorizontalPadding
    && css`
      padding: 0 0 20px 0;
    `}

  ${(props) => props.$noBottomPadding
    && css`
      padding-bottom: 0;
    `}
`;
