import styled from 'styled-components';

export const MenuItemsGroupHeader = styled.div`
  height: 60px;
  display: flex;
  font-weight: 500;
  align-items: center;
  text-transform: capitalize;
  background: var(--default-gray);
  padding: 0 var(--grid-margin-space);
  margin: 0 calc(-1 * var(--grid-margin-space)) 20px calc(-1 * var(--grid-margin-space));
`;
