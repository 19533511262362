import styled, { css } from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 25px;
  justify-content: space-between;
  & > span {
    display: flex;
    color: #5E5E5E;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    align-items: center;
  }
`;

export const OpenBorder = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  padding: 4px 30px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  border-radius: 12.5px;
  border: 1px solid #000;
  text-transform: lowercase;
  ${({ open }) => !open && css`
    color: white;
    background: #000;
  `}
`;
