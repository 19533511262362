import React from 'react';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';
import ShopWindowItem from './Item';

import * as S from './styles';

const ShopWindow = ({ items, simpleModel }) => (
  <S.ShopWindow
    allowTouchMove
    slidesPerView="auto"
    slideToClickedSlide
    slidesOffsetAfter={0}
  >
    {items.map((item) => (
      <SwiperSlide className="slide" key={item.id}>
        <ShopWindowItem item={item} simpleModel={simpleModel} />
      </SwiperSlide>
    ))}
  </S.ShopWindow>
);

ShopWindow.propTypes = {
  simpleModel: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)).isRequired,
};

ShopWindow.defaultProps = {
  simpleModel: false,
};

export default ShopWindow;
