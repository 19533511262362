/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { SwiperSlide } from 'swiper/react';

import * as S from './styles';

export const UIHorizontalOptions = ({ options, selected, setSelected }) => (
  <S.SliderContainer>
    <S.OptionsDelivery
      allowTouchMove
      slidesPerView="auto"
      slideToClickedSlide
      slidesOffsetAfter={3}
    >
      {options.map(({
        title, subtitle, value, hoursOptions,
      }) => {
        if (hoursOptions?.length <= 0) return null;
        return (
          <SwiperSlide className="slide" key={value}>
            <S.LabelDelivery selected={value === selected} onClick={() => setSelected(value)}>
              <strong>{title}</strong>
              <span>{subtitle}</span>
            </S.LabelDelivery>
          </SwiperSlide>
        );
      })}
    </S.OptionsDelivery>
  </S.SliderContainer>
);

UIHorizontalOptions.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
