import React from 'react';
import PropTypes from 'prop-types';

import './NewMenuItems.scss';
import { AnimatePresence, motion } from 'framer-motion';
import MenuItem from '../../Item';

const NewMenuItems = ({
  items,
}) => (
  items && items.length > 0 ? (
    <AnimatePresence>
      {items.map((item) => (
        <motion.li
          key={item.id}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <MenuItem
            item={item}
          />
        </motion.li>
      ))}
    </AnimatePresence>
  ) : null
);

NewMenuItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    menu: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    priceIsNotFixed: PropTypes.bool,
    promotionalPrice: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    priceType: PropTypes.oneOf(['single', 'multiple']),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
  })).isRequired,
};

export default NewMenuItems;
