import React, { useEffect, useState, useCallback } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { Sticky } from 'react-sticky';
import Fuse from 'fuse.js';
import * as S from './style';
import MenuItem from '../../Item';
import EmptySearch from '../EmptySearch/EmptySearch';
import { FirebaseProduct } from '../../../../firebase/functions';
import { UILoader } from '../../../UI';
import { getOnlyAlphaNums } from '../../../../utils/getOnlyAlphaNums';
import { addFacebookSearchEvent } from './utils';

const Search = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState('');
  const { establishment } = useParams();

  useEffect(() => {
    async function getProducts() {
      const productsData = await FirebaseProduct.getAllProducts();
      setResults(productsData);
      setAllProducts(productsData);
      setLoading(false);
    }
    getProducts();
  }, []);

  const onSearch = useCallback(
    ({ currentTarget: { value } }) => {
      setQuery(value);
      const parsedValue = getOnlyAlphaNums(value);
      const options = {
        threshold: 0.4,
        includeScore: true,
        ignoreLocation: true,
        isCaseSensitive: false,
        keys: ['name', 'description'],
        minMatchCharLength: parsedValue.length,
        getFn: (obj, path) => getOnlyAlphaNums(Fuse.config.getFn(obj, path)),
      };
      if (parsedValue && parsedValue.length > 0) {
        const fuse = new Fuse(allProducts, options);
        const filteredProducts = fuse.search(parsedValue).map(({ item }) => item);
        setResults(filteredProducts);
      } else {
        setResults(allProducts);
      }
    },
    [allProducts],
  );

  return (
    <div>
      <Sticky topOffset={60}>
        {({ style, isSticky }) => (
          <S.ContainerSearch $isSticky={isSticky} style={{ ...style }}>
            <Link to={`/${establishment}/produtos`}>
              <FiArrowLeft />
            </Link>
            <S.InputSearch
              type="text"
              value={query}
              onChange={onSearch}
              placeholder="Buscar Produtos"
              onBlur={() => addFacebookSearchEvent(query)}
            />
            <S.LupeStyled />
          </S.ContainerSearch>
        )}
      </Sticky>

      {loading ? (
        <UILoader />
      ) : (
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          {results?.length > 0 ? (
            <>
              <S.ResultsNumberContainer>
                <S.ResultsNumber>
                  Encontramos
                  {' '}
                  {results.length}
                  {' '}
                  produto(s)
                </S.ResultsNumber>
              </S.ResultsNumberContainer>
              {results.map((item) => (
                <MenuItem
                  item={item}
                  key={item.id}
                />
              ))}
            </>
          ) : (
            <EmptySearch search={query} />
          )}
        </div>
      )}

    </div>
  );
};

export default Search;
