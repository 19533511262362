import React from 'react';
import { useParams } from 'react-router';
import HeaderSimpleWithReturn from '../../components/Header/SimpleWithReturn';
import Product from '../../components/Product';

export const ScreensProduct = () => {
  const { establishment } = useParams();
  return (
    <>
      <HeaderSimpleWithReturn
        noMarginBottom
        noHorizontalPadding
        title="Detalhes do produto"
        returnLink={`/${establishment}/produtos`}
      />

      <Product />
    </>
  );
};
