import styled from 'styled-components';

export const HomeHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
`;

export const HomeHeaderBanner = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  background: var(--primary-color);
  & > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;
