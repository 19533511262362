import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import * as S from './styles';

export const UILoader = ({ width, height, color }) => (
  <S.UILoader>
    <Loader
      color={color}
      width={width}
      height={height}
      type="TailSpin"
    />
  </S.UILoader>
);

UILoader.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

UILoader.defaultProps = {
  width: 99,
  height: 100,
  color: '#a30a38',
};
