import styled from 'styled-components';

export const Avatar = styled.div`
  display: flex;
  align-items: center;

  .infos {
    display: flex;
    font-size: 14px;
    margin-left: 10px;
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--default-gray);
  padding: 10px var(--grid-margin-space) 10px var(--grid-margin-space); 
  margin: 20px calc(-1 * var(--grid-margin-space)) 20px calc(-1 * var(--grid-margin-space));

  small {
    margin-bottom: 5px;
  }

  div {
    font-size: 14px;
  }
`;
