import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { uuid } from 'uuidv4';
import { useOpenPix } from '@openpix/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { UIModal, UIInput } from '../../../UI';
import { getFormattedCartTotal } from '../../../../store/selectors';
import * as S from './styles';
import { formatRealToNumber } from '../../../../utils/formatting';

const PixKeyModal = ({
  pixKey,
  showModal,
  cartTotal,
  autoPixID,
  accountOwner,
  setShowModal,
  handleCheckOut,
}) => {
  const [successOnCopyKey, setSuccessOnCopyKey] = useState(false);
  const [counter, setCounter] = useState(5);
  const [pixCharge, setPixCharge] = useState(null);

  const handleOnCopy = () => {
    if (!pixCharge?.brCode) {
      setSuccessOnCopyKey(true);
    }
    toast.success('Chave PIX copiada com sucesso');
  };

  const handleFinishOrder = () => {
    handleCheckOut(true);
    setShowModal(false);
  };

  const { chargeCreate } = useOpenPix({
    appID: autoPixID,
    onPay: handleFinishOrder,
  });

  useEffect(() => {
    (async () => {
      const { charge, error } = await chargeCreate({
        correlationID: uuid(),
        value: formatRealToNumber(cartTotal) * 100, // value in cents
      });

      if (error) {
        console.log(error);
        return;
      }

      setPixCharge(charge);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (counter > 0 && successOnCopyKey) {
      setTimeout(() => setCounter(counter - 1), 1000);
      return;
    }

    if (counter === 0 && successOnCopyKey) {
      handleFinishOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, successOnCopyKey]);

  return (
    <UIModal
      isSmall
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={pixCharge?.brCode ? 'Efetuar pagamento' : 'Finalizar pedido'}
    >
      <S.Container>
        {pixCharge && (
          <div className="tw-flex tw-items-center tw-justify-center tw-mb-4">
            <div className="tw-border tw-border-gray-300 tw-border-solid tw-rounded-md tw-p-2 tw-pb-0">
              <QRCode
                size={100}
                renderAs="svg"
                includeMargin={false}
                value={pixCharge.brCode}
              />
            </div>
          </div>
        )}

        <UIInput
          required
          readOnly
          type="text"
          customValidation
          validatedValue={successOnCopyKey}
          value={pixCharge?.brCode || pixKey}
          label="Copie a chave pix do restaurante para realizar o seu pedido"
        />

        <CopyToClipboard onCopy={handleOnCopy} text={pixCharge?.brCode || pixKey}>
          <S.CopyPixKeyButton
            className="mb-20"
            successOnCopyKey={successOnCopyKey}
            text={
              successOnCopyKey
                ? 'Chave PIX copiada com sucesso'
                : 'Copiar chave PIX'
            }
          />
        </CopyToClipboard>

        {pixCharge?.brCode && (
          <small style={{ textAlign: 'center' }}>
            Finalizeramos seu pedido automaticamente 5 segundos após você efetuar o pagamento
          </small>
        )}

        {!pixCharge?.brCode && (
          <>
            <span className="tw-mb-4">
              Use essa chave pix para efetuar o pagamento no valor de
              {' '}
              <b>{cartTotal}</b>
              {' '}
              para a conta do (a)
              {' '}
              <b>{accountOwner}</b>
              {' '}
              e após a transferência envie o comprovante de pagamento para o estabelecimento.
            </span>
            <S.FinishOrderButton
              type="button"
              className="mt-auto mb-10"
              onClick={handleFinishOrder}
              disabledAppearance={!successOnCopyKey}
              text={
                successOnCopyKey
                  ? `Finalizar pedido (${counter})`
                  : 'Finalizar pedido'
              }
              functionWhenDisable={() => {
                toast.warn('Copie a chave pix antes de finalizar seu pedido');
              }}
            />
            <small style={{ textAlign: 'center' }}>
              Finalizeramos seu pedido automaticamente 5 segundos após você copiar a
              chave PIX
            </small>
          </>
        )}
      </S.Container>
    </UIModal>
  );
};

PixKeyModal.propTypes = {
  pixKey: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  cartTotal: PropTypes.string.isRequired,
  autoPixID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleCheckOut: PropTypes.func.isRequired,
  accountOwner: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  cartTotal: getFormattedCartTotal(state),
  pixKey: state.user.paymentMethod.pixKey,
  autoPixID: state.establishment.autoPixID,
  accountOwner: state.user.paymentMethod.accountOwner,
});

export default connect(mapStateToProps, null)(PixKeyModal);
