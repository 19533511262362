import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { getWeekdayInString } from '../getWeekdayInString';
import { parseHourStringOnDayjsFormat } from '../parseHourStringOnDayjsFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const getTodayWeekdayTimes = (hours, customDate) => {
  const dateToValidate = customDate || dayjs();
  const todayWeekday = getWeekdayInString(dateToValidate.day());
  const yersterdayWeekday = getWeekdayInString(dateToValidate.subtract(1, 'day').day());

  const todayTimes = hours.filter(({ weekday }) => {
    if (dateToValidate.hour() < 5) {
      return yersterdayWeekday === weekday || weekday === todayWeekday;
    }

    return weekday === todayWeekday;
  });

  return todayTimes;
};

export const getActiveHourData = (hours, customTimezone, customDate) => {
  const todayTimes = getTodayWeekdayTimes(hours, customDate);
  const activeHours = todayTimes.filter((time) => time.isActive === true);

  if (activeHours.length <= 0) return {};

  const dateToValidate = customDate || dayjs();

  const openHours = activeHours.reduce((acc, time) => {
    time.hours.forEach((hour) => {
      let dayjsEndHour = parseHourStringOnDayjsFormat(
        hour.endHour, customDate,
      ).tz(customTimezone);
      let dayjsStartHour = parseHourStringOnDayjsFormat(
        hour.startHour, customDate,
      ).tz(customTimezone);
      const isYersterdayHour = getWeekdayInString(dateToValidate.day()) !== time.weekday;
      const isStartBiggerThanEnd = dayjsStartHour > dayjsEndHour;

      /** Até 5h da manhã é considerado fromTime do dia anterior,
       * então é retirado 1 dia da data de abertura * */
      if (dateToValidate.hour() < 5 && isStartBiggerThanEnd) {
        if (isYersterdayHour) {
          dayjsStartHour = dayjsStartHour.subtract(1, 'day');
        }

        if (!isYersterdayHour) {
          dayjsEndHour = dayjsEndHour.add(1, 'day');
        }
      }

      /** Se hora de abertura maior que hora de fechamento, adiciona um dia.
       *  Significa que o estabelecimento entra para a madrugada do dia seguinte. */
      if (dateToValidate.hour() >= 5 && isStartBiggerThanEnd) {
        dayjsEndHour = dayjsEndHour.add(1, 'day');
      }

      const isOpenNow = dateToValidate.isBetween(dayjsStartHour, dayjsEndHour);

      if (isOpenNow) {
        acc.push(hour);
      }
    }, []);

    return acc;
  }, []);

  return openHours[0];
};
