import React from 'react';
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom';
import '../../config/ReactotronConfig';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { StickyContainer } from 'react-sticky';
import { store } from '../../store';

import { ScreensHome } from '../Home';
import { ScreensCart } from '../Cart';
import { ScreensPage404 } from '../Page404';
import { ScreensProduct } from '../Product';
import { ScreensMenu } from '../Menu';
import { ScreensSearchProducts } from '../SearchProducts';

import ScrollToTop from '../../components/ScrollToTop';
import { RequiredLabelProvider } from '../../context/RequiredLabelContext';
import { URLChangeListenerForRN } from '../../components/URLChangeListenerForRN';
import HandleEstablishmentInfos from '../../components/HandleEstablishmentInfos';
import { ShowAdressSelectionProvider } from '../../context/ShowAddressSelection';

const ScreensRoutes = () => (
  <Provider store={store}>
    <Router>
      <ScrollToTop />
      <URLChangeListenerForRN />
      <RequiredLabelProvider>
        <ShowAdressSelectionProvider>
          <HandleEstablishmentInfos>
            <StickyContainer id="global-sticky-container">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/japa-da-flash" />
                </Route>

                <Route exact path="/:establishment" component={ScreensHome} />
                <Route exact path="/:establishment/cart" component={ScreensCart} />

                {/* search */}
                <Route exact path="/:establishment/buscar" component={ScreensSearchProducts} />

                {/* Multiple options because the project had multiple changes */}
                <Route exact path="/:establishment/produtos" component={ScreensMenu} />
                <Route exact path="/:establishment/cardapio" component={ScreensMenu} />
                <Route exact path="/:establishment/:productType" component={ScreensMenu} />

                <Route exact path="/:establishment/:productType/:productId" component={ScreensProduct} />
                <Route exact path="/:establishment/:productType/:productId/:productName?" component={ScreensProduct} />

                <Route path="*" component={ScreensPage404} />
              </Switch>
            </StickyContainer>
          </HandleEstablishmentInfos>
        </ShowAdressSelectionProvider>

        <ToastContainer
          draggable
          rtl={false}
          closeOnClick
          pauseOnHover
          autoClose={5000}
          pauseOnFocusLoss
          newestOnTop={false}
          position="top-right"
          hideProgressBar={false}
        />
      </RequiredLabelProvider>
    </Router>
  </Provider>
);

export default ScreensRoutes;
