import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AiOutlineShopping } from 'react-icons/ai';
import {
  Link, useParams,
} from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { fadeInUp } from 'react-animations';
import * as UserActions from '../../../store/modules/user/actions';
import './CartLinkFixed.scss';

import { getFormattedCartTotal } from '../../../store/selectors';

const bounceAnimation = keyframes`${fadeInUp}`;

const BouncyDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation: 1s ${bounceAnimation};
`;

const CartLinkFixed = ({
  cartTotal,
  cartLength,
}) => {
  // const history = useHistory();
  const { establishment } = useParams();
  // const [showAddedMsg, setShowAddedMsg] = useState(false);

  /* useEffect(() => {
    history.listen(() => {
      if (history.location === '/digrasi/produtos?from=product-page') {
        setShowAddedMsg(true);
      } else {
        setShowAddedMsg(false);
      }
    });
  }, [history]); */

  /* useEffect(() => {
    if (showAddedMsg) {
      setTimeout(() => {
        setShowAddedMsg(false);
      }, 20000);
    }
  }, [showAddedMsg]); */

  if (cartLength === 0) {
    return null;
  }

  return (
    <>
      <Link
        className="CartLinkFixed"
        to={`/${establishment}/cart`}
      >
        {/* {showAddedMsg ? (
          <span className="CartLinkFixed__added-item">Item adicionado a sacola</span>
        ) : ( */}
        <BouncyDiv>
          <div className="CartLinkFixed__bag">
            <AiOutlineShopping className="bag__icon" />
            <span className="bag__length">{cartLength}</span>
          </div>
          <span className="CartLinkFixed__title">Ver Pedido</span>
          <span className="CartLinkFixed__total">{cartTotal}</span>
        </BouncyDiv>
        {/* )} */}

      </Link>
      <div className="CartLinkFixed__extra-size" />
    </>
  );
};

CartLinkFixed.propTypes = {
  cartTotal: PropTypes.string.isRequired,
  cartLength: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  cartLength: state.cart.products.length,
  cartTotal: getFormattedCartTotal(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartLinkFixed);
