import React, { useState } from 'react';
import ShopWindow from '../../ShopWindow';

import * as S from './styles';

export default function CartAskToo() {
  const [askTooItems] = useState([]);

  /* useEffect(() => {
    const establishmentInfos = EstablishmentsJSON[establishment];

    const allAskTooItems = establishmentInfos.getAllAskTooItems();

    if (allAskTooItems && allAskTooItems.length > 0) {
      setAskTooItems(allAskTooItems);
    }
  }, [establishment]); */

  if (askTooItems && askTooItems.length > 0) {
    return (
      <S.CartAskToo>
        <span>Peça também</span>
        <ShopWindow simpleModel items={askTooItems} />
      </S.CartAskToo>
    );
  }

  return null;
}
