import React from 'react';
import { UIButton, UIFixedBottom } from '../../UI';
import { Flash } from '../../../assets/svgs/Flash';
import { NotFoundSVG } from '../../../assets/svgs/NotFound';
import * as S from './styles';

export const Page404Infos = () => (
  <S.Container>
    <S.InfosContainer>
      <NotFoundSVG />
      <S.Text>Não encontramos a página</S.Text>
      <S.TextSubtitle>O que pode ter ocorrido:</S.TextSubtitle>
      <S.List>
        <li>
          <Flash />
          <S.ListText>O link está errado</S.ListText>
        </li>
        <li>
          <Flash />
          <S.ListText>Houve um erro ao carregar a página</S.ListText>
        </li>
        <li>
          <Flash />
          <S.ListText>A Flash Menu está desabilitado no restaurante</S.ListText>
        </li>
      </S.List>
    </S.InfosContainer>
    <UIFixedBottom>
      <UIButton
        text="Tentar Novamente"
        onClick={() => window.location.reload()}
      />
    </UIFixedBottom>
  </S.Container>
);
