import React, { useEffect, useState } from 'react';
import Ink from 'react-ink';
import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { UIQuantityControl, UIPrice } from '../../../UI';
import * as S from './styles';

const ProductSelectionGroupOption = ({
  option,
  header,
  simpleStyle,
  selectedOptions,
  toggleSelections,
  setSelectedOptions,
  dontShowThePlusIcon,
  numOfSelectedOptions,
  chargeForTheMostExpensive,
}) => {
  const [quantity, setQuantity] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const {
    id, name, price, description, promotionalPrice,
  } = option;

  useEffect(() => {
    const isAlreadySelected = selectedOptions.some(({ id: optionId }) => optionId === id);
    if (isAlreadySelected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [id, selectedOptions]);

  const handleUpdateQtd = (idToUpdate, newQtd) => {
    const optionIndex = selectedOptions.findIndex(
      ({ id: selectedId }) => selectedId === idToUpdate,
    );

    const newSelectedOptions = selectedOptions.map((selectedOption, index) => {
      if (index === optionIndex) {
        return {
          ...selectedOption,
          quantity: newQtd,
        };
      }
      return selectedOption;
    });

    setSelectedOptions(newSelectedOptions);
  };

  const handleToggleOption = (selectedOption) => {
    if (toggleSelections && !header.repeatOptions) {
      setSelectedOptions([selectedOption]);

      return;
    }

    const optionIndex = selectedOptions.findIndex(
      ({ id: selectedId }) => selectedId === selectedOption.id,
    );

    if (
      !toggleSelections
      && numOfSelectedOptions >= header.max
      && optionIndex < 0
    ) {
      toast.warn('Limite de opções atingido');
      return;
    }

    if (optionIndex < 0) {
      setSelectedOptions([...selectedOptions, selectedOption]);
    } else {
      // Remove the option from selected options array
      const newSelectedOptions = selectedOptions.filter(
        (_, index) => index !== optionIndex,
      );
      setSelectedOptions(newSelectedOptions);
    }
  };

  return (
    <S.Option
      type="button"
      className="options__option"
      isSelected={isSelected.toString()}
      simpleStyle={simpleStyle.toString()}
      onClick={() => {
        if (!header.repeatOptions) {
          setQuantity(1);
          handleToggleOption({
            ...option, quantity: 1, groupName: header.name, chargeForTheMostExpensive,
          });
        }
      }}
    >
      <div className="option__infos">
        <strong className="infos__title">{name}</strong>
        <span className="infos__description">{description}</span>
        {price > 0 && (
          (dontShowThePlusIcon || chargeForTheMostExpensive) ? (
            <div className="infos__price">
              <UIPrice price={price} promotionalPrice={promotionalPrice} />
            </div>
          ) : (
            <div className="infos__price">
              <span className="price__plus">+</span>
              <UIPrice price={price} promotionalPrice={promotionalPrice} />
            </div>
          )
        )}
      </div>

      <div className="option__selection">
        {!header.repeatOptions && (
          toggleSelections ? (
            <div className="selection__radio">
              <div className="radio__background" />
            </div>
          ) : (
            <div className="selection__checkbox">
              <FaCheck />
            </div>
          )
        )}

        {header.repeatOptions && (
          <UIQuantityControl
            canBeZero
            simpleModel
            quantity={quantity}
            disablePlus={
              numOfSelectedOptions >= header.max
              || quantity >= header.maxQtdPerItem
            }
            setQuantity={(newQtd) => {
              // Quando o usuário deseja tirar a opção
              if (newQtd <= 1 && quantity <= 1) {
                handleToggleOption({
                  ...option, quantity: newQtd, groupName: header.name, chargeForTheMostExpensive,
                });
              } else {
                handleUpdateQtd(id, newQtd);
              }
              setQuantity(newQtd);
            }}
          />
        )}
      </div>
      <Ink />
    </S.Option>
  );
};

ProductSelectionGroupOption.propTypes = {
  simpleStyle: PropTypes.bool,
  dontShowThePlusIcon: PropTypes.bool,
  toggleSelections: PropTypes.bool.isRequired,
  numOfSelectedOptions: PropTypes.number.isRequired,
  chargeForTheMostExpensive: PropTypes.bool.isRequired,
  option: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    paused: PropTypes.bool,
    price: PropTypes.number,
    promotionalPrice: PropTypes.number,
  }).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  repeatOptions: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(PropTypes.node).isRequired,
  header: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    name: PropTypes.string,
    repeatOptions: PropTypes.bool,
    maxQtdPerItem: PropTypes.number,
    chargeForTheMostExpensive: PropTypes.bool,
  }).isRequired,
};

ProductSelectionGroupOption.defaultProps = {
  simpleStyle: false,
  dontShowThePlusIcon: false,
  repeatOptions: undefined,
};

export default ProductSelectionGroupOption;
