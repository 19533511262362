import React from 'react';
import PropTypes from 'prop-types';
import { StickyContainer } from 'react-sticky';
import { connect } from 'react-redux';
import Header from '../Header';
import CartLinkFixed from '../Cart/LinkFixed';
import { MenuProductsSection } from './ProductsSection';
import { UIContainer } from '../UI';

import './Menu.scss';

import MenuInfos from './Infos';
import MenuCoupon from './Coupon';

const Menu = ({ isOnlineCommand }) => (
  <>
    {!isOnlineCommand && (
      <Header />
    )}
    <StickyContainer id="teste" className="Menu">
      {!isOnlineCommand && (
        <>
          <UIContainer>
            <MenuInfos />
          </UIContainer>

          <MenuCoupon />
        </>
      )}

      <MenuProductsSection />

    </StickyContainer>
    <CartLinkFixed />
  </>
);

Menu.propTypes = {
  isOnlineCommand: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isOnlineCommand: state.establishment.isOnlineCommand,
});

export default connect(mapStateToProps, null)(Menu);
