import styled from 'styled-components';

export const CustomInputLabel = styled.div`
  width: 100%;
  // height: 22px;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  strong {
    font-size: 14px;
  }

  span {
    font-size: 12px;
  }
`;
