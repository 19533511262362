import styled from 'styled-components';
import { UIButtonToEdit, UILabel } from '../../../UI';

export const CartCoupon = styled(UIButtonToEdit)`
  margin-bottom: 15px;
`;

export const CartCouponValue = styled(UILabel)`
  margin-right: 10px;
`;

export const CartCouponDescription = styled.div`
  width: 100%;
  display: flex;
  margin-right: 5px;
  margin-bottom: 10px;
  justify-content: space-between;
`;
