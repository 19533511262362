import Fuse from 'fuse.js';
import { store } from '../../../store';
import { app } from '../../index';
import { getOnlyAlphaNums } from '../../../utils/getOnlyAlphaNums';

export const putSearchCustomer = async (search) => {
  try {
    const db = app.firestore();
    const parsedSearch = getOnlyAlphaNums(search);
    const { establishment: { id } } = store.getState();
    const data = await db
      .collection('customers')
      .where('establishmentId', '==', id)
      .get();

    const customers = data.docs.map((doc) => ({
      id: doc.id, ...doc.data(),
    }));

    const options = {
      threshold: 0.4,
      includeScore: true,
      ignoreLocation: true,
      isCaseSensitive: false,
      keys: ['name', 'phone'],
      minMatchCharLength: parsedSearch.length,
      getFn: (obj, path) => getOnlyAlphaNums(Fuse.config.getFn(obj, path)),
    };

    const fuse = new Fuse(customers, options);
    const filteredCustomers = fuse
      .search(getOnlyAlphaNums(parsedSearch))
      .map(({ item }) => item);

    return filteredCustomers;
  } catch (err) {
    console.log(err);
    return [];
  }
};
