export const STATUS = {
  BLOCKED: 'blocked',
  CANCELED: 'canceled',
  PENDING: 'pending',
  PENDING_PLAN_CHOICE: 'pending-plan-choice',
};

export const ASAAS_STATUS = {
  PENDING: 'PENDING',
  OVERDUE: 'OVERDUE',
};

export const PAYMENT_METHODS = {
  PIX: 'PIX',
  BOLETO: 'BOLETO',
  CREDIT_CARD: 'CREDIT_CARD',
};
