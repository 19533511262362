import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getOrderOriginByLabel, orderOrigins } from '../../../../utils/orderOrigin';
import { UIModal } from '../../../UI';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as UserActions from '../../../../store/modules/user/actions';
import * as AddressesActions from '../../../../store/modules/addresses/actions';
import * as S from './styles';

const OrderOriginChooseModal = ({
  showModal, setShowModal, addOrderOrigin, resetUser, resetAdresses,
}) => (
  <>
    <UIModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Origem do pedido"
    >
      {orderOrigins.map(({ name, label }) => (
        <S.Option
          key={label}
          noRightIcon
          icon={getOrderOriginByLabel(label).icon}
          firstElement={<strong>{name}</strong>}
          onClick={() => {
            addOrderOrigin(label);
            resetUser();
            resetAdresses();
            setShowModal(false);
          }}
        />
      ))}
    </UIModal>
  </>
);

OrderOriginChooseModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  resetUser: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  resetAdresses: PropTypes.func.isRequired,
  addOrderOrigin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  establishmentDeliveryOptions: state.establishment.deliveryOptions,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { ...CartActions, ...UserActions, ...AddressesActions },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderOriginChooseModal);
