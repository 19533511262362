import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import UIInput from '..';

const UIInputCurrency = ({
  label,
  value,
  setValue,
  required,
  className,
  infoMessage,
  errorMessage,
}) => (
  <>
    <UIInput
      type="text"
      label={label}
      customValidation
      required={required}
      inputMode="numeric"
      validatedValue={value}
      infoMessage={infoMessage}
      errorMessage={errorMessage}
      customInput={(
        <CurrencyInput
          prefix="R$ "
          value={value}
          decimalSeparator=","
          thousandSeparator="."
          className={`UIInput__input ${className}`}
          inputMode="numeric"
          onChangeEvent={(e) => setValue(e.target.value)}
        />
    )}
    />
  </>

);

UIInputCurrency.propTypes = {
  setValue: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  infoMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UIInputCurrency.defaultProps = {
  value: '',
  className: '',
  setValue: null,
  required: false,
  infoMessage: '',
  errorMessage: '',
};

export default UIInputCurrency;
