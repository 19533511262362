import React, { useCallback, useState } from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { RiArrowDropRightLine } from 'react-icons/ri';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { formatRealToNumber } from '../../../../utils/formatting';
import {
  UIBottomModal, UIButton, UIButtonToEdit, UIInputCurrency, UIModal,
} from '../../../UI';
import * as UserActions from '../../../../store/modules/user/actions';
import { getCartTotal, getFormattedCartTotal } from '../../../../store/selectors';
import { getPaymentIcon } from '../../../../utils/getPaymentIcon';

const PaymentButton = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  border: 1px solid var(--text-color);

  & > div {
    display: flex;
    padding: 10px;
    align-items: center;
    svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  & > svg {
    font-size: 24px;
  }
`;

const FlagChooseButton = styled(UIButtonToEdit)`
  border: 1px solid;
  min-height: 64px;
`;

const CartPaymentChooseModal = ({
  paymentMethods,
  showModal,
  setShowModal,
  addUserPaymentMethod,
  cartTotal,
  formattedCartTotal,
  isOnlineCommand,
}) => {
  const [showNeedChange, setShowNeedChange] = useState(false);
  const [showAddChangeModal, setShowAddChangeModal] = useState(false);
  const [showSelectFlagModal, setShowSelectFlagModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const isChangeValueValid = useCallback(() => {
    const { changeValue } = selectedPaymentMethod;

    const changeValueNum = changeValue ? formatRealToNumber(changeValue) : 0;

    if (changeValueNum >= 0 && changeValueNum < cartTotal) {
      return false;
    }
    return true;
  }, [selectedPaymentMethod, cartTotal]);

  return (
    <>
      {/* Modal com os botões de forma de pagamento */}
      <UIModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Forma de pagamento"
      >
        {paymentMethods.map((method) => {
          if (method.isActive === false && !isOnlineCommand) return null;
          return (
            <PaymentButton
              type="button"
              key={method.name}
              onClick={() => {
                if (method.id === 'dinheiro') {
                  setSelectedPaymentMethod({ id: method.id, name: method.name });
                  setShowAddChangeModal(true);
                  return;
                }

                if (method?.flags?.length > 0) {
                  setSelectedPaymentMethod({
                    id: method.id,
                    name: method.name,
                    flags: method.flags,
                  });
                  setShowSelectFlagModal(true);
                  return;
                }

                if (method.id === 'pix') {
                  addUserPaymentMethod({
                    id: method.id,
                    name: method.name,
                    pixKey: method.pixKey || '',
                    accountOwner: method.accountOwner || '',
                  });
                  setShowModal(false);
                  return;
                }

                addUserPaymentMethod({ id: method.id, name: method.name });
                setShowModal(false);
              }}
            >
              <div>
                {getPaymentIcon(method)}
                <span>{method.name}</span>
              </div>

              <RiArrowDropRightLine />
            </PaymentButton>
          );
        })}
      </UIModal>

      {/* Modal para seleção da bandeira do cartão */}
      <UIModal
        modalTitle="Escolha a bandeira"
        showModal={showSelectFlagModal}
        setShowModal={setShowSelectFlagModal}
      >
        {selectedPaymentMethod?.flags?.map((flag) => {
          const paymentMethod = {
            id: selectedPaymentMethod.id,
            name: selectedPaymentMethod.name,
            flag,
          };

          return (
            <FlagChooseButton
              key={flag.id}
              className="mb-20"
              icon={getPaymentIcon(paymentMethod)}
              onClick={() => {
                setShowSelectFlagModal(false);
                setShowModal(false);
                addUserPaymentMethod(paymentMethod);
              }}
              secondElement={<strong className="title">{flag.name}</strong>}
              firstElement={<span>{selectedPaymentMethod.name}</span>}
            />
          );
        })}
      </UIModal>

      {/* Bottom modal para a seleção da quantidade para troco */}
      <UIBottomModal
        showModal={showAddChangeModal}
        setShowModal={() => {
          setShowAddChangeModal(false);
          setShowNeedChange(false);
        }}
        secondTitle={showNeedChange ? formattedCartTotal : ''}
        modalTitle={showNeedChange ? 'Pagamento' : 'Precisa de troco ?'}
      >
        {!showNeedChange && (
          <>
            <div style={{ display: 'flex', gap: 10 }}>
              <UIButton
                outline
                text="Não"
                onClick={() => {
                  addUserPaymentMethod({
                    ...selectedPaymentMethod,
                    changeValue: formattedCartTotal,
                  });
                  setShowModal(false);
                  setShowAddChangeModal(false);
                }}
              />
              <UIButton
                outline
                text="Sim"
                onClick={() => setShowNeedChange(true)}
              />
            </div>
          </>
        )}
        {showNeedChange && (
          <>
            <UIButtonToEdit
              className="mb-20"
              icon={<BsCreditCard />}
              onClick={() => setShowAddChangeModal(false)}
              secondElement={<strong>{selectedPaymentMethod.name}</strong>}
              firstElement={<span className="title">Pagar na entrega</span>}
            />
            {selectedPaymentMethod.id === 'dinheiro' && (
              <UIInputCurrency
                label="Troco para quanto?"
                value={selectedPaymentMethod.changeValue}
                errorMessage={
                  !isChangeValueValid()
                    ? `Valor para troco menor que o valor do pedido de ${formattedCartTotal}`
                    : ''
                }
                setValue={(newChange) => {
                  const newPayment = {
                    ...selectedPaymentMethod,
                    changeValue: newChange,
                  };
                  setSelectedPaymentMethod(newPayment);
                }}
              />
            )}
            <UIButton
              text="Confirmar"
              disabled={!isChangeValueValid()}
              onClick={() => {
                addUserPaymentMethod(selectedPaymentMethod);
                setShowModal(false);
                setShowAddChangeModal(false);
              }}
            />
          </>
        )}
      </UIBottomModal>
    </>
  );
};

CartPaymentChooseModal.propTypes = {
  cartTotal: PropTypes.number.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  addUserPaymentMethod: PropTypes.func.isRequired,
  formattedCartTotal: PropTypes.string.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({
    flags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })),
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

const mapStateToProps = (state) => ({
  cartTotal: getCartTotal(state),
  formattedCartTotal: getFormattedCartTotal(state),
  paymentMethods: state.establishment.paymentMethods,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartPaymentChooseModal);
