import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

const RequiredLabelContext = createContext();

const RequiredLabelProvider = ({ children }) => {
  const history = useHistory();
  const [emphasizeLabels, setEmphasizeLabels] = useState(false);
  const [goToTheRequiredElement, setGoToTheRequiredElement] = useState(false);

  useEffect(() => {
    history.listen(() => {
      setEmphasizeLabels(false);
    });
  }, [history]);

  return (
    <RequiredLabelContext.Provider
      value={{
        emphasizeLabels,
        setEmphasizeLabels,
        goToTheRequiredElement,
        setGoToTheRequiredElement,
      }}
    >
      {children}
    </RequiredLabelContext.Provider>
  );
};

RequiredLabelProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export { RequiredLabelContext, RequiredLabelProvider };
