import { getIfAddressAreEquals } from '../../../utils/compareAddresses';
import { formatRealToNumber } from '../../../utils/formatting';
import { app } from '../../index';
import { getCustomerById } from './get';

export const updateCustomer = async (customerId, dataToUpdate) => {
  const db = app.firestore();
  await db
    .collection('customers')
    .doc(customerId)
    .set(dataToUpdate, { merge: true });

  return true;
};

export const updateCustomerOrders = async (customerId, order) => {
  const customer = await getCustomerById(customerId);

  if (customer?.id) {
    const ISODateNow = new Date().toISOString();

    const newCustomerInfos = {
      ...customer,
      lastOrderDate: ISODateNow,
      orders: [...customer.orders, order.id],
      totalSpent: customer.totalSpent + formatRealToNumber(order.cartTotal),
    };

    if (!customer.firstOrderDate) {
      newCustomerInfos.firstOrderDate = ISODateNow;
    }

    if (order?.deliveryAddress?.street) {
      if (customer?.addresses?.length) {
        const alreadyHaveThisAddressRegistered = customer.addresses.reduce((acc, address) => {
          if (getIfAddressAreEquals(address, order.deliveryAddress)) {
            acc = true;
          }

          return acc;
        }, false);

        if (!alreadyHaveThisAddressRegistered) {
          newCustomerInfos.addresses.push(order?.deliveryAddress);
        }
      } else {
        newCustomerInfos.addresses.push(order?.deliveryAddress);
      }
    }

    const db = app.firestore();
    await db
      .collection('customers')
      .doc(customerId)
      .set(newCustomerInfos, { merge: true });

    return true;
  }

  return false;
};

export const updateCustomerAddress = async (customerId, addressId, newAddress) => {
  const customer = await getCustomerById(customerId);

  if (customer?.id) {
    const customerAddresses = [...customer.addresses];
    const indexToEdit = customerAddresses.findIndex((address) => address.id === addressId);

    customerAddresses[indexToEdit] = newAddress;

    const db = app.firestore();
    await db
      .collection('customers')
      .doc(customerId)
      .set({ addresses: customerAddresses }, { merge: true });

    return customerAddresses;
  }

  return false;
};
