import dayjs from 'dayjs';
import { getDaysOptions } from './getDayjsOptions';
import { getHoursOptions } from './getHoursOptions';

export const getScheduleOrdersDates = (scheduleOrders) => {
  if (!scheduleOrders || !scheduleOrders.active) {
    return {
      scheduleDaysOptions: [],
      hasValidDaysToScheduleOrders: false,
    };
  }

  const { minAntecedence, maxAntecedence, active } = scheduleOrders;

  const minDate = minAntecedence.format === 'days'
    ? dayjs().add(minAntecedence.value, minAntecedence.format).startOf('D')
    : dayjs().add(minAntecedence.value, minAntecedence.format);
  const maxDate = minAntecedence.format === 'days'
    ? dayjs().add(maxAntecedence.value, maxAntecedence.format).endOf('D')
    : dayjs().add(maxAntecedence.value, maxAntecedence.format);

  const daysOptions = getDaysOptions(minDate, maxDate);
  const daysWithHoursOptions = daysOptions.map((day) => ({
    ...day,
    hoursOptions: getHoursOptions(day.value, { minDate, maxDate }),
  }));

  const hasValidDaysToScheduleOrders = daysWithHoursOptions.reduce((acc, day) => {
    if (day.hoursOptions.length > 0) {
      acc = true;
    }

    return acc;
  }, false);

  return {
    scheduleDaysOptions: daysWithHoursOptions,
    hasValidDaysToScheduleOrders: !active ? false : hasValidDaysToScheduleOrders,
  };
};
