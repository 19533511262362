import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import CartAddress from '../components/Cart/Address';

const ShowAdressSelectionContext = createContext();

const ShowAdressSelectionProvider = ({ children }) => {
  const [showAddressSelectionModal, setShowAddressSelectionModal] = useState(false);

  return (
    <ShowAdressSelectionContext.Provider
      value={{
        showAddressSelectionModal,
        setShowAddressSelectionModal,
      }}
    >
      {children}
      {showAddressSelectionModal && (
        <CartAddress
          showAddressSelectionModal={showAddressSelectionModal}
          setShowAddressSelectionModal={setShowAddressSelectionModal}
        />
      )}
    </ShowAdressSelectionContext.Provider>
  );
};

ShowAdressSelectionProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export { ShowAdressSelectionContext, ShowAdressSelectionProvider };
