import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OrderOriginChooseModal from './ChooseModal';
import { getOrderOriginByLabel } from '../../../utils/orderOrigin';

import {
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../UI';

const CartOrderOrigin = ({
  orderOrigin,
  tableSelectedByDefault,
}) => {
  const [showModal, setShowModal] = useState(false);
  const selectedOrderOrigin = getOrderOriginByLabel(orderOrigin);

  return (
    <>
      <UICustomInputLabel
        isRequired
        isValited={orderOrigin}
        labelTitle="Origem do pedido"
      />
      {orderOrigin ? (
        <UIButtonToEdit
          className="mb-20"
          icon={selectedOrderOrigin.icon}
          disabled={tableSelectedByDefault}
          noRightIcon={tableSelectedByDefault}
          onClick={tableSelectedByDefault ? null : () => setShowModal(true)}
          firstElement={(
            <strong>
              {selectedOrderOrigin.name}
            </strong>
          )}
        />
      ) : (
        <UIButton
          outline
          className="mb-20"
          text="Origem do pedido"
          onClick={() => setShowModal(true)}
        />
      )}
      <OrderOriginChooseModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

CartOrderOrigin.propTypes = {
  orderOrigin: PropTypes.string.isRequired,
  tableSelectedByDefault: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  orderOrigin: state.cart.orderOrigin,
  tableSelectedByDefault: state.user.tableSelectedByDefault,
});

export default connect(mapStateToProps, null)(CartOrderOrigin);
