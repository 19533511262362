import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GiTable } from 'react-icons/gi';

import * as S from './styles';
import AddressStateBlock from '../../AddressStateBlock';
import MinimumOrderAndIsOpen from '../MinimumOrderAndIsOpen';
import { UIButtonToEdit } from '../../UI';

const MenuInfos = ({
  hideAddress, hasLocalPickup, location, orderOrigin, userTable,
}) => (
  <S.MenuInfos>
    {location?.city && (
      hideAddress && !hasLocalPickup ? (
        <S.MenuInfosLine>
          <span>
            {`${location.neighborhood}, ${location.city}/${location.uf}`}
          </span>
        </S.MenuInfosLine>
      ) : (
        <S.MenuInfosLine>
          <span>
            {`${location.street}, ${location.number} - ${location.neighborhood}, ${location.city}/${location.uf}`}
          </span>
        </S.MenuInfosLine>
      )
    )}

    <MinimumOrderAndIsOpen />

    {orderOrigin === 'table' ? (
      <>
        {userTable && (
          <UIButtonToEdit
            noRightIcon
            className="mb-25"
            icon={<GiTable />}
            firstElement={(
              <strong>
                Mesa
                {' '}
                {userTable}
              </strong>
            )}
          />
        )}
      </>
    ) : (
      <AddressStateBlock marginBottom={25} />
    )}
  </S.MenuInfos>
);

MenuInfos.propTypes = {
  hideAddress: PropTypes.string.isRequired,
  userTable: PropTypes.number,
  hasLocalPickup: PropTypes.bool.isRequired,
  orderOrigin: PropTypes.string.isRequired,
  location: (PropTypes.shape({
    addressNotFound: PropTypes.bool,
    addressTooFar: PropTypes.bool,
    city: PropTypes.string,
    complement: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    referencePoint: PropTypes.string,
    street: PropTypes.string,
    uf: PropTypes.string,
  })).isRequired,
};

MenuInfos.defaultProps = {
  userTable: null,
};

const mapStateToProps = (state) => ({
  userTable: state.user.table,
  orderOrigin: state.cart.orderOrigin,
  hideAddress: state.establishment.name,
  location: state.establishment.location,
  hasLocalPickup: state.establishment.deliveryOptions.some(
    ({ label }) => label === 'local-pickup',
  ),
});

export default connect(mapStateToProps, null)(MenuInfos);
