import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  $tablet-max: 960px;

  body {
    margin: 0;
    background: white;
    color: var(--text-color);

    &.remove-scroll, .remove-scroll {
      overflow: hidden;
    }

    #root {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
    }

    &,
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: "Poppins", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &::-webkit-scrollbar {
        width: 0.5em;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--primary-color);
      }
    }
    input {
      border: none;
      outline: none;
    }
    button {
      border: none;
      outline: none;
      cursor: pointer;
      background: none;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    h2, h3, h4 {
      font-weight: 500;
    }

    .mb-5 {
      margin-bottom: 5px;
    }

    .mb-10 {
      margin-bottom: 10px;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

    .mb-25 {
      margin-bottom: 25px;
    }

    input[type="radio"] {
      width: 25px;
      height: 25px;
      display: flex;
      appearance: none;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--default-gray);
      :checked {
        border: none;
        background: var(--primary-color);
        ::after {
          width: 13px;
          height: 13px;
          border-radius: 13px;
          position: relative;
          background: #FFF;
          content: '';
          display: inline-block;
          visibility: visible;
        }
      }
    }
  }

  .swal2-title {
    font-size: 24px;
    line-height: 32px;
  }

  .swal2-styled.swal2-confirm {
    background: var(--sucess-color);
  }

  .swal2-styled.swal2-cancel {
    background: var(--default-dark-gray);
  }

  :root {
    --grid-column-size: 4vw;
    --grid-margin-space: 20px;
    /* --grid-margin-space: calc(var(--grid-column-size) * 2); */

    --default-border-radius: 4px;

    --default-box-shadow: 0 0 0.3125rem rgba(0,0,0,.25);

    --text-color: #414141;
    --text-secondary-color: #939598;

    --disabled-color: #D1D3D4;

    --default-gray: #dcdcdc;
    --default-dark-gray: #717171; 

    --primary-color: #a30a38;
    --promotional-color: #50a773;
    --primary-color-opacity: #a30a3860;

    --alert-color: #DA2027;
    --sucess-color: #50BD86;
  }
`;
