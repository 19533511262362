import createWhatsMsg from '../createWhatsMessage';
import { getWhatsAppLink } from './getLink';
import { store } from '../../store';

export const whatsappSendMessage = (order) => {
  const state = store.getState();
  const establishmentPhone = state.establishment.phoneForOrder;
  const encodedMsg = createWhatsMsg(order);

  const whatsAppLink = getWhatsAppLink(establishmentPhone, encodedMsg);

  window.location.replace(whatsAppLink);
};
