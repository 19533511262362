import firebase from 'firebase/app';
import { app } from '../../../index';
import { store } from '../../../../store';

export const addOrder = async (order) => {
  try {
    const db = app.firestore();
    const { establishment: { id } } = store.getState();

    const orderData = {
      ...order,
      establishmentId: id,
      serverTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const newOrder = await db.collection('orders').add(orderData);

    if (newOrder.id) {
      return {
        ...orderData,
        id: newOrder.id,
      };
    }

    return false;
  } catch (err) {
    console.log('err');
    console.log(err);
    return false;
  }
};
