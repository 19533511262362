import styled, { css } from 'styled-components';

export const HeaderSimpleWithReturn = styled.header`
  display: flex;
  margin: 0 -20px;
  min-height: 50px;
  padding: 10px 20px;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--default-gray);

  .close {
    display: flex;
    font-size: 24px;
    align-items: flex-end;
    background: transparent;
    color: var(--text-color);
  }

  .title {
    margin: 0 auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    padding-left: 5px;
    text-align: center;
    padding-right: 24px;

    ${({ rightAction }) => rightAction && css`
      margin: 0 0 0 10px; 
    `}
  }

  .right-action {
    font-size: 16px;
    margin-left: auto;
  }

  ${({ noMarginBottom }) => noMarginBottom && css`
    margin-bottom: 0;
  `}

  ${({ $noHorizontalPadding }) => $noHorizontalPadding && css`
    margin: 0 0;
  `}
`;
