import React from 'react';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import * as S from './styles';

export const UIRadioList = ({ options, selected, setSelected }) => (
  <ul>
    {options.map(({ value, label }) => (
      <S.Address key={value}>
        <label className="container" htmlFor={value}>
          <input
            id={value}
            type="radio"
            name={value}
            checked={value === selected}
            onChange={() => setSelected(value)}
          />
          <div className="infos">
            <span>{label}</span>
          </div>
          <Ink />
        </label>
      </S.Address>
    ))}
  </ul>
);

UIRadioList.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
