import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import CartItem from '../Item';

import './CartItems.scss';
import { UIBottomModal } from '../../UI';

const CartItems = ({ cartItems, removeFromCart, informationalUse }) => {
  const { establishment } = useParams();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (cartItems && cartItems.length <= 0 && !informationalUse) {
      history.push(`/${establishment}/produtos`);
    }
  }, [history, cartItems, establishment, informationalUse]);

  const {
    amount, name, id,
  } = selectedItem;

  return (
    <div className="CartItems">
      {cartItems && cartItems.length > 0 ? (
        cartItems.map((item) => (
          <CartItem
            item={item}
            key={item.id}
            setSelectedItem={setSelectedItem}
            informationalUse={informationalUse}
          />
        ))
      ) : (
        <span className="CartItems__empty">Seu carrinho está vazio</span>
      )}

      {!informationalUse && (
        <UIBottomModal
          className="CartItems__actions"
          setShowModal={() => {
            setSelectedItem({});
          }}
          showModal={Object.keys(selectedItem).length >= 1}
          modalTitle={`${amount} x ${name}`}
        >
          <button
            type="button"
            className="actions__button actions__button--delete"
            onClick={() => {
              removeFromCart(id);
              setSelectedItem({});
            }}
          >
            Excluir
          </button>
        </UIBottomModal>
      )}
    </div>
  );
};

CartItems.propTypes = {
  removeFromCart: PropTypes.func.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CartItems;
