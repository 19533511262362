import { app } from '../../../index';
import { getIsStoreOpen } from '../../../../utils/validateIfStoreIsOpen';
import { getActiveHourData } from '../../../../utils/hour/getActiveHourData';
import { getScheduleOrdersDates } from '../../../../utils/getScheduleOrdersDates';

export const getEstablishmentBySlug = async (slug, isOnlineCommand) => {
  try {
    const db = app.firestore();
    const data = await db
      .collection('establishments')
      .where('slug', '==', slug)
      .get();

    if (data.empty) return false;

    const establishmentInfos = data.docs[0].data();
    const establishmentOpenHour = getActiveHourData(
      establishmentInfos.businessHours, establishmentInfos.timezone,
    );
    const {
      scheduleDaysOptions,
      hasValidDaysToScheduleOrders,
    } = getScheduleOrdersDates(establishmentInfos.scheduleOrders);

    return {
      ...establishmentInfos,
      id: data.docs[0].id,
      scheduleOrders: {
        ...establishmentInfos.scheduleOrders,
        scheduleDaysOptions,
        hasValidDaysToScheduleOrders,
      },
      activeHourData: establishmentOpenHour,
      isOpen: getIsStoreOpen(establishmentInfos, isOnlineCommand),
    };
  } catch (err) {
    console.log(err);
    return false;
  }
};
