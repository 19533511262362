import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const UIFlagImage = ({ src, alt }) => (
  <S.FlagImage alt={alt} src={src} />
);

UIFlagImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

UIFlagImage.defaultProps = {
  src: '',
  alt: '',
};

export default UIFlagImage;
