import React from 'react';
import PropTypes from 'prop-types';

import './CartItem.scss';
import { formatNumberToReal } from '../../../utils/formatting';
import { getProductPriceByInfos } from '../../../utils/getProductValues';
import { returnFormattedWeightDescription } from '../../../utils/returnFormattedWeightDescription';

const CartItem = ({ item, setSelectedItem, informationalUse }) => {
  const {
    name, image, amount, observation, selectedVariations, selectedAdditionals, weight, unit,
  } = item;

  return (
    <div className="CartItem">
      <button
        type="button"
        className="CartItem__description"
        onClick={() => {
          if (!informationalUse) {
            setSelectedItem(item);
          }
        }}
      >
        <div className="description__infos">
          <span className="infos__name">{item.name}</span>
          <div className="infos__extra-infos">
            {weight && (
              <span className="extra-infos__info">
                {`- ${returnFormattedWeightDescription(weight)}`}
              </span>
            )}
            {selectedVariations && Object.keys(selectedVariations).length > 0 && (
              Object.values(selectedVariations).map(({ name: variationName }) => (
                <span key={variationName} className="extra-infos__info">{`- ${variationName}`}</span>
              ))
            )}
            {selectedAdditionals && selectedAdditionals.length > 0 && (
              selectedAdditionals.map(({ name: additionalName, quantity }) => (
                <span key={additionalName} className="extra-infos__info">{`- ${quantity}x ${additionalName}`}</span>
              ))
            )}
            {observation && (
              <span className="extra-infos__info">
                {`- ${observation}`}
              </span>
            )}
          </div>
          <span className="infos__price">{formatNumberToReal(getProductPriceByInfos(item) * amount)}</span>
        </div>
        <div className="description__image-container">
          {unit !== 'KG' && (
            <span className="image-container__amount">{amount}</span>
          )}
          {unit === 'KG' && (
            <span className="image-container__weight">{returnFormattedWeightDescription(weight)}</span>
          )}
          {image && (
            <img
              alt={name}
              src={image}
              className="image-container__image"
            />
          )}
        </div>
      </button>
    </div>
  );
};

CartItem.propTypes = {
  setSelectedItem: PropTypes.func.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    unit: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    weight: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
    selectedAdditionals: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default CartItem;
