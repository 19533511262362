import React from 'react';
import PropTypes from 'prop-types';
import { formatNumberToReal } from '../../../utils/formatting';
import * as S from './styles';

export const UIPrice = ({
  price, promotionalPrice, free, smallType,
}) => (
  <S.UIPrice
    smallType={smallType}
    hasPromotionalPrice={(promotionalPrice && Number(promotionalPrice) > 0) || free}
  >
    {price && Number(price) > 0 && (
      <span className="price">{formatNumberToReal(price)}</span>
    )}
    {free && (
      <span className="promotional-price">
        GRÁTIS
      </span>
    )}
    {promotionalPrice && Number(promotionalPrice) > 0 && (
      <span className="promotional-price">
        {formatNumberToReal(promotionalPrice)}
      </span>
    )}
  </S.UIPrice>
);

UIPrice.propTypes = {
  free: PropTypes.bool,
  smallType: PropTypes.bool,
  price: PropTypes.number.isRequired,
  promotionalPrice: PropTypes.number,
};

UIPrice.defaultProps = {
  free: false,
  smallType: false,
  promotionalPrice: null,
};
