import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle } from 'react-icons/fa';
import { scroller } from 'react-scroll';
import { RequiredLabelContext } from '../../../context/RequiredLabelContext';

import * as S from './styles';

const UIRequiredBlock = ({ isValited, darkStyle, customLabelError }) => {
  const myRef = useRef(null);
  const {
    goToTheRequiredElement,
    setGoToTheRequiredElement,
  } = useContext(RequiredLabelContext);

  useEffect(() => {
    if (goToTheRequiredElement) {
      scroller.scrollTo('test1', {
        delay: 0,
        offset: -20,
        duration: 800,
        smooth: 'easeInOutQuart',
      });
      const allNotValidatedBlocks = document.querySelectorAll('.UIRequiredBlock--not-validated');

      if (allNotValidatedBlocks[0]) {
        allNotValidatedBlocks[0].classList.add('UIRequiredBlock--emphasize');
      }

      setGoToTheRequiredElement(false);
    }
  }, [goToTheRequiredElement, setGoToTheRequiredElement]);

  return (
    <S.UIRequiredBlock
      id="test1"
      ref={myRef}
      $darkStyle={darkStyle}
      className={!isValited ? 'UIRequiredBlock--not-validated' : ''}
    >
      {isValited ? (
        <FaCheckCircle />
      ) : (
        <span>{customLabelError || 'Obrigatório'}</span>
      )}
    </S.UIRequiredBlock>
  );
};

UIRequiredBlock.propTypes = {
  darkStyle: PropTypes.bool,
  customLabelError: PropTypes.string,
  isValited: PropTypes.bool.isRequired,
};

UIRequiredBlock.defaultProps = {
  darkStyle: false,
  customLabelError: '',
};

export default UIRequiredBlock;
