import 'dayjs/locale/pt-br';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { BsPencil } from 'react-icons/bs';
import { BiMap } from 'react-icons/bi';
import {
  UIModal,
  UIButton,
  UIFixedBottom,
  UILetterAvatar,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../../UI';
import * as UserActions from '../../../../../store/modules/user/actions';
import * as CartActions from '../../../../../store/modules/cart/actions';
import * as AddressesActions from '../../../../../store/modules/addresses/actions';
import { PropCustomerAddress } from '../../../../../types/customer';
import * as S from './styles';
import { getSeparetedStrings } from '../../../../../utils/getSeparetedStrings';
import { getNeighborhoodCityAndUF } from '../../../../../utils/addressInfos';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('pt-br');

const ClientDetailsModal = ({
  addUserId,
  showModal,
  resetUser,
  addUserName,
  setShowModal,
  addUserPhone,
  setShowFormModal,
  selectedCustomer,
  resetCartDeliveryState,
  closeChooseClientModal,
  addAdressesOnAdressGroup,
}) => {
  const handleSelectUser = async () => {
    resetUser();
    resetCartDeliveryState();

    addUserId(selectedCustomer.id);
    addUserName(selectedCustomer.name);
    addUserPhone(selectedCustomer.phone);
    if (selectedCustomer.addresses.length >= 1) {
      addAdressesOnAdressGroup(selectedCustomer.addresses);
    }

    setShowModal(false);
    closeChooseClientModal();
  };

  const handleEditUser = () => {
    setShowFormModal(true);
  };

  return (
    <UIModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Perfil do cliente"
      rightAction={{
        label: 'Editar',
        action: handleEditUser,
      }}
    >
      <S.Avatar>
        <UILetterAvatar name={selectedCustomer.name} />
        <div className="infos">
          <strong>{selectedCustomer.name}</strong>
          <span>{selectedCustomer.phone}</span>
        </div>
      </S.Avatar>
      <S.InputContainer>
        <small>
          {selectedCustomer.orders.length}
          {' '}
          Pedidos
        </small>
        {selectedCustomer.firstOrderDate && (
          <small>
            Cliente a
            {' '}
            {dayjs()
              .locale('pt-br')
              .from(dayjs(selectedCustomer.firstOrderDate), true)}
          </small>
        )}
        {selectedCustomer.lastOrderDate && (
          <div>
            <strong>Último pedido: </strong>
            <span>
              {dayjs(selectedCustomer.lastOrderDate).format('DD/MM/YYYY')}
            </span>
          </div>
        )}
      </S.InputContainer>

      <UIButton
        outline
        className="mb-20"
        text="Editar cliente"
        onClick={handleEditUser}
        icon={(
          <BsPencil
            size={16}
            style={{ marginRight: 5 }}
            color="var(--primary-color)"
          />
        )}
      />

      <UICustomInputLabel
        labelTitle="Endereços"
      />

      {selectedCustomer.addresses.length >= 1 ? (
        <div>
          {selectedCustomer.addresses.map((userAddress) => {
            const neighborhoodCityAndUF = getNeighborhoodCityAndUF(userAddress);

            return (
              <UIButtonToEdit
                noRightIcon
                icon={<BiMap />}
                className="mb-10"
                firstElement={
                  <strong>{`${userAddress.street}, ${userAddress.number}`}</strong>
              }
                secondElement={
                neighborhoodCityAndUF ? (
                  <span>{neighborhoodCityAndUF}</span>
                ) : (
                  ''
                )
              }
                thirdElement={(
                  <span>
                    {getSeparetedStrings(
                      [userAddress.complement, userAddress.referencePoint],
                      '-',
                    )}
                  </span>
              )}
              />
            );
          })}
        </div>
      ) : (
        <small>Este cliente ainda não possui endereços cadastrados</small>
      )}

      <UIFixedBottom>
        <UIButton
          type="button"
          text="Selecionar cliente"
          onClick={handleSelectUser}
        />
      </UIFixedBottom>
    </UIModal>
  );
};

ClientDetailsModal.propTypes = {
  addUserId: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  resetUser: PropTypes.func.isRequired,
  addUserName: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addUserPhone: PropTypes.func.isRequired,
  setShowFormModal: PropTypes.func.isRequired,
  resetCartDeliveryState: PropTypes.func.isRequired,
  closeChooseClientModal: PropTypes.func.isRequired,
  addAdressesOnAdressGroup: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    address: PropCustomerAddress,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    totalSpent: PropTypes.number.isRequired,
    lastOrderDate: PropTypes.string.isRequired,
    firstOrderDate: PropTypes.string.isRequired,
    establishmentId: PropTypes.string.isRequired,
    addresses: PropTypes.arrayOf(PropCustomerAddress),
    orders: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions, ...CartActions, ...AddressesActions }, dispatch)
);

export default connect(null, mapDispatchToProps)(ClientDetailsModal);
