import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export const UILetterAvatar = ({ name }) => {
  const firstLetter = name.charAt(0);

  return (
    <S.LetterAvatar>
      <span>
        {firstLetter}
      </span>
    </S.LetterAvatar>
  );
};

UILetterAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};
