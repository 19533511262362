import React from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';

import './UIBottomModal.scss';

const UIBottomModal = ({
  showModal,
  setShowModal,
  children,
  modalTitle,
  className,
  shadowDontCloseModal,
  secondTitle,
}) => {
  if (!showModal) return null;
  return ReactDom.createPortal(
    <div
      tabIndex="0"
      role="button"
      className={`UIBottomModal ${className}`}
      onKeyDown={(e) => {
        if (e.key === 'Escape') setShowModal(false);
      }}
      onClick={(e) => {
        if (e.target.matches('.UIBottomModal') && !shadowDontCloseModal) { setShowModal(false); }
      }}
    >
      <div className="UIBottomModal__container">
        <span
          className={
            secondTitle
              ? 'UIBottomModal__title UIBottomModal__title--double'
              : 'UIBottomModal__title'
          }
        >
          <span>{modalTitle}</span>
          {secondTitle && <span>{secondTitle}</span>}
        </span>
        {children}
      </div>
    </div>,
    document.getElementById('bottom-modal-portal'),
  );
};

UIBottomModal.propTypes = {
  className: PropTypes.string,
  secondTitle: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  shadowDontCloseModal: PropTypes.bool,
  modalTitle: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

UIBottomModal.defaultProps = {
  className: '',
  secondTitle: '',
  shadowDontCloseModal: false,
};

export default UIBottomModal;
