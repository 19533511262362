import React, { useContext } from 'react';
import { BiMap } from 'react-icons/bi';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ShowAdressSelectionContext } from '../../context/ShowAddressSelection';
import { getSeparetedStrings } from '../../utils/getSeparetedStrings';
import { UIButton, UIButtonToEdit, UILabel } from '../UI';
import * as S from './styles';
import { getNeighborhoodCityAndUF } from '../../utils/addressInfos';

const AddressStateBlock = ({
  marginTop,
  userAddress,
  marginBottom,
  cartDelivery,
  isOnlineCommand,
  informationalUse,
  isItBeingUsedOnCart,
  establishmentDeliveryOptions,
}) => {
  const { setShowAddressSelectionModal } = useContext(ShowAdressSelectionContext);
  const establishmentHasDeliveryMethod = establishmentDeliveryOptions.some(({ label }) => label === 'delivery');
  const { addressNotFound, addressTooFar, street } = userAddress;
  const neighborhoodCityAndUF = getNeighborhoodCityAndUF(userAddress);

  if (addressTooFar) {
    return (
      <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
        <UIButtonToEdit
          redBorder
          onClick={() => setShowAddressSelectionModal(true)}
          icon={<span role="img" aria-label="sad-emoji">🥺</span>}
          firstElement={<span>Desculpe, você está muito longe !</span>}
          secondElement={(<strong>Endereço fora da nossa área de entrega</strong>)}
        />
      </S.AddressStateBlock>
    );
  }

  if (addressNotFound) {
    return (
      <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
        <UIButtonToEdit
          redBorder
          onClick={() => setShowAddressSelectionModal(true)}
          firstElement={<span>Endereço não localizado</span>}
          icon={<span role="img" aria-label="sad-emoji">🥺</span>}
          secondElement={(<strong>{isOnlineCommand ? 'Entre em contato com o cliente' : 'Entre em contato com a loja'}</strong>)}
        />
      </S.AddressStateBlock>
    );
  }

  if (street) {
    if (isItBeingUsedOnCart) {
      return (
        <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
          <UIButtonToEdit
            icon={<BiMap />}
            disabled={informationalUse}
            noRightIcon={informationalUse}
            onClick={() => setShowAddressSelectionModal(true)}
            firstElement={<strong>{`${userAddress.street}, ${userAddress.number}`}</strong>}
            secondElement={neighborhoodCityAndUF ? <span>{neighborhoodCityAndUF}</span> : ''}
            thirdElement={<span>{getSeparetedStrings([userAddress.complement, userAddress.referencePoint], '-')}</span>}
          />
        </S.AddressStateBlock>
      );
    }

    return (
      <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
        <UIButtonToEdit
          icon={<BiMap />}
          onClick={() => setShowAddressSelectionModal(true)}
          secondElement={<span>{`${userAddress.street}, ${userAddress.number}`}</span>}
          firstElement={neighborhoodCityAndUF ? <span>{neighborhoodCityAndUF}</span> : ''}
          thirdElement={(
            <strong>
              Entrega
              {cartDelivery.estimatedTime && (
                <>
                  {' '}
                  aprox.
                  <UILabel text={cartDelivery.estimatedTime} />
                </>
              )}
              <UILabel text={cartDelivery.rateToBeAgreed ? 'Taxa a combinar' : cartDelivery.formattedRate} />
            </strong>
          )}
        />
      </S.AddressStateBlock>
    );
  }

  if (isItBeingUsedOnCart) {
    return (
      <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
        <UIButton
          outline
          onClick={() => setShowAddressSelectionModal(true)}
          text={isOnlineCommand ? 'Selecione o endereço do cliente' : 'Adicione seu endereço'}
        />
      </S.AddressStateBlock>
    );
  }

  if (establishmentHasDeliveryMethod) {
    return (
      <S.AddressStateBlock marginTop={marginTop} marginBottom={marginBottom}>
        <UIButtonToEdit
          icon={<BiMap />}
          onClick={() => setShowAddressSelectionModal(true)}
          firstElement={<span>Selecione um endereço</span>}
          secondElement={<strong>para ver tempo e taxa de entrega</strong>}
        />
      </S.AddressStateBlock>
    );
  }

  return null;
};

AddressStateBlock.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  isItBeingUsedOnCart: PropTypes.bool,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  userAddress: (PropTypes.shape({
    addressNotFound: PropTypes.bool,
    addressTooFar: PropTypes.bool,
    city: PropTypes.string,
    complement: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    referencePoint: PropTypes.string,
    street: PropTypes.string,
    uf: PropTypes.string,
  })).isRequired,
  cartDelivery: PropTypes.shape({
    clientDistance: PropTypes.string,
    estimatedTime: PropTypes.string,
    formattedRate: PropTypes.string,
    rate: null,
    rateToBeAgreed: PropTypes.bool,
  }).isRequired,
  establishmentDeliveryOptions: PropTypes.arrayOf(PropTypes.shape({
    minTime: PropTypes.number,
    maxTime: PropTypes.number,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

AddressStateBlock.defaultProps = {
  marginTop: 0,
  marginBottom: 0,
  isItBeingUsedOnCart: false,
};

const mapStateToProps = (state) => ({
  userAddress: state.user.address,
  cartDelivery: state.cart.delivery,
  isOnlineCommand: state.establishment.isOnlineCommand,
  establishmentDeliveryOptions: state.establishment.deliveryOptions,
});

export default connect(mapStateToProps, null)(AddressStateBlock);
