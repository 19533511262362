/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router';
import { Sticky } from 'react-sticky';
import { SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as S from './styles';
import { Lupe } from '../../../assets/svgs/lupe';

const MenuNewChangeItemsType = ({
  isRelative,
  customOffset,
  optionsContainerId,
  hasPromotionalItems,
  categoriesWithProducts,
}) => {
  const stickyRef = useRef(null);
  const { establishment } = useParams();
  const [swiper, setSwiper] = useState(null);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiper) {
        swiper.slideTo(selectedSlide);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [swiper, selectedSlide]);

  return (
    <div ref={stickyRef}>
      <Sticky relative={isRelative} topOffset={105}>
        {({ style, isSticky }) => (
          <S.NewMenuChangeItemType style={{ ...style }}>
            <S.NewMenuChangeItemTypeTop $isSticky={isSticky}>
              <S.DisplaySearch>
                <h2>Produtos</h2>
                <Link to={`/${establishment}/buscar`}>
                  <Lupe color="#FFF" />
                </Link>
              </S.DisplaySearch>
            </S.NewMenuChangeItemTypeTop>
            <S.NewMenuChangeItemTypeItems $isSticky={isSticky}>
              <S.NewMenuChangeItemTypeSlide
                $isSticky={isSticky}
                slidesPerView="auto"
                slideToClickedSlide
                slidesOffsetAfter={0}
                onSwiper={(s) => setSwiper(s)}
              >
                {hasPromotionalItems && (
                  <SwiperSlide>
                    <S.NewMenuChangeItemTypeLink
                      spy
                      smooth
                      duration={500}
                      $isSticky={isSticky}
                      activeClass="active"
                      offset={customOffset}
                      id="custom-promotions"
                      to="custom-promotions"
                      containerId={optionsContainerId || null}
                      onSetActive={() => setSelectedSlide(0)}
                    >
                      Promoções
                    </S.NewMenuChangeItemTypeLink>
                  </SwiperSlide>
                )}
                {categoriesWithProducts && categoriesWithProducts.length > 0 && (
                  categoriesWithProducts.map(({ id, name, products }, index) => (
                    products && products.length > 0 ? (
                      <SwiperSlide key={id}>
                        <S.NewMenuChangeItemTypeLink
                          spy
                          smooth
                          id={name}
                          duration={500}
                          to={id.toString()}
                          $isSticky={isSticky}
                          activeClass="active"
                          offset={customOffset}
                          containerId={optionsContainerId || null}
                          onSetActive={() => { setSelectedSlide(index + 1); }}
                        >
                          {name}
                        </S.NewMenuChangeItemTypeLink>
                      </SwiperSlide>
                    ) : null
                  ))
                )}
              </S.NewMenuChangeItemTypeSlide>
            </S.NewMenuChangeItemTypeItems>
          </S.NewMenuChangeItemType>
        )}
      </Sticky>
    </div>
  );
};

MenuNewChangeItemsType.propTypes = {
  isRelative: PropTypes.bool,
  optionsContainerId: PropTypes.string,
  customOffset: PropTypes.number.isRequired,
  hasPromotionalItems: PropTypes.bool.isRequired,
  categoriesWithProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    establishmentId: PropTypes.string,
    name: PropTypes.string,
    sequence: PropTypes.number,
    updatedAt: PropTypes.number,
    createdAt: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      establishmentId: PropTypes.string,
      createdAt: PropTypes.number,
      promotionalPrice: PropTypes.number,
      sequence: PropTypes.number,
      additionals: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
      updatedAt: PropTypes.number,
      paused: PropTypes.bool,
      description: PropTypes.string,
      priceType: PropTypes.string,
      categoryId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        promotionalPrice: PropTypes.number,
      })),
    })),
  })).isRequired,
};

MenuNewChangeItemsType.defaultProps = {
  isRelative: false,
  optionsContainerId: '',
};

export default MenuNewChangeItemsType;
