import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaStore } from 'react-icons/fa';
import * as S from './styles';

const EstablishmentBrand = ({
  name, brand, className, size, smallSize, margin,
}) => (
  <S.EstablishmentBrand
    size={size}
    margin={margin}
    smallSize={smallSize}
    className={className}
  >
    {brand ? (
      <img src={brand} alt={name} />
    ) : (
      <FaStore size={32} color="var(--primary-color)" />
    )}
  </S.EstablishmentBrand>
);

EstablishmentBrand.propTypes = {
  margin: PropTypes.string,
  className: PropTypes.string,
  smallSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
};

EstablishmentBrand.defaultProps = {
  margin: '',
  className: '',
  smallSize: null,
};

const mapStateToProps = (state) => ({
  name: state.establishment.name,
  brand: state.establishment.brand,
});

export default connect(mapStateToProps, null)(EstablishmentBrand);
