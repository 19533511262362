import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPixel from 'react-facebook-pixel';
import { formatNumberToReal, formatRealToNumber } from '../../../utils/formatting';
import * as S from './styles';
import * as CartActions from '../../../store/modules/cart/actions';
import { getProductPriceByInfos } from '../../../utils/getProductValues';
import {
  UIInput,
  UIButton,
  UIInputCurrency,
  UIQuantityControl,
} from '../../UI';

const ProductAddToCart = ({
  additionals,
  isForEdit,
  product,
  addToCart,
  updateItem,
  isOpen,
  hasValidDaysToScheduleOrders,
}) => {
  const history = useHistory();
  const { establishment } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [weight, setWeight] = useState(null);
  const [total, setTotal] = useState(null);
  const availableToMakeOrders = isOpen || hasValidDaysToScheduleOrders;
  const [requiredInfosSelected, setRequiredInfosSelected] = useState(false);
  const [componentAlreadyRendered, setComponentAlreadyRendered] = useState(false);
  const productHasUnselectedVariation = product.variations?.length > 0
    && product.selectedVariations.length <= 0;
  const maxQuantity = product?.maxQuantity || 0;
  const maxSelected = maxQuantity > 0 && quantity >= maxQuantity;

  useEffect(() => {
    if (product.amount && !componentAlreadyRendered) {
      setComponentAlreadyRendered(true);
      setQuantity(product.amount);
    }
  }, [product, componentAlreadyRendered]);

  useEffect(() => {
    const isAllRequiredInfosSelected = () => {
      const additionalsAndMinReqOpts = additionals.reduce(
        (acc, { infos }) => {
          if (infos.min > 0) {
            acc.push({
              name: infos.name,
              min: infos.min,
            });
          }
          return acc;
        },
        [],
      );

      const hasMinAdditionalsReqOptsSelected = additionalsAndMinReqOpts.every(({ name, min }) => {
        const additionalsSelected = product.selectedAdditionals.filter(
          ({ groupName }) => groupName === name,
        );

        if (additionalsSelected.length <= 0) return false;

        const numberOfSelectedOptions = additionalsSelected.reduce(
          (acc, { quantity: optionQuantity }) => acc + optionQuantity,
          0,
        );

        const hasMinOptionSelected = numberOfSelectedOptions >= min;

        return hasMinOptionSelected;
      });

      const validVariations = product.variations.filter((variation) => !variation.paused);

      if (validVariations.length > 0) {
        if (product.selectedVariations.length > 0 && hasMinAdditionalsReqOptsSelected) return true;
        return false;
      }

      return hasMinAdditionalsReqOptsSelected;
    };

    setRequiredInfosSelected(isAllRequiredInfosSelected());
  }, [product, additionals]);

  const handleAddProduct = useCallback(
    (productToAdd, quantityToAdd) => {
      if (isForEdit) {
        updateItem(productToAdd, quantityToAdd, productToAdd.id);
      } else {
        if (weight && Number(weight) > 0) {
          addToCart({ ...productToAdd, weight: Number(weight) }, quantityToAdd);
        } else {
          addToCart(productToAdd, quantityToAdd);
        }
        ReactPixel.track('AddToCart', {
          content_type: 'product',
          content_name: productToAdd.name,
          value: getProductPriceByInfos(productToAdd) * quantityToAdd,
          currency: 'BRL',
        });
      }
      history.push(`/${establishment}/produtos/`);
    },
    [updateItem, addToCart, isForEdit, establishment, history, weight],
  );

  const handleGetOrderPrice = useCallback(
    (amount) => {
      const newPrice = getProductPriceByInfos(product);

      return newPrice * amount;
    },
    [product],
  );

  const handleChangeTotalBasedOnWeight = (newWeight) => {
    const weightToUse = newWeight || weight;
    const qtdInKg = weightToUse / 1000;
    setQuantity(qtdInKg);
    setTotal(qtdInKg * getProductPriceByInfos(product));
  };

  const handleChangeWeightBasedOnTotal = (newTotal) => {
    const totalToUse = newTotal || total;
    const productPrice = getProductPriceByInfos(product);
    const qtdByValue = formatRealToNumber(totalToUse) / productPrice;
    setWeight(qtdByValue * 1000);
    setQuantity(qtdByValue);
  };

  return (
    <S.ProductAddToCart>
      {maxSelected && (
        <S.IsClosedInfos>
          <strong>Quantidade máxima selecionada</strong>
          <span>
            Você pode selecionar no máximo
            {` ${maxQuantity} `}
            deste item
          </span>
        </S.IsClosedInfos>
      )}
      {!availableToMakeOrders && (
        <S.IsClosedInfos>
          <strong>Poxa, estamos fechados no momento 😢.</strong>
          <span>
            Por isso,
            {' '}
            <strong>no momento</strong>
            {' '}
            não estamos aceitando novos
            pedidos.
          </span>
        </S.IsClosedInfos>
      )}
      {product.unit !== 'KG' && (
        <div className="tw-flex tw-w-full tw-items-center">
          <UIQuantityControl
            quantity={quantity}
            setQuantity={setQuantity}
            disabled={!availableToMakeOrders}
            disableButtons={!requiredInfosSelected || maxSelected}
          />
          <UIButton
            type="button"
            disabled={!availableToMakeOrders}
            className="ProductAddToCart__button"
            disabledAppearance={!requiredInfosSelected}
            onClick={() => handleAddProduct(product, quantity)}
          >
            <div>
              <span>Adicionar</span>
              <span className="total__value">
                {formatNumberToReal(handleGetOrderPrice(quantity))}
              </span>
            </div>
          </UIButton>
        </div>
      )}
      {product.unit === 'KG' && (
        <>
          <div className="tw-flex tw-w-full tw-items-center">
            <div className="tw-flex tw-flex-col tw-w-full">
              {productHasUnselectedVariation && (
                <S.IsClosedInfos>
                  <span>
                    Selecione as informações
                    {' '}
                    <strong>obrigatórias</strong>
                    {' '}
                    para selecionar a quantidade
                  </span>
                </S.IsClosedInfos>
              )}
              {!productHasUnselectedVariation && (
                <div className="tw-flex tw-gap-4">
                  <UIInput
                    type="number"
                    value={weight}
                    placeholder="Ex: 1"
                    label="Peso (gramas)"
                    infoMessage={`${quantity} kg`}
                    setValue={(newValue) => {
                      setWeight(newValue);
                      handleChangeTotalBasedOnWeight(newValue);
                    }}
                  />
                  <UIInputCurrency
                    label="Valor"
                    value={total}
                    setValue={(newValue) => {
                      setTotal(newValue);
                      handleChangeWeightBasedOnTotal(newValue);
                    }}
                  />
                </div>
              )}
              <UIButton
                type="button"
                text="Adicionar"
                disabled={!availableToMakeOrders}
                disabledAppearance={!requiredInfosSelected}
                onClick={() => handleAddProduct(product, quantity)}
              />
            </div>
          </div>
        </>
      )}
    </S.ProductAddToCart>
  );
};

ProductAddToCart.propTypes = {
  isForEdit: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  addToCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  hasValidDaysToScheduleOrders: PropTypes.bool.isRequired,
  additionals: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.number,
    establishmentId: PropTypes.string,
    infos: PropTypes.shape({
      repeatOptions: PropTypes.bool,
      optionsLength: PropTypes.number,
      name: PropTypes.string,
      chargeForTheMostExpensive: PropTypes.bool,
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      paused: PropTypes.bool,
      price: PropTypes.number,
    })),
    products: PropTypes.arrayOf(PropTypes.string),
    updatedAt: PropTypes.number,
  })).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    maxQuantity: PropTypes.number,
    categoryId: PropTypes.string,
    description: PropTypes.string,
    observation: PropTypes.string,
    unit: PropTypes.oneOf(['UNIT', 'KG']),
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    selectedVariations: PropTypes.arrayOf(PropTypes.object),
    selectedAdditionals: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

ProductAddToCart.defaultProps = {
  isForEdit: false,
};

const mapStateToProps = (state) => ({
  isOpen: state.establishment.isOpen,
  hasValidDaysToScheduleOrders:
    state.establishment.scheduleOrders.hasValidDaysToScheduleOrders,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddToCart);
