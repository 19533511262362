import { createSelector } from 'reselect';
import { getProductsTotal } from '../products';
import { getDeliveryRate } from '../deliveryRate';
import { formatNumberToReal } from '../../../utils/formatting';
import { getCouponValue, getIfActiveCouponIsValidToUse } from '../coupon';

export const getCartTotal = createSelector(
  [getProductsTotal, getDeliveryRate, getCouponValue, getIfActiveCouponIsValidToUse],
  (productsTotal, deliveryRate, couponValue, activeCouponIsValidToUse) => {
    if (couponValue && activeCouponIsValidToUse) {
      if (couponValue === 'FRETE GRÁTIS') {
        return productsTotal;
      }
      return (productsTotal + deliveryRate) - couponValue;
    }

    return productsTotal + deliveryRate;
  },
);

export const getFormattedCartTotal = createSelector(
  [getCartTotal],
  (cartTotal) => formatNumberToReal(cartTotal),
);
