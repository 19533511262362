import styled from 'styled-components';

export const DevelopBy = styled.div`
  height: 32px;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    background: #fff;
    align-items: flex-end;
    justify-content: center;
    span {
      color: #8b8b8b;
      font-size: 11px;
      line-height: 11px;
      margin-right: 4px;
    }
  }
`;
