import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App } from './App';
import 'core-js/stable';

Sentry.init({
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
