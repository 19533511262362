import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const UIContainer = ({ className, children }) => (
  <S.UIContainer className={className}>
    {children}
  </S.UIContainer>
);

UIContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

UIContainer.defaultProps = {
  className: '',
};

export default UIContainer;
