import styled from 'styled-components';

export const LastOrderDescription = styled.small`
  text-align: center;
  margin-bottom: 30px;
`;

export const EstablishmentInfos = styled.div`
  display: flex;
  margin-bottom: 30px;

  .infos {
    display: flex;
    margin-left: 15px;
    flex-direction: column;

    small {
      color: var(--text-secondary-color)
    }
  }
`;

export const SuccessContainer = styled.div`
  display: flex;
  background: red;
  min-height: 42px;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  background: var(--default-gray);
  border-radius: var(--default-border-radius);

  small {
    font-weight: 500;
    margin-left: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  small {
    text-align: center;
    margin-bottom: 10px;
  }
`;
