import * as React from 'react';
import PropTypes from 'prop-types';

export function Lupe({ color, className }) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g id="V1" stroke="none" fill="none">
        <g id="search" transform="translate(-326.000000, -627.000000)">
          <g transform="translate(327.000000, 628.000000)">
            <circle id="Oval" stroke={color || '#000000'} strokeWidth="2" cx="8" cy="8" r="8" />
            <line x1="18" y1="18" x2="13.65" y2="13.65" id="Path" stroke={color || '#000000'} strokeWidth="2" />
          </g>
        </g>
      </g>
    </svg>
  );
}

Lupe.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Lupe.defaultProps = {
  color: '',
  className: '',
};
