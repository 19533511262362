import React from 'react';
import ContentLoader from 'react-content-loader';
import { useWindowSize } from '@react-hook/window-size';

export const MenuProductsSectionLoader = () => {
  const [width] = useWindowSize();
  return (
    <ContentLoader
      width="100%"
      height="100%"
      foregroundColor="#e9e8e8"
      backgroundColor="#f3f3f3"
      viewBox={`0 0 ${width} 535`}
    >
      <rect x="0" y="0" width={width} height="105" />
      <rect x="0" y="105" width={width} height="60" />
      <rect x="20" y="185" rx="5" ry="5" width={width - 40} height="100" />
      <rect x="20" y="300" rx="5" ry="5" width={width - 40} height="100" />
      <rect x="20" y="415" rx="5" ry="5" width={width - 40} height="100" />
    </ContentLoader>
  );
};
