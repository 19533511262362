export const getModalTitle = (addNewAddress, addressToEdit) => {
  if (addNewAddress) {
    return 'Adicione um novo endereço';
  }

  if (addressToEdit?.street) {
    return 'Edite o endereço';
  }

  return 'Endereço para entrega';
};

export const getModalCloseFunc = (
  addNewAddress,
  addressToEdit,
  setAddNewAddress,
  setAddressToEdit,
  setShowAddressSelectionModal,
) => {
  if (addNewAddress) {
    return setAddNewAddress;
  }

  if (addressToEdit?.street) {
    return setAddressToEdit;
  }

  return setShowAddressSelectionModal;
};
