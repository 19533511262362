import { parsedDayOfWeek } from '../parsedDayOfWeek';
import { getIfNowIsBetweenTwoHours } from '../date/getIfNowIsBetweenTwoHours';

export const getInvalidMsgOnCoupon = (coupon) => {
  const {
    active, quantity, quantityUsed, date, availability, hour,
  } = coupon;

  function getIfIsActive() {
    if (active) {
      return '';
    }
    return 'INVALID_ACTIVE';
  }

  function getIfQuantityIsAvailable() {
    if (quantityUsed < quantity) {
      return '';
    }

    return 'INVALID_QUANTITY';
  }

  function getIfInitialAndFinalDateIsAvailable() {
    const timestampNow = new Date().getTime();
    if (timestampNow < date.final && timestampNow > date.initial) {
      return '';
    }

    return 'INVALID_DATE';
  }

  function getIfDayOfWeekIsAvailable() {
    const todayDayOfWeek = parsedDayOfWeek[new Date().getDay()];
    const isTodayAvailable = availability.some((dayOfWeek) => dayOfWeek === todayDayOfWeek);

    if (isTodayAvailable) {
      return '';
    }

    return 'INVALID_DAY_OF_WEEK';
  }

  function getIfHourIsAvailable() {
    const nowIsBetweenFromAndUntilTime = getIfNowIsBetweenTwoHours(hour.initial, hour.final);

    if (nowIsBetweenFromAndUntilTime) {
      return '';
    }

    return 'INVALID_HOUR';
  }

  const invalidDatasList = [getIfIsActive(),
    getIfQuantityIsAvailable(),
    getIfInitialAndFinalDateIsAvailable(),
    getIfDayOfWeekIsAvailable(),
    getIfHourIsAvailable()].filter((data) => data.length > 0);

  return invalidDatasList[0];
};
