import styled, { css } from 'styled-components';
import { Lupe } from '../../../../assets/svgs/lupe';

export const InputSearch = styled.input`
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 20px;
  border-radius: 4px;
  position: relative;
  border: 1px solid var(--default-gray);
  &:focus {
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 7px 0 var(--primary-color);
  }
`;

export const ContainerSearch = styled.div`
  z-index: 1;
  display: flex;
  padding: 10px 20px;
  position: relative;
  align-items: center;
  background: #FFFFFF;
  a {
    height: 24px;
    display: flex;
    font-size: 24px;
    margin-right: 10px;
  }
  ${(props) => props.$isSticky && css`
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
  `}
`;

export const ResultsNumberContainer = styled.div`
  display: flex;
  padding: 8px 0;
  margin: 0 -20px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  border: 1px solid var(--default-gray);
`;

export const ResultsNumber = styled.span`
  font-size: 12px;
  text-align: center;
  color: var(--text-secondary-color);
`;

export const LupeStyled = styled(Lupe)`
  right: 35px;
  width: 18px;
  height: 18px;
  position: absolute;
`;
