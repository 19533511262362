export function addLinkAccessed(linkAccessed) {
  return {
    type: '@system/ADD_LINK_ACCESSED',
    linkAccessed,
  };
}

export function addLastOrderId(lastOrderId) {
  return {
    type: '@system/ADD_LAST_ORDER_ID',
    lastOrderId,
  };
}
