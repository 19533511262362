import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import * as S from './styles';
import { DevelopBy } from '../DevelopedBy';
import EstablishmentBrand from '../EstablishmentBrand';

const Header = ({
  name, brand, banner, establishmentCategories,
}) => {
  const windowWidth = useWindowWidth();
  const { establishment } = useParams();

  return (
    <>
      <DevelopBy />

      {banner && (
        <S.HeaderBanner>
          <div />
          <img src={banner} alt={`${name} banner`} />
        </S.HeaderBanner>
      )}

      <S.HeaderEstablishmentInfos $hasBanner={banner}>
        {brand && (
          <EstablishmentBrand size={95} smallSize={115} margin="0 15px 0 0" />
        )}
        <S.HeaderEstablishmentInfosContent $hasBanner={banner}>
          <div>
            <span>
              {establishmentCategories.map((cat, index) => {
                const isLastOnArray = index + 1 === establishmentCategories.length;
                const isPenultimateOnArray = index + 1 === establishmentCategories.length - 1;
                if (isLastOnArray) {
                  return cat;
                }
                if (isPenultimateOnArray) {
                  return `${cat} & `;
                }
                return `${cat}, `;
              })}
            </span>
            <h2>{name}</h2>
            {(windowWidth <= 375 && brand) && (
              <S.HeaderEstablishmentInfosAboutStore
                to={`/${establishment}`}
              >
                Sobre a loja
                {' '}
                {'>'}
              </S.HeaderEstablishmentInfosAboutStore>
            )}
          </div>
          {(windowWidth > 375 || !brand) && (
            <S.HeaderEstablishmentInfosAboutStore
              $hasBanner={banner}
              to={`/${establishment}`}
            >
              Sobre a loja &gt;
            </S.HeaderEstablishmentInfosAboutStore>
          )}
        </S.HeaderEstablishmentInfosContent>
      </S.HeaderEstablishmentInfos>
    </>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
  establishmentCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  name: state.establishment.name,
  brand: state.establishment.brand,
  banner: state.establishment.banner,
  establishmentCategories: state.establishment.establishmentCategories,
});

export default connect(mapStateToProps, null)(Header);
