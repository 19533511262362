import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CgMathPlus } from 'react-icons/cg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { formatNumberToReal } from '../../../utils/formatting';

import * as S from './styles';
import * as CartActions from '../../../store/modules/cart/actions';
import { UIQuantityControl } from '../../UI';

const ShopWindowItem = ({
  item, simpleModel, productsOnCart, updateItem, addToCart, removeFromCart,
}) => {
  const [itemOnCart, setItemOnCart] = useState(null);
  const [quantityOnCart, setQuantityOnCart] = useState(0);
  const { establishment } = useParams();

  useEffect(() => {
    const { id } = item;

    const selectedItem = productsOnCart.find(({ id: productId }) => (
      id === productId
    ));

    if (selectedItem) {
      setItemOnCart(selectedItem);
      setQuantityOnCart(selectedItem.amount);
    } else {
      setQuantityOnCart(0);
    }
  }, [item, productsOnCart]);

  const {
    id,
    name,
    menu,
    image,
    price,
    variations,
    additionals,
    description,
  } = item;

  const itemLink = `/${establishment}/${menu}/${id}`;

  return (
    <S.ShopWindowItem simpleModel={simpleModel}>
      <S.ImageContainer simpleModel={simpleModel}>
        <Link to={itemLink}>
          <img
            alt={name}
            src={image}
            className="image-container__image"
          />
        </Link>

        {(!variations || variations.length <= 0) && (!additionals || additionals.length <= 0) && (
          <S.ControlsContainer>
            {quantityOnCart > 0 ? (
              <UIQuantityControl
                canBeZero
                customHeight={34.6}
                quantity={quantityOnCart}
                setQuantity={(qtd) => {
                  if (qtd === 0) {
                    removeFromCart(itemOnCart.id);
                  } else {
                    updateItem(itemOnCart, qtd, itemOnCart.id);
                  }
                }}
              />
            ) : (
              <S.PlusIcon type="button" onClick={() => addToCart(item, 1)}>
                <CgMathPlus />
              </S.PlusIcon>
            )}
          </S.ControlsContainer>
        )}

      </S.ImageContainer>

      {simpleModel ? (
        <Link to={itemLink}>
          <S.Name simpleModel>{name}</S.Name>
          <S.Price simpleModel>{formatNumberToReal(price)}</S.Price>
        </Link>
      ) : (
        <Link to={itemLink}>
          <S.Price>{formatNumberToReal(price)}</S.Price>
          <S.Name>{name}</S.Name>
          <S.Description>{description}</S.Description>
        </Link>
      )}
    </S.ShopWindowItem>
  );
};

ShopWindowItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    menu: PropTypes.string,
    additionals: PropTypes.arrayOf(PropTypes.object),
    variations: PropTypes.arrayOf(PropTypes.object),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
    selectedAdditionals: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  simpleModel: PropTypes.bool,
  addToCart: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  productsOnCart: PropTypes.arrayOf(PropTypes.node).isRequired,
};

ShopWindowItem.defaultProps = {
  simpleModel: false,
};

const mapStateToProps = (state) => ({
  productsOnCart: state.cart.products,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShopWindowItem);
