import { store } from '../../../store';
import { app } from '../../index';

export const getCustomerByPhone = async (customerPhone) => {
  try {
    const db = app.firestore();
    const { establishment: { id } } = store.getState();
    const data = await db
      .collection('customers')
      .where('establishmentId', '==', id)
      .where('phone', '==', customerPhone)
      .get();

    if (data.empty) return {};

    return {
      ...data.docs[0].data(),
      id: data.docs[0].id,
    };
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getCustomerById = async (customerId) => {
  try {
    const db = app.firestore();
    const { establishment: { id } } = store.getState();
    const data = await db
      .collection('customers')
      .doc(customerId)
      .get();

    if (data.empty) return {};

    const customerData = data.data();
    if (customerData && customerData.establishmentId === id) {
      return {
        ...customerData,
        id: data.id,
      };
    }

    return {};
  } catch (err) {
    console.log(err);
    return [];
  }
};
