import { orderBySmallestToLargest } from '../../../utils/orderBy';
import { store } from '../../../store';
import { app } from '../../index';

export const getAllCategories = async () => {
  try {
    const db = app.firestore();
    const { establishment: { id } } = store.getState();
    const data = await db
      .collection('categories')
      .where('establishmentId', '==', id)
      .get();

    const categories = data.docs.map((doc) => ({
      id: doc.id, ...doc.data(),
    }));

    const orderedCategories = orderBySmallestToLargest(categories, 'sequence');

    return orderedCategories;
  } catch (err) {
    console.log(err);
    return [];
  }
};
