import styled, { css } from 'styled-components';

export const UIInput = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;

  .UIInput {
    &__input-container {
      display: flex;
      position: relative;
      align-items: center;
    }
    &__input {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      border-radius: 4px;
      border: 1px solid var(--default-gray);

      &:active,
      &:hover {
        border: 1px solid var(--primary-color);
      }

      &:focus {
        box-shadow: 0 0 7px 0 var(--primary-color);
        border: 1px solid var(--primary-color);
      }

      &:read-only {
        color: var(--text-secondary-color);
        background: var(--default-gray);
        &:focus {
          border: none;
          box-shadow: none;
        }
        &:hover {
          border: 1px solid transparent;
        }
      }
    }
    &__icon {
      right: 20px;
      height: 20px;
      display: flex;
      font-size: 20px;
      position: absolute;
      align-items: center;
    }
    &__error-msg {
      font-size: 12px;
      margin-top: 5px;
      color: var(--alert-color);
    }
    &__info-msg {
      font-size: 12px;
      margin-top: 5px;
      color: var(--default-dark-gray);
    }
  }

  ${(props) => props.hasIcon
    && css`
      .UIInput {
        &__input {
          padding-right: 50px;
        }
      }
    `}

  ${(props) => props.error
    && css`
      .UIInput {
        &__input {
          background: #FF000007;
          border: 1px solid red;
        }
      }
    `}

  ${(props) => props.hideInput
    && css`
      display: none !important;
    `}
`;
