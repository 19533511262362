import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import * as CartActions from '../../../../../store/modules/cart/actions';
import { parseHourStringOnDayjsFormat } from '../../../../../utils/hour/parseHourStringOnDayjsFormat';
import {
  UIModal,
  UIButton,
  UIRadioList,
  UIFixedBottom,
  UIHorizontalOptions,
} from '../../../../UI';

const CartScheduleOrderModal = ({
  showModal, setShowModal, scheduleOrders, addIsScheduled, addScheduleOrder,
}) => {
  const { scheduleDaysOptions } = scheduleOrders;
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const selectedDayInfos = scheduleDaysOptions.find((option) => option.value === selectedDate);

  if (!scheduleOrders.active) return null;

  const handleSaveSelectedDate = () => {
    const [startHour, endHour] = selectedHour.split(' - ');

    addIsScheduled(true);
    addScheduleOrder({
      deliveryDateTimeEnd: parseHourStringOnDayjsFormat(endHour, dayjs(selectedDate)).toISOString(),
      deliveryDateTimeStart:
        parseHourStringOnDayjsFormat(startHour, dayjs(selectedDate)).toISOString(),
    });

    setShowModal(false);
  };

  return (
    <UIModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle="Agendar pedido"
    >
      <UIHorizontalOptions
        selected={selectedDate}
        options={scheduleDaysOptions}
        setSelected={setSelectedDate}
      />
      {selectedDayInfos?.hoursOptions?.length && (
        <UIRadioList
          selected={selectedHour}
          setSelected={setSelectedHour}
          options={selectedDayInfos?.hoursOptions}
        />
      )}
      <UIFixedBottom>
        <UIButton
          text="Confirmar data"
          onClick={handleSaveSelectedDate}
          disabledAppearance={!selectedHour && !selectedDate}
          functionWhenDisable={() => toast.error('Selecione um horário')}
        />
      </UIFixedBottom>
    </UIModal>
  );
};

CartScheduleOrderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addIsScheduled: PropTypes.func.isRequired,
  addScheduleOrder: PropTypes.func.isRequired,
  scheduleOrders: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    scheduleDaysOptions: PropTypes.arrayOf(PropTypes.any),
    maxAntecedence: PropTypes.shape({
      format: PropTypes.oneOf(['hour', 'day']).isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    minAntecedence: PropTypes.shape({
      format: PropTypes.oneOf(['hour', 'day']).isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  scheduleOrders: state.establishment.scheduleOrders,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(CartActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartScheduleOrderModal);
