import { createSelector } from 'reselect';
import { formatNumberToReal } from '../../../utils/formatting';
import { getProductPriceByInfos } from '../../../utils/getProductValues';

export const getProductsTotal = (state) => state.cart.products.reduce(
  (acc, product) => acc + getProductPriceByInfos(product) * product.amount,
  0,
);

export const getFormattedProductsTotal = createSelector(
  getProductsTotal,
  (productsTotal) => formatNumberToReal(productsTotal),
);
