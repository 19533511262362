import 'dayjs/locale/pt-br';
import { store } from '../../store';
import { formatStringForSearch } from '../formatting';

export const getIfThisWeekdayEstablishmentIsOpen = (
  weekdayToValidate,
) => {
  const {
    establishment: { businessHours },
  } = store.getState();

  const isOpenOnThisWeekday = businessHours.some(
    ({ weekday, isActive }) => (isActive === true)
      && (formatStringForSearch(weekday)
        === formatStringForSearch(weekdayToValidate)),
  );

  return isOpenOnThisWeekday;
};
