import styled from 'styled-components';
import { UICard } from '../../UI';

export const CartMinOrder = styled(UICard)`
  margin-top: 20px;
  span {
    text-align: center;
    color: var(--primary-color);
  }
`;
