import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getActiveHourData } from '../hour/getActiveHourData';

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

const DEFAULT_HOUR_OFFSET = 5;

export const forceCloseStoreIsActive = (forceClosingDate) => {
  if (!forceClosingDate) {
    return false;
  }

  // This margin is necessary because some restaurants work until after midnight
  const distanceInHours = dayjs().diff(
    dayjs(forceClosingDate, 'DD/MM/YYYY'),
    'hours',
  );

  if (distanceInHours >= 0 && distanceInHours <= 24 + DEFAULT_HOUR_OFFSET) {
    return true;
  }

  return false;
};

// This functions returns if the current date is same or before within the range
// of the store open hours + DEFAULT_HOUR_OFFSET
export const forceOpenStoreIsActive = (date) => {
  if (!date) {
    return false;
  }

  const openDate = dayjs(date, 'DD/MM/YYYY').add(1, 'day').add(DEFAULT_HOUR_OFFSET, 'hour');
  return dayjs().isSameOrBefore(openDate);
};

export const getIsStoreOpen = (establishment, isOnlineCommand) => {
  if (isOnlineCommand) return true;

  const isForcedOpen = forceOpenStoreIsActive(establishment.forceOpenDate);
  const forceCloseIsActive = forceCloseStoreIsActive(
    establishment.forceClosingDate,
  );

  const activeHourNow = getActiveHourData(
    establishment.businessHours,
  );

  const isStoreOpen = isForcedOpen || !!activeHourNow?.startHour;

  return forceCloseIsActive ? false : isStoreOpen;
};
