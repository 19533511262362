import { app } from '../../../index';

export const updateCouponQuantity = async (couponId) => {
  const db = app.firestore();
  const data = await db
    .collection('coupons')
    .doc(couponId)
    .get();

  const coupon = data.data();

  await db
    .collection('coupons')
    .doc(couponId)
    .set({ quantityUsed: (coupon.quantityUsed + 1) }, { merge: true });

  return true;
};
