import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderPageTop = styled.div`
  height: 32px;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    display: flex;
    background: #fff;
    align-items: flex-end;
    justify-content: center;
    span {
      color: #8b8b8b;
      font-size: 11px;
      line-height: 11px;
      margin-right: 4px;
    }
  }
`;

export const HeaderBanner = styled.div`
  height: 200px;
  position: relative;
  & > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  & > div {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export const HeaderEstablishmentInfos = styled.div`
  z-index: 2;
  display: flex;
  position: relative;
  margin: 15px 15px 25px 15px;
  ${({ $hasBanner }) => $hasBanner
    && css`
      margin: -15px 15px 25px 15px;
    `}
`;

export const HeaderEstablishmentInfosContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > div {
    span {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      color: var(--primary-color);
    }
    h2 {
      color: #414141;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.56px;
    }
  }
  ${({ $hasBanner }) => $hasBanner
    && css`
      & > div {
        margin-top: 30px;
      }
      @media screen and (max-width: 375px) {
        & > div {
          margin-top: 15px;
        }
      }
    `}
`;

export const HeaderEstablishmentInfosAboutStore = styled(Link)`
  display: none;
  color: #5e5e5e;
  font-size: 12px;
  margin-top: 6px;
  line-height: 18px;
  white-space: nowrap;
  letter-spacing: -0.3px;
  ${({ $hasBanner }) => $hasBanner
    && css`
      margin-top: 22px;
    `}
`;
