import React from 'react';
import Ink from 'react-ink';
import PropTypes from 'prop-types';

import { BsPencil } from 'react-icons/bs';
import * as S from './styles';

const UIButtonToEdit = ({
  icon,
  onClick,
  disabled,
  redBorder,
  className,
  noRightIcon,
  firstElement,
  thirdElement,
  secondElement,
}) => (
  <S.UIButtonToEdit
    type="button"
    disabled={disabled}
    className={className}
    redBorder={redBorder}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    <div>
      <span className="icon">
        {icon}
      </span>
      <div>
        {firstElement}
        {secondElement}
        {thirdElement}
      </div>
    </div>
    {!noRightIcon && (
      <BsPencil />
    )}
    {onClick && (
      <Ink />
    )}
  </S.UIButtonToEdit>
);

UIButtonToEdit.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  redBorder: PropTypes.bool,
  className: PropTypes.string,
  noRightIcon: PropTypes.bool,
  thirdElement: PropTypes.element,
  firstElement: PropTypes.element.isRequired,
  secondElement: PropTypes.element,
};

UIButtonToEdit.defaultProps = {
  icon: null,
  onClick: null,
  className: '',
  disabled: false,
  redBorder: false,
  noRightIcon: false,
  thirdElement: null,
  secondElement: undefined,
};

export default UIButtonToEdit;
