import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import CustomInputLabel from '../CustomInputLabel';

const UIInput = ({
  type,
  icon,
  label,
  value,
  onBlur,
  setValue,
  readOnly,
  required,
  className,
  maxLength,
  description,
  customInput,
  placeholder,
  infoMessage,
  errorMessage,
  defaultValue,
  maskValueFunction,
  containerClassName,
  validatedValue,
  customValidation,
  inputMode,
}) => (
  <S.UIInput className={`UIInput ${containerClassName}`} hasIcon={icon} error={!!errorMessage}>
    {label && (
      <CustomInputLabel
        labelTitle={label}
        isRequired={required}
        description={description}
        isValited={
          customValidation ? validatedValue : !!value
        }
      />
    )}

    <div className="UIInput__input-container">
      {customInput || (
        defaultValue ? (
          <input
            type={type}
            onBlur={onBlur}
            readOnly={readOnly}
            maxLength={maxLength}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className={`UIInput__input ${className}`}
            inputMode={inputMode}
          />
        ) : (
          <input
            type={type}
            onBlur={onBlur}
            readOnly={readOnly}
            maxLength={maxLength}
            placeholder={placeholder}
            className={`UIInput__input ${className}`}
            value={maskValueFunction ? maskValueFunction(value) : value}
            onChange={(e) => (setValue ? setValue(e.target.value) : null)}
            inputMode={inputMode}
          />
        )
      )}
    </div>

    {errorMessage && (
      <span className="UIInput__error-msg">{errorMessage}</span>
    )}

    {infoMessage && (
      <span className="UIInput__info-msg">{infoMessage}</span>
    )}

  </S.UIInput>
);

UIInput.propTypes = {
  onBlur: PropTypes.func,
  icon: PropTypes.element,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  description: PropTypes.string,
  infoMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  customInput: PropTypes.element,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  inputMode: PropTypes.string,
  maskValueFunction: PropTypes.func,
  containerClassName: PropTypes.string,
  validatedValue: PropTypes.bool,
  customValidation: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['text', 'email', 'tel', 'password', 'url', 'search', 'number', 'date', 'range']).isRequired,
};

UIInput.defaultProps = {
  value: '',
  label: '',
  icon: null,
  onBlur: null,
  className: '',
  setValue: null,
  readOnly: false,
  infoMessage: '',
  errorMessage: '',
  customInput: null,
  required: false,
  maxLength: null,
  defaultValue: null,
  placeholder: '',
  description: '',
  containerClassName: '',
  inputMode: 'text',
  maskValueFunction: null,
  validatedValue: false,
  customValidation: false,
};

export default UIInput;
