import { isMobile } from 'react-device-detect';

export const getWhatsAppLink = (phone, msg) => {
  const encodedMsg = encodeURIComponent(msg);
  const whatsLink = isMobile
    ? `whatsapp://send/?phone=${phone}&text=${encodedMsg}`
    : `https://web.whatsapp.com/send?phone=${phone}&text=${encodedMsg}`;

  return whatsLink;
};
