import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/input';
import UIInput from '..';

const UIInputPhone = ({
  label,
  value,
  onBlur,
  setValue,
  className,
  required,
  description,
  placeholder,
  errorMessage,
}) => (
  <UIInput
    type="text"
    label={label}
    value={value}
    customValidation
    required={required}
    description={description}
    errorMessage={errorMessage}
    validatedValue={[13, 14].includes(value.length)}
    customInput={(
      <PhoneInput
        country="BR"
        value={value}
        minLength={15}
        maxLength={15}
        onBlur={onBlur}
        placeholder={placeholder}
        className={`UIInput__input ${className}`}
        onChange={(number) => { setValue(number); }}
      />
    )}
  />
);

UIInputPhone.propTypes = {
  onBlur: PropTypes.func,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UIInputPhone.defaultProps = {
  value: '',
  onBlur: null,
  className: '',
  setValue: null,
  required: false,
  description: '',
  errorMessage: '',
};

export default UIInputPhone;
