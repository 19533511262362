import styled from 'styled-components';

export const UILabel = styled.span`
  color: #FFF;
  margin: 0 4px;
  font-size: 12px;
  padding: 2px 5px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background: var(--primary-color);
  border-radius: var(--default-border-radius);
`;
