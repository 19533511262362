import styled from 'styled-components';

export const LetterAvatar = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  min-width: 50px;
  min-height: 50px;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background: var(--primary-color-opacity);

  span {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color)
  }
`;
