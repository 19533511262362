import { separateParagraphs } from '../../separateParagraphs';
import { formatNumberToReal } from '../../formatting';
import { getProductPriceByInfos } from '../../getProductValues';
import { returnFormattedWeightDescription } from '../../returnFormattedWeightDescription';

export const createWhatsMessageItems = (cartItems) => cartItems.map((item) => {
  const variationsAndAdditionals = [];

  if (item.variations) {
    variationsAndAdditionals.push(...item.variations);
  }

  if (item.additionals) {
    variationsAndAdditionals.push(...item.additionals);
  }

  const variationsAndAdditionalsString = variationsAndAdditionals.length > 0 ? (
    variationsAndAdditionals.map(({ name, quantity }) => (
      `_- ${quantity || 1}x ${name}_`
    )).join(`
`)
  ) : '';

  return (
    separateParagraphs([
      item.unit === 'KG'
        ? `*(${returnFormattedWeightDescription(item.weight)}) ${item.name}* - ${formatNumberToReal(
          getProductPriceByInfos(item) * item.amount,
        )}`
        : `*${item.amount}x ${item.name}* - ${formatNumberToReal(
          getProductPriceByInfos(item) * item.amount,
        )}`,
      variationsAndAdditionalsString,
      `${item.observation ? `_Obs: ${item.observation}_` : ''}`,
    ])
  );
}).join(`

`);
