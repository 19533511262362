import React from 'react';
import PropTypes from 'prop-types';

import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import * as S from './styles';

const HeaderSimpleWithReturn = ({
  title, returnLink, rightAction, onClick, noMarginBottom, noHorizontalPadding,
}) => (
  <S.HeaderSimpleWithReturn
    noMarginBottom={noMarginBottom}
    rightAction={rightAction?.label}
    $noHorizontalPadding={noHorizontalPadding}
  >
    {(onClick || returnLink) && (
      returnLink ? (
        <Link className="close" to={returnLink}>
          <FiArrowLeft />
        </Link>
      ) : (
        <button
          type="button"
          className="close"
          onClick={() => onClick()}
        >
          {/* eslint-disable-next-line */}
          <FiArrowLeft />
        </button>
      )
    )}
    <span className="title">{title}</span>
    {rightAction?.label && (
      <button
        type="button"
        className="right-action"
        onClick={rightAction?.action}
      >
        {rightAction?.label}
      </button>
    )}
  </S.HeaderSimpleWithReturn>
);

HeaderSimpleWithReturn.propTypes = {
  onClick: PropTypes.func,
  returnLink: PropTypes.string,
  noMarginBottom: PropTypes.bool,
  title: PropTypes.string.isRequired,
  noHorizontalPadding: PropTypes.bool,
  rightAction: PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }),
};

HeaderSimpleWithReturn.defaultProps = {
  onClick: null,
  returnLink: '',
  rightAction: {},
  noMarginBottom: false,
  noHorizontalPadding: false,
};

export default HeaderSimpleWithReturn;
