import { parseHourStringOnDayjsFormat } from '../hour/parseHourStringOnDayjsFormat';

export const hoursOptions = [
  {
    value: '00:00 - 00:30',
    label: '00:00 - 00:30',
    dayjsMin: parseHourStringOnDayjsFormat('00:00'),
    min: {
      hour: 0,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('00:30'),
    max: {
      hour: 0,
      minute: 30,
    },
  },
  {
    value: '00:30 - 01:00',
    label: '00:30 - 01:00',
    dayjsMin: parseHourStringOnDayjsFormat('00:30'),
    min: {
      hour: 0,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('01:00'),
    max: {
      hour: 1,
      minute: 0,
    },
  },
  {
    value: '01:00 - 01:30',
    label: '01:00 - 01:30',
    dayjsMin: parseHourStringOnDayjsFormat('01:00'),
    min: {
      hour: 1,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('01:30'),
    max: {
      hour: 1,
      minute: 30,
    },
  },
  {
    value: '01:30 - 02:00',
    label: '01:30 - 02:00',
    dayjsMin: parseHourStringOnDayjsFormat('01:30'),
    min: {
      hour: 1,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('02:00'),
    max: {
      hour: 2,
      minute: 0,
    },
  },
  {
    value: '02:00 - 02:30',
    label: '02:00 - 02:30',
    dayjsMin: parseHourStringOnDayjsFormat('02:00'),
    min: {
      hour: 2,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('02:30'),
    max: {
      hour: 2,
      minute: 30,
    },
  },
  {
    value: '02:30 - 03:00',
    label: '02:30 - 03:00',
    dayjsMin: parseHourStringOnDayjsFormat('02:30'),
    min: {
      hour: 2,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('03:00'),
    max: {
      hour: 3,
      minute: 0,
    },
  },
  {
    value: '03:00 - 03:30',
    label: '03:00 - 03:30',
    dayjsMin: parseHourStringOnDayjsFormat('03:00'),
    min: {
      hour: 3,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('03:30'),
    max: {
      hour: 3,
      minute: 30,
    },
  },
  {
    value: '03:30 - 04:00',
    label: '03:30 - 04:00',
    dayjsMin: parseHourStringOnDayjsFormat('03:30'),
    min: {
      hour: 3,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('04:00'),
    max: {
      hour: 4,
      minute: 0,
    },
  },
  {
    value: '04:00 - 04:30',
    label: '04:00 - 04:30',
    dayjsMin: parseHourStringOnDayjsFormat('04:00'),
    min: {
      hour: 4,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('04:30'),
    max: {
      hour: 4,
      minute: 30,
    },
  },
  {
    value: '04:30 - 05:00',
    label: '04:30 - 05:00',
    dayjsMin: parseHourStringOnDayjsFormat('04:30'),
    min: {
      hour: 4,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('05:00'),
    max: {
      hour: 5,
      minute: 0,
    },
  },
  {
    value: '05:00 - 05:30',
    label: '05:00 - 05:30',
    dayjsMin: parseHourStringOnDayjsFormat('05:00'),
    min: {
      hour: 5,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('05:30'),
    max: {
      hour: 5,
      minute: 30,
    },
  },
  {
    value: '05:30 - 06:00',
    label: '05:30 - 06:00',
    dayjsMin: parseHourStringOnDayjsFormat('05:30'),
    min: {
      hour: 5,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('06:00'),
    max: {
      hour: 6,
      minute: 0,
    },
  },
  {
    value: '06:00 - 06:30',
    label: '06:00 - 06:30',
    dayjsMin: parseHourStringOnDayjsFormat('06:00'),
    min: {
      hour: 6,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('06:30'),
    max: {
      hour: 6,
      minute: 30,
    },
  },
  {
    value: '06:30 - 07:00',
    label: '06:30 - 07:00',
    dayjsMin: parseHourStringOnDayjsFormat('06:30'),
    min: {
      hour: 6,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('07:00'),
    max: {
      hour: 7,
      minute: 0,
    },
  },
  {
    value: '07:00 - 07:30',
    label: '07:00 - 07:30',
    dayjsMin: parseHourStringOnDayjsFormat('07:00'),
    min: {
      hour: 7,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('07:30'),
    max: {
      hour: 7,
      minute: 30,
    },
  },
  {
    value: '07:30 - 08:00',
    label: '07:30 - 08:00',
    dayjsMin: parseHourStringOnDayjsFormat('07:30'),
    min: {
      hour: 7,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('08:00'),
    max: {
      hour: 8,
      minute: 0,
    },
  },
  {
    value: '08:00 - 08:30',
    label: '08:00 - 08:30',
    dayjsMin: parseHourStringOnDayjsFormat('08:00'),
    min: {
      hour: 8,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('08:30'),
    max: {
      hour: 8,
      minute: 30,
    },
  },
  {
    value: '08:30 - 09:00',
    label: '08:30 - 09:00',
    dayjsMin: parseHourStringOnDayjsFormat('08:30'),
    min: {
      hour: 8,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('09:00'),
    max: {
      hour: 9,
      minute: 0,
    },
  },
  {
    value: '09:00 - 09:30',
    label: '09:00 - 09:30',
    dayjsMin: parseHourStringOnDayjsFormat('09:00'),
    min: {
      hour: 9,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('09:30'),
    max: {
      hour: 9,
      minute: 30,
    },
  },
  {
    value: '09:30 - 10:00',
    label: '09:30 - 10:00',
    dayjsMin: parseHourStringOnDayjsFormat('09:30'),
    min: {
      hour: 9,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('10:00'),
    max: {
      hour: 10,
      minute: 0,
    },
  },
  {
    value: '10:00 - 10:30',
    label: '10:00 - 10:30',
    dayjsMin: parseHourStringOnDayjsFormat('10:00'),
    min: {
      hour: 10,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('10:30'),
    max: {
      hour: 10,
      minute: 30,
    },
  },
  {
    value: '10:30 - 11:00',
    label: '10:30 - 11:00',
    dayjsMin: parseHourStringOnDayjsFormat('10:30'),
    min: {
      hour: 10,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('11:00'),
    max: {
      hour: 11,
      minute: 0,
    },
  },
  {
    value: '11:00 - 11:30',
    label: '11:00 - 11:30',
    dayjsMin: parseHourStringOnDayjsFormat('11:00'),
    min: {
      hour: 11,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('11:30'),
    max: {
      hour: 11,
      minute: 30,
    },
  },
  {
    value: '11:30 - 12:00',
    label: '11:30 - 12:00',
    dayjsMin: parseHourStringOnDayjsFormat('11:30'),
    min: {
      hour: 11,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('12:00'),
    max: {
      hour: 12,
      minute: 0,
    },
  },
  {
    value: '12:00 - 12:30',
    label: '12:00 - 12:30',
    dayjsMin: parseHourStringOnDayjsFormat('12:00'),
    min: {
      hour: 12,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('12:30'),
    max: {
      hour: 12,
      minute: 30,
    },
  },
  {
    value: '12:30 - 13:00',
    label: '12:30 - 13:00',
    dayjsMin: parseHourStringOnDayjsFormat('12:30'),
    min: {
      hour: 12,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('13:00'),
    max: {
      hour: 13,
      minute: 0,
    },
  },
  {
    value: '13:00 - 13:30',
    label: '13:00 - 13:30',
    dayjsMin: parseHourStringOnDayjsFormat('13:00'),
    min: {
      hour: 13,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('13:30'),
    max: {
      hour: 13,
      minute: 30,
    },
  },
  {
    value: '13:30 - 14:00',
    label: '13:30 - 14:00',
    dayjsMin: parseHourStringOnDayjsFormat('13:30'),
    min: {
      hour: 13,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('14:00'),
    max: {
      hour: 14,
      minute: 0,
    },
  },
  {
    value: '14:00 - 14:30',
    label: '14:00 - 14:30',
    dayjsMin: parseHourStringOnDayjsFormat('14:00'),
    min: {
      hour: 14,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('14:30'),
    max: {
      hour: 14,
      minute: 30,
    },
  },
  {
    value: '14:30 - 15:00',
    label: '14:30 - 15:00',
    dayjsMin: parseHourStringOnDayjsFormat('14:30'),
    min: {
      hour: 14,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('15:00'),
    max: {
      hour: 15,
      minute: 0,
    },
  },
  {
    value: '15:00 - 15:30',
    label: '15:00 - 15:30',
    dayjsMin: parseHourStringOnDayjsFormat('15:00'),
    min: {
      hour: 15,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('15:30'),
    max: {
      hour: 15,
      minute: 30,
    },
  },
  {
    value: '15:30 - 16:00',
    label: '15:30 - 16:00',
    dayjsMin: parseHourStringOnDayjsFormat('15:30'),
    min: {
      hour: 15,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('16:00'),
    max: {
      hour: 16,
      minute: 0,
    },
  },
  {
    value: '16:00 - 16:30',
    label: '16:00 - 16:30',
    dayjsMin: parseHourStringOnDayjsFormat('16:00'),
    min: {
      hour: 16,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('16:30'),
    max: {
      hour: 16,
      minute: 30,
    },
  },
  {
    value: '16:30 - 17:00',
    label: '16:30 - 17:00',
    dayjsMin: parseHourStringOnDayjsFormat('16:30'),
    min: {
      hour: 16,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('17:00'),
    max: {
      hour: 17,
      minute: 0,
    },
  },
  {
    value: '17:00 - 17:30',
    label: '17:00 - 17:30',
    dayjsMin: parseHourStringOnDayjsFormat('17:00'),
    min: {
      hour: 17,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('17:30'),
    max: {
      hour: 17,
      minute: 30,
    },
  },
  {
    value: '17:30 - 18:00',
    label: '17:30 - 18:00',
    dayjsMin: parseHourStringOnDayjsFormat('17:30'),
    min: {
      hour: 17,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('18:00'),
    max: {
      hour: 18,
      minute: 0,
    },
  },
  {
    value: '18:00 - 18:30',
    label: '18:00 - 18:30',
    dayjsMin: parseHourStringOnDayjsFormat('18:00'),
    min: {
      hour: 18,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('18:30'),
    max: {
      hour: 18,
      minute: 30,
    },
  },
  {
    value: '18:30 - 19:00',
    label: '18:30 - 19:00',
    dayjsMin: parseHourStringOnDayjsFormat('18:30'),
    min: {
      hour: 18,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('19:00'),
    max: {
      hour: 19,
      minute: 0,
    },
  },
  {
    value: '19:00 - 19:30',
    label: '19:00 - 19:30',
    dayjsMin: parseHourStringOnDayjsFormat('19:00'),
    min: {
      hour: 19,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('19:30'),
    max: {
      hour: 19,
      minute: 30,
    },
  },
  {
    value: '19:30 - 20:00',
    label: '19:30 - 20:00',
    dayjsMin: parseHourStringOnDayjsFormat('19:30'),
    min: {
      hour: 19,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('20:00'),
    max: {
      hour: 20,
      minute: 0,
    },
  },
  {
    value: '20:00 - 20:30',
    label: '20:00 - 20:30',
    dayjsMin: parseHourStringOnDayjsFormat('20:00'),
    min: {
      hour: 20,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('20:30'),
    max: {
      hour: 20,
      minute: 30,
    },
  },
  {
    value: '20:30 - 21:00',
    label: '20:30 - 21:00',
    dayjsMin: parseHourStringOnDayjsFormat('20:30'),
    min: {
      hour: 20,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('21:00'),
    max: {
      hour: 21,
      minute: 0,
    },
  },
  {
    value: '21:00 - 21:30',
    label: '21:00 - 21:30',
    dayjsMin: parseHourStringOnDayjsFormat('21:00'),
    min: {
      hour: 21,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('21:30'),
    max: {
      hour: 21,
      minute: 30,
    },
  },
  {
    value: '21:30 - 22:00',
    label: '21:30 - 22:00',
    dayjsMin: parseHourStringOnDayjsFormat('21:30'),
    min: {
      hour: 21,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('22:00'),
    max: {
      hour: 22,
      minute: 0,
    },
  },
  {
    value: '22:00 - 22:30',
    label: '22:00 - 22:30',
    dayjsMin: parseHourStringOnDayjsFormat('22:00'),
    min: {
      hour: 22,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('22:30'),
    max: {
      hour: 22,
      minute: 30,
    },
  },
  {
    value: '22:30 - 23:00',
    label: '22:30 - 23:00',
    dayjsMin: parseHourStringOnDayjsFormat('22:30'),
    min: {
      hour: 22,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('23:00'),
    max: {
      hour: 23,
      minute: 0,
    },
  },
  {
    value: '23:00 - 23:30',
    label: '23:00 - 23:30',
    dayjsMin: parseHourStringOnDayjsFormat('23:00'),
    min: {
      hour: 23,
      minute: 0,
    },
    dayjsMax: parseHourStringOnDayjsFormat('23:30'),
    max: {
      hour: 23,
      minute: 30,
    },
  },
  {
    value: '23:30 - 00:00',
    label: '23:30 - 00:00',
    dayjsMin: parseHourStringOnDayjsFormat('23:30'),
    min: {
      hour: 23,
      minute: 30,
    },
    dayjsMax: parseHourStringOnDayjsFormat('00:00'),
    max: {
      hour: 0,
      minute: 0,
    },
  },
];
