import styled, { css } from 'styled-components';

export const ShopWindowItem = styled.div`
  display: flex;
  max-width: 120px;
  flex-direction: column;
  ${({ simpleModel }) => simpleModel && css`
    height: 100%;
    max-width: 140px;
    padding: 5px 10px;
    background: white;
  `}
`;

export const ImageContainer = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  padding: 16px;
  position: relative;
  background: var(--default-gray);
  border-radius: var(--default-border-radius);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  ${({ simpleModel }) => simpleModel && css`
    background: white;
  `}
`;

export const ControlsContainer = styled.div`
  right: 8px;
  bottom: 8px;
  position: absolute;
`;

export const PlusIcon = styled.button`
  width: 38px;
  height: 38px;
  display: flex;
  font-size: 18px;
  background: #FFF;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: var(--default-box-shadow);
  svg, * {
    fill: var(--primary-color);
    stroke: var(--primary-color);
    color: var(--primary-color);
  }
`;

export const Price = styled.span`
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 10px 0 8px 0;
  ${({ simpleModel }) => simpleModel && css`
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0 0 0;
  `}
`;

export const Name = styled.span`
  -webkit-line-clamp: 2;
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  ${({ simpleModel }) => simpleModel && css`
    min-height: 40px;
    font-size: 14px;
  `}
`;

export const Description = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;

  word-break: break-word;
  margin-top: 10px;
  color: var(--text-secondary-color);
`;
