import styled from 'styled-components';

export const CartAskToo = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  background: var(--default-gray);
  margin: 0 calc(-1 * var(--grid-margin-space));
  padding: 10px var(--grid-margin-space) 20px var(--grid-margin-space);
  & > span {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`;

export const Divider = styled.hr`
  border: none;
  margin: 0 calc(-1 * var(--grid-margin-space));
  margin-bottom: 10px;
  border-bottom: 0.5px solid var(--default-gray);
`;
