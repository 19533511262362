import produce from 'immer';
import { uuid } from 'uuidv4';

const INITIAL_STATE = {
  products: [],
  orderOrigin: '',
  isScheduled: null, // is true or false
  scheduledData: {
    deliveryDateTimeEnd: '',
    deliveryDateTimeStart: '',
  },
  delivery: {
    rate: null, // rate is num
    formattedRate: '',
    estimatedTime: '',
    clientDistance: '',
    rateToBeAgreed: false,
  },
  activeCoupon: {
    name: '',
    couponType: '',
    value: 0,
    maxValue: 0,
    minOrderValue: 0,
    quantity: 0,
    availability: [],
    origin: [],
    hour: {
      final: '',
      initial: '',
    },
    date: {
      final: 0,
      initial: 0,
    },
  },
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@cart/PRODUCT_ADD':
      return produce(state, (draft) => {
        draft.products.push({
          ...action.product,
          idOnCart: uuid(),
          amount: action.amount,
        });
      });

    case '@cart/PRODUCT_REMOVE':
      return produce(state, (draft) => {
        const productIndex = draft.products.findIndex((p) => p.id === action.id);

        if (productIndex >= 0) {
          draft.products.splice(productIndex, 1);
        }
      });

    case '@cart/PRODUCT_UPDATE_ITEM': {
      return produce(state, (draft) => {
        const productIndex = draft.products.findIndex((p) => p.id === action.id);

        if (productIndex >= 0) {
          draft.products[productIndex] = { ...action.newProduct, amount: action.amount };
        }
      });
    }

    /* case "@cart/PRODUCT_UPDATE_AMOUNT": {
      if (action.amount <= 0) {
        return state;
      }

      return produce(state, (draft) => {
        const productIndex = draft.products.findIndex((p) => p.id === action.id);

        if (productIndex >= 0) {
          draft[productIndex].amount = Number(action.amount);
        }
      });
    } */

    case '@cart/DELIVERY_ADD':
      return {
        ...state,
        delivery: {
          ...INITIAL_STATE.delivery,
          ...action.delivery,
        },
      };

    case '@cart/DELIVERY_RATE_ADD':
      return {
        ...state,
        delivery: {
          ...state.delivery,
          rate: action.deliveryRate,
          formattedRate: action.formattedRate,
        },
      };

    case '@cart/DELIVERY_RESET':
      return {
        ...state,
        delivery: INITIAL_STATE.delivery,
      };

    case '@cart/COUPON_ADD':
      return {
        ...state,
        activeCoupon: {
          ...INITIAL_STATE.activeCoupon,
          ...action.activeCoupon,
        },
      };

    case '@cart/ORDER_ORIGIN_ADD':
      return {
        ...state,
        orderOrigin: action.orderOrigin,
      };

    case '@cart/IS_SCHEDULED_ADD':
      return {
        ...state,
        isScheduled: action.isScheduled,
      };

    case '@cart/SCHEDULE_ORDER_ADD':
      return {
        ...state,
        scheduledData: action.scheduledData,
      };

    case '@cart/INFOS_FROM_LAST_ORDER_ADD':
      return {
        ...state,
        products: action.lastOrder.items,
        delivery: action.lastOrder.delivery,
        orderOrigin: action.lastOrder.origin,
        activeCoupon: action.lastOrder.coupon,
        isScheduled: action.lastOrder.isScheduled,
        scheduledData: action.lastOrder.scheduledData,
      };

    case '@cart/RESET':
      return INITIAL_STATE;

    default:
      return state;
  }
}
