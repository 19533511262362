import removeAccents from 'remove-accents';

const formatStringForSearch = (string) => {
  const newString = string.toString();
  return removeAccents(newString).toLowerCase();
};

const { format: formatNumberToReal } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const formatRealToNumber = (real) => {
  if (typeof real === 'number') {
    return real;
  }
  let newReal = real;
  newReal = newReal?.replace('R$', '');
  newReal = newReal?.replace(/\./g, '');
  newReal = newReal?.replace(/,/g, '.');

  return parseFloat(newReal);
};

const formatHoursToSeconds = (hours) => hours * 3600;

const formatMinutesToSeconds = (minutes) => minutes * 60;

export {
  formatNumberToReal,
  formatRealToNumber,
  formatHoursToSeconds,
  formatStringForSearch,
  formatMinutesToSeconds,
};
