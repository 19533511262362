import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import UIRequiredBlock from '../RequiredBlock';

const UICustomInputLabel = ({
  labelTitle, isRequired, isValited, customLabelError, className, description,
}) => (
  <S.CustomInputLabel className={className}>
    <div>
      <strong>
        {labelTitle}
      </strong>
      {isRequired && (
        <UIRequiredBlock
          isValited={isValited}
          customLabelError={customLabelError}
        />
      )}
    </div>
    {description && (
      <span>{description}</span>
    )}
  </S.CustomInputLabel>

);

UICustomInputLabel.propTypes = {
  isValited: PropTypes.bool,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  customLabelError: PropTypes.string,
  labelTitle: PropTypes.string.isRequired,
};

UICustomInputLabel.defaultProps = {
  className: '',
  description: '',
  isRequired: false,
  customLabelError: '',
  isValited: false,
};

export default UICustomInputLabel;
