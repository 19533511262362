import { app } from '../../../index';
import { store } from '../../../../store';

export const getAllTables = async () => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();

  const data = await db
    .collection('tables')
    .where('establishmentId', '==', id)
    .orderBy('number')
    .get();

  const tables = data.docs.reduce((acc, doc) => {
    acc.push({ id: doc.id, ...doc.data() });

    return acc;
  }, []);

  return tables;
};

export const getTableByNumber = async (number) => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();

  const data = await db
    .collection('tables')
    .where('establishmentId', '==', id)
    .where('number', '==', number)
    .get();

  const table = { id: data.docs[0]?.id, ...data.docs[0]?.data() };

  return table;
};
