import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { UIButton, UIFixedBottom, UIMenuActions } from '../../../UI';
import * as UserActions from '../../../../store/modules/user/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as AddressesActions from '../../../../store/modules/addresses/actions';
import { getSeparetedStrings } from '../../../../utils/getSeparetedStrings';
import * as S from './styles';
import { getNeighborhoodCityAndUF } from '../../../../utils/addressInfos';
import { FirebaseCustomers } from '../../../../firebase/functions';

const MySwal = withReactContent(Swal);

const CartAddressSelection = ({
  userId,
  userAddresses,
  isOnlineCommand,
  setClientAddress,
  setAddNewAddress,
  setAddressToEdit,
  resetUserAddress,
  reduxSelectedAddress,
  resetCartDeliveryState,
  removeAdressOnAdressGroup,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(reduxSelectedAddress);

  const handleDeleteAddress = (address, indexPosition) => {
    MySwal.fire({
      title: 'Tem certeza que deseja excluir este endereço ?',
      confirmButtonText: 'Sim, excluir o endereço',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.value) {
        removeAdressOnAdressGroup(indexPosition);
        resetUserAddress();
        resetCartDeliveryState();
        setSelectedAddress(null);

        if (isOnlineCommand && userId) {
          FirebaseCustomers.deleteCustomerAddress(userId, address.id);
        }
      }
    });
  };

  return (
    <S.CartAddressSelection>
      <ul>
        {userAddresses.map((address, index) => {
          const {
            id, number, street, referencePoint, complement,
          } = address;
          const neighborhoodCityAndUF = getNeighborhoodCityAndUF(address);

          return (
            <S.Address key={id}>
              <label className="container" htmlFor={`address${index}`}>
                <input
                  type="radio"
                  name="address"
                  id={`address${index}`}
                  onChange={() => setSelectedAddress(address)}
                  defaultChecked={reduxSelectedAddress?.id && reduxSelectedAddress?.id === id}
                />
                <div className="infos">
                  <strong>{`${street}, ${number}`}</strong>
                  <span>
                    {getSeparetedStrings(
                      [complement, referencePoint],
                      '-',
                    )}
                  </span>
                  <span>{neighborhoodCityAndUF}</span>
                </div>
                {/* <Ink /> */}
              </label>
              <div className="edit-button">
                <UIMenuActions
                  actions={[{
                    visible: true,
                    name: 'Editar',
                    iconLeft: <BsPencil />,
                    actionEvent: () => setAddressToEdit({ ...address, indexPosition: index }),
                  },
                  {
                    visible: true,
                    name: 'Deletar',
                    iconLeft: <BsTrash />,
                    actionEvent: () => handleDeleteAddress(address, index),
                  },
                  ]}
                />
              </div>
            </S.Address>
          );
        })}
        <S.NewAddress>
          <label className="container" htmlFor="add-new">
            <input
              type="radio"
              name="address"
              id="add-new"
              onChange={() => setAddNewAddress(true)}
            />
            <div className="infos">
              <span>Adicionar novo endereço</span>
            </div>
            <Ink />
          </label>
        </S.NewAddress>
      </ul>
      <UIFixedBottom>
        <UIButton
          text="Confirmar endereço"
          disabledAppearance={!selectedAddress?.street}
          functionWhenDisable={() => toast.error('Selecione um endereço')}
          onClick={() => {
            setClientAddress(selectedAddress);
          }}
        />
      </UIFixedBottom>
    </S.CartAddressSelection>
  );
};

CartAddressSelection.propTypes = {
  userId: PropTypes.string.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  setClientAddress: PropTypes.func.isRequired,
  setAddNewAddress: PropTypes.func.isRequired,
  setAddressToEdit: PropTypes.func.isRequired,
  resetUserAddress: PropTypes.func.isRequired,
  resetCartDeliveryState: PropTypes.func.isRequired,
  removeAdressOnAdressGroup: PropTypes.func.isRequired,
  reduxSelectedAddress: PropTypes.objectOf(PropTypes.node).isRequired,
  userAddresses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.node)).isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
  userAddresses: state.addresses,
  reduxSelectedAddress: state.user.address,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions, ...AddressesActions, ...CartActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartAddressSelection);
