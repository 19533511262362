import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoChatboxEllipsesSharp } from 'react-icons/io5';
import './ProductObservation.scss';

const ProductObservation = ({ observation, setObservation, allowObservationOnProducts }) => {
  const maxLength = 140;

  if (!allowObservationOnProducts) {
    return (
      <div className="ProductObservation--empty-space" />
    );
  }

  return (
    <div className="ProductObservation">
      <div className="ProductObservation__header">
        <span className="header__title">
          <IoChatboxEllipsesSharp className="title__icon" />
          Alguma observação ?
        </span>
        <span className="header__length">
          {`${observation.length}/${maxLength}`}
        </span>
      </div>
      <textarea
        value={observation}
        maxLength={maxLength}
        className="ProductObservation__textarea"
        placeholder="Ex: Tirar ervilha, ponto da carne, molho a parte..."
        onChange={(e) => {
          if (observation.length + 1 <= maxLength) {
            setObservation(e.target.value);
          } else if (e.target.value.length < maxLength) {
            setObservation(e.target.value);
          }
        }}
      />
    </div>
  );
};

ProductObservation.propTypes = {
  observation: PropTypes.string.isRequired,
  setObservation: PropTypes.func.isRequired,
  allowObservationOnProducts: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  allowObservationOnProducts: state.establishment.allowObservationOnProducts,
});

export default connect(mapStateToProps, null)(ProductObservation);
