import { useSelector } from 'react-redux';

function useMyCustomHook() {
  const userName = useSelector((state) => state.user.name);
  const userPhone = useSelector((state) => state.user.phone);
  const userTable = useSelector((state) => state.user.table);
  const userAddress = useSelector((state) => state.user.address);
  const orderOrigin = useSelector((state) => state.cart.orderOrigin);
  const userPaymentMethod = useSelector((state) => state.user.paymentMethod);
  const userDeliveryMethod = useSelector((state) => state.user.deliveryMethod);
  const isOnlineCommand = useSelector((state) => state.establishment.isOnlineCommand);
  const deliveryRateState = useSelector((state) => state.cart.delivery.rate);

  const deliveryMethodIsDelivery = userDeliveryMethod.label === 'delivery';

  const handleCheckIfHaveAllRequiredInfos = () => {
    const hasAllReqInfosForOnlineOrder = () => {
      if (isOnlineCommand) {
        if (
          orderOrigin
          && !userAddress.addressTooFar
          && !userAddress.addressNotFound
        ) {
          if (userDeliveryMethod.name && deliveryMethodIsDelivery && userAddress.street) {
            // !== null because can be 0
            if (deliveryRateState !== null) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      }

      if (
        userName
        && userPhone
        && !userAddress.addressTooFar
        && !userAddress.addressNotFound
        && [13, 14].includes(userPhone?.length)
        && ((deliveryMethodIsDelivery && userAddress.street)
          || (userDeliveryMethod.name && !deliveryMethodIsDelivery))
        && userPaymentMethod.id
      ) {
        return true;
      }

      return false;
    };

    const hasAllReqInfosForTableOrder = () => {
      if (userTable) {
        return true;
      }

      return false;
    };

    const hasAllReqInfosForCounterOrder = () => {
      if (isOnlineCommand) {
        if (
          orderOrigin
          && !userAddress.addressTooFar
          && !userAddress.addressNotFound
        ) {
          if (userDeliveryMethod.name && deliveryMethodIsDelivery && userAddress.street) {
            // !== null because can be 0
            if (deliveryRateState !== null) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      }

      if (
        userName
        && !userAddress.addressTooFar
        && !userAddress.addressNotFound
        && ((deliveryMethodIsDelivery && userAddress.street)
          || (userDeliveryMethod.name && !deliveryMethodIsDelivery))
        && userPaymentMethod.id
        && ((deliveryMethodIsDelivery
          && userPhone
          && [13, 14].includes(userPhone?.length))
          || !deliveryMethodIsDelivery)
      ) {
        return true;
      }

      return false;
    };

    const hasAllReqInfosForPhoneOrder = () => {
      if (isOnlineCommand) {
        if (
          orderOrigin
          && !userAddress.addressTooFar
          && !userAddress.addressNotFound
        ) {
          if (userDeliveryMethod.name && deliveryMethodIsDelivery && userAddress.street) {
            // !== null because can be 0
            if (deliveryRateState !== null) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      }

      if (
        userName
        && userPhone
        && !userAddress.addressTooFar
        && !userAddress.addressNotFound
        && [13, 14].includes(userPhone?.length)
        && ((deliveryMethodIsDelivery && userAddress.street)
          || (userDeliveryMethod.name && !deliveryMethodIsDelivery))
        && userPaymentMethod.id
      ) {
        return true;
      }

      return false;
    };

    if (orderOrigin === 'online') {
      return hasAllReqInfosForOnlineOrder();
    }

    if (orderOrigin === 'table') {
      return hasAllReqInfosForTableOrder();
    }

    if (orderOrigin === 'counter') {
      return hasAllReqInfosForCounterOrder();
    }

    if (orderOrigin === 'phone') {
      return hasAllReqInfosForPhoneOrder();
    }

    return false;
  };

  return handleCheckIfHaveAllRequiredInfos();
}

export default useMyCustomHook;
