import { app } from '../../../index';
import { getTableByNumber } from '../get';

export const updateTableOrders = async (userTable, orderId) => {
  const db = app.firestore();
  const tableSelected = await getTableByNumber(userTable);

  if (tableSelected) {
    await db
      .collection('tables')
      .doc(tableSelected.id)
      .set({ activeOrders: [...tableSelected.activeOrders, orderId] }, { merge: true });

    return true;
  }

  return false;
};
