import React from 'react';
import { DevelopBy } from '../../components/DevelopedBy';
import { Page404Infos } from '../../components/Page404/Infos';

export const ScreensPage404 = () => (
  <>
    <DevelopBy />
    <Page404Infos />
  </>
);
