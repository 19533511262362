import { app } from '../../../index';
import { store } from '../../../../store';

export const addTable = async (data) => {
  try {
    const db = app.firestore();
    const { establishment: { id } } = store.getState();

    const tableData = {
      ...data,
      activeOrders: [],
      establishmentId: id,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
    };

    const newTable = await db.collection('tables').add(tableData);

    return { id: newTable.id, ...tableData };
  } catch (err) {
    return false;
  }
};
