import React, { useCallback, useState } from 'react';
import './ProductSelectionGroup.scss';
import PropTypes from 'prop-types';
import { Sticky, StickyContainer } from 'react-sticky';
import { Element, scroller } from 'react-scroll';
import ProductSelectionGroupOption from './Option';
import ProductSelectionGroupHeader from './Header';

const ProductSelectionGroup = ({
  header,
  options,
  position,
  notSticky,
  simpleStyle,
  dontShowThePlusIcon,
  setAllSelectedAdditionals,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [numOfSelectedOptions, setNumOfSelectedOptions] = useState(0);

  const handleChangeSelectedOption = useCallback(
    (newOptions) => {
      setSelectedOptions(newOptions);
      setAllSelectedAdditionals((prevState) => {
        const newPrevState = prevState.filter((option) => option.groupName !== header.name);
        return [...newPrevState, ...newOptions];
      });

      const numberOfSelectedOptions = newOptions.reduce(
        (acc, { quantity: optionQuantity }) => acc + optionQuantity,
        0,
      );

      if (
        numberOfSelectedOptions === header.max
      ) {
        if (position > 0) {
          scroller.scrollTo(`additional-${position + 1}`, {
            delay: 100,
            smooth: true,
            duration: 1000,
          });
        }
      }

      setNumOfSelectedOptions(numberOfSelectedOptions);
    },
    [position, header.name, header.max, setAllSelectedAdditionals],
  );

  return (
    <StickyContainer id="testee">
      <Element name={`additional-${position}`} className="ProductSelectionGroup">
        <Sticky relative={false}>
          {({ style }) => (
            <div style={!notSticky ? { ...style, zIndex: 10 } : { zIndex: 10 }} className="cont-sticky">
              <ProductSelectionGroupHeader
                header={header}
                simpleStyle={simpleStyle}
                numOfSelectedOptions={numOfSelectedOptions}
              />
            </div>
          )}
        </Sticky>

        <div className="ProductSelectionGroup__options">
          {options.map((option) => {
            if (!option.paused) {
              return (
                <ProductSelectionGroupOption
                  key={option.id}
                  option={option}
                  header={header}
                  simpleStyle={simpleStyle}
                  selectedOptions={selectedOptions}
                  dontShowThePlusIcon={dontShowThePlusIcon}
                  numOfSelectedOptions={numOfSelectedOptions}
                  setSelectedOptions={handleChangeSelectedOption}
                  toggleSelections={header.min === 1 && header.max === 1}
                  chargeForTheMostExpensive={header.chargeForTheMostExpensive || false}
                />
              );
            }
            return null;
          })}
        </div>
      </Element>
    </StickyContainer>
  );
};

ProductSelectionGroup.propTypes = {
  position: PropTypes.number,
  notSticky: PropTypes.bool,
  simpleStyle: PropTypes.bool,
  header: PropTypes.shape({
    chargeForTheMostExpensive: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    optionsLength: PropTypes.number,
    repeatOptions: PropTypes.bool,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    paused: PropTypes.bool,
    price: PropTypes.number,
  })).isRequired,
  dontShowThePlusIcon: PropTypes.bool,
  setAllSelectedAdditionals: PropTypes.func.isRequired,
};

ProductSelectionGroup.defaultProps = {
  position: 0,
  notSticky: false,
  simpleStyle: false,
  dontShowThePlusIcon: false,
};

export default ProductSelectionGroup;
