import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiZoomInLine } from 'react-icons/ri';
import { UIModal } from '../../UI';
import * as S from './styles';

const ProductHeader = ({
  productName, productImage, productDescription,
}) => {
  const [showModal, setShowModal] = useState(false);

  if (!productImage) return <div style={{ marginBottom: 27 }} />;

  return (
    <>
      <S.ProductHeader type="button" onClick={() => setShowModal(true)}>
        <S.ProductHeaderImage className="image-container">
          <img src={productImage} alt={productName} />
        </S.ProductHeaderImage>
        <S.ProductHeaderZoomIcon>
          <RiZoomInLine size={20} />
        </S.ProductHeaderZoomIcon>
      </S.ProductHeader>

      <UIModal
        noBottomPadding
        noHorizontalPadding
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={productName}
      >
        <S.ModalDarkContainer>
          <img src={productImage} alt={productName} />
          <div>
            <p>{productName}</p>
            <p>{productDescription}</p>
          </div>
        </S.ModalDarkContainer>
      </UIModal>
    </>
  );
};

ProductHeader.propTypes = {
  productName: PropTypes.string.isRequired,
  productImage: PropTypes.string.isRequired,
  productDescription: PropTypes.string.isRequired,
};

export default ProductHeader;
