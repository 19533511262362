import { store } from '../../../store';
import { formatRealToNumber } from '../../../utils/formatting';
import { app } from '../../index';
import { getCustomerByPhone, getCustomerById } from './get';

export const postCustomer = async ({
  name, phone, addresses, order,
}) => {
  try {
    const customerByPhone = await getCustomerByPhone(phone);
    if (customerByPhone?.id) {
      throw Error('This phone number is already been used');
    }

    const db = app.firestore();
    const { establishment: { id } } = store.getState();
    const hasAddressesToRegister = addresses?.length >= 1;

    const customerInfos = {
      name,
      phone,
      orders: [],
      addresses: [],
      totalSpent: 0,
      lastOrderDate: '',
      firstOrderDate: '',
      establishmentId: id,
    };

    if (hasAddressesToRegister) {
      customerInfos.addresses = addresses;
    }

    if (order?.id) {
      const ISODateNow = new Date().toISOString();
      customerInfos.lastOrderDate = ISODateNow;
      customerInfos.firstOrderDate = ISODateNow;
      customerInfos.orders.push(order.id);
      customerInfos.totalSpent = formatRealToNumber(order.cartTotal);

      if (!hasAddressesToRegister && customerInfos?.deliveryAddress?.street) {
        customerInfos.addresses = [customerInfos.deliveryAddress];
      }
    }

    const newCustomer = await db.collection('customers').add(customerInfos);

    return newCustomer.id;
  } catch (err) {
    throw Error(err);
  }
};

export const postAddCustomerAddress = async (customerId, address) => {
  try {
    const customer = await getCustomerById(customerId);

    if (customer?.id) {
      const db = app.firestore();
      await db
        .collection('customers')
        .doc(customerId)
        .set({ addresses: [...customer.addresses, address] }, { merge: true });

      return true;
    }
    return false;
  } catch (err) {
    throw Error(err);
  }
};
