export function addEstablishment(establishment) {
  return {
    type: '@establisment/ESTABLISHMENT_ADD',
    establishment,
  };
}

export function resetEstablishment() {
  return {
    type: '@establisment/RESET',
  };
}
