import React, { useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useDetectClickOutside } from 'react-detect-click-outside';
import * as S from './styles';

export const UIMenuActions = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsOpen(false);
    },
  });

  return (
    <S.UIMenuActions ref={ref}>
      {/* eslint-disable-next-line */}
      <button type="button" onClick={() => setIsOpen((prevState) => !prevState)}>
        <FiMoreVertical size={20} />
      </button>
      {isOpen && (
        <div className="actions">
          {actions.map(({
            name, visible, actionEvent, iconLeft,
          }) => {
            if (!visible) return null;

            return (
              <button
                type="button"
                onClick={() => {
                  actionEvent();
                  setIsOpen((prevState) => !prevState);
                }}
              >
                {iconLeft && iconLeft}
                {name}
              </button>
            );
          })}
        </div>
      )}
    </S.UIMenuActions>
  );
};

UIMenuActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    iconLeft: PropTypes.node.isRequired,
    actionEvent: PropTypes.func.isRequired,
  })).isRequired,
};

UIMenuActions.defaultProps = {
};
