import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getOrderDeliveryTypeIcon } from '../../../../utils/getOrderDeliveryTypeIcon';
import { getSeparetedStrings } from '../../../../utils/getSeparetedStrings';

import { UIModal } from '../../../UI';
import * as UserActions from '../../../../store/modules/user/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import * as S from './styles';

const DeliveryTypeChooseModal = ({
  showModal,
  setShowModal,
  addDelivery,
  activeHourData,
  resetUserAddress,
  addUserDeliveryMethod,
  establishmentDeliveryOptions,
}) => (
  <UIModal
    showModal={showModal}
    setShowModal={setShowModal}
    modalTitle="Modo de entrega"
  >
    {establishmentDeliveryOptions.map(({
      name, label, minTime, maxTime,
    }) => {
      if (activeHourData?.origins && !activeHourData?.origins[label]) return null;
      const formattedEstimatedTime = minTime ? `${getSeparetedStrings([minTime, maxTime], '-')}min` : '';

      return (
        <S.Option
          key={name}
          noRightIcon
          icon={getOrderDeliveryTypeIcon(label)}
          firstElement={(
            <strong>
              {formattedEstimatedTime ? `${name} (${formattedEstimatedTime}min)` : name}
            </strong>
            )}
          onClick={() => {
            addUserDeliveryMethod({
              name,
              label,
            });

            if (label === 'local-pickup' && formattedEstimatedTime) {
              resetUserAddress();
              addDelivery({
                estimatedTime: formattedEstimatedTime,
              });
            }

            setShowModal(false);
          }}
        />
      );
    })}
  </UIModal>
);

DeliveryTypeChooseModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  addDelivery: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  resetUserAddress: PropTypes.func.isRequired,
  addUserDeliveryMethod: PropTypes.func.isRequired,
  establishmentDeliveryOptions: PropTypes.arrayOf(PropTypes.shape({
    minTime: PropTypes.number,
    maxTime: PropTypes.number,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  activeHourData: PropTypes.shape(
    {
      endHour: PropTypes.string.isRequired,
      startHour: PropTypes.string.isRequired,
      origins: {
        delivery: PropTypes.bool.isRequired,
        'local-pickup': PropTypes.bool.isRequired,
      },
    },
  ).isRequired,
};

const mapStateToProps = (state) => ({
  activeHourData: state.establishment.activeHourData,
  establishmentDeliveryOptions: state.establishment.deliveryOptions,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryTypeChooseModal);
