import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GiTable } from 'react-icons/gi';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  UIModal, UIButtonToEdit, UILoader, UIInput, UIFixedBottom, UIButton,
} from '../../../../UI';
import * as UserActions from '../../../../../store/modules/user/actions';
import * as CartActions from '../../../../../store/modules/cart/actions';
import { FirebaseTables } from '../../../../../firebase/functions';
import * as S from './styles';

const CartFormTableChooseModal = ({
  showModal,
  setShowModal,
  addUserTable,
}) => {
  const [loading, setLoading] = useState(false);
  const [newTable, setNewTable] = useState(null);
  const [allTables, setAllTables] = useState([]);
  const [addNewTable, setAddNewTable] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resAllTables = await FirebaseTables.getAllTables();

      setAllTables(resAllTables);
      setLoading(false);
    })();
  }, []);

  const handleAddNewTable = async (tableNumber) => {
    const resNewTable = await FirebaseTables.addTable({ number: Number(tableNumber) });

    if (resNewTable) {
      addUserTable(resNewTable.number);
      setShowModal(false);
    }
  };

  const handleSaveTableSelection = (tableNumber) => {
    addUserTable(tableNumber);
    setShowModal(false);
  };

  return (
    <>
      <UIModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={addNewTable ? 'Adicione uma nova mesa' : 'Escolha a mesa'}
      >
        {loading ? (
          <div style={{ display: 'flex' }}>
            <UILoader />
          </div>
        ) : (
          <>
            {!addNewTable && (
              <>
                {allTables.map(({
                  number,
                }) => (
                  <UIButtonToEdit
                    key={number}
                    noRightIcon
                    className="mb-20"
                    icon={<GiTable />}
                    firstElement={(
                      <strong>
                        Mesa
                        {' '}
                        {number}
                      </strong>
                  )}
                    onClick={() => {
                      handleSaveTableSelection(number);
                    }}
                  />
                ))}

                <S.AddNewtableContainer>
                  <small>Não encontrou a mesa que estava procurando ?</small>
                  <UIButton transparent text="Adicionar uma nova mesa" onClick={() => setAddNewTable(true)} />
                </S.AddNewtableContainer>
              </>
            )}

            {addNewTable && (
              <>
                <UIInput
                  type="number"
                  value={newTable}
                  placeholder="Ex: 1"
                  setValue={setNewTable}
                  label="Número da nova mesa"
                />

                <UIFixedBottom>
                  <UIButton
                    text="Adicionar mesa"
                    disabledAppearance={!newTable}
                    onClick={() => {
                      handleAddNewTable(newTable);
                    }}
                  />
                </UIFixedBottom>
              </>
            )}
          </>
        )}
      </UIModal>
    </>
  );
};

CartFormTableChooseModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  addUserTable: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  establishmentDeliveryOptions: state.establishment.deliveryOptions,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartFormTableChooseModal);
