import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  button:first-of-type {
    margin-right: 10px;
  }
`;
