import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as S from './styles';
import { formatNumberToReal } from '../../../utils/formatting';
import { getIfCartProductsIsBiggerThanMinOrder } from '../../../store/selectors';

const CartMinOrder = ({ minOrder, cartProductsBiggerThanMinOrder, isOnlineCommand }) => {
  if (cartProductsBiggerThanMinOrder) return null;

  return (
    <S.CartMinOrder bordered className={isOnlineCommand ? 'mb-20' : ''}>
      <span>
        O pedido mínimo para entrega desse restaurante é
        {' '}
        {formatNumberToReal(minOrder)}
        , sem contar com a taxa de entrega.
      </span>
    </S.CartMinOrder>
  );
};

CartMinOrder.propTypes = {
  minOrder: PropTypes.number.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  cartProductsBiggerThanMinOrder: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  minOrder: state.establishment.minOrder,
  isOnlineCommand: state.establishment.isOnlineCommand,
  cartProductsBiggerThanMinOrder: getIfCartProductsIsBiggerThanMinOrder(state),
});

export default connect(mapStateToProps, null)(CartMinOrder);
