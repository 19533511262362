import { getAddressInfosInLine } from './addressInfos';

export function getDistanceFromAdresses({ origin, destiny }) {
  return new Promise((resolve, reject) => {
    const { google } = window;
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
      travelMode: 'DRIVING',
      origins: [getAddressInfosInLine(origin)],
      destinations: [getAddressInfosInLine(destiny)],
    }, (response, status) => {
      if (status === 'OK') {
        const result = response.rows[0].elements[0];
        if (result.status === 'OK') {
          resolve(result.distance);
        } else {
          reject(result.status);
        }
      } else {
        reject(status);
      }
    });
  });
}
