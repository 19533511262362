import React from 'react';
import GlobalStyle from './styles/global.js';
import ScreensRoutes from './screens/Routes/index.jsx';
import './styles/styles.css';

export const App = () => {
  return (
    <>
      <ScreensRoutes />
      <GlobalStyle />
    </>
  );
};
