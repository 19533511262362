import styled, { css } from 'styled-components';

export const UICard = styled.div`
  display: flex;
  padding: 16px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${({ color }) => css`
    background: ${color || 'var(--primary-color-opacity)'};
  `}
  ${({ bordered }) => bordered && css`
    border-radius: 4px;
  `}
`;
