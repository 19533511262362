import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as UserActions from '../../../../store/modules/user/actions';
import * as AddressesActions from '../../../../store/modules/addresses/actions';
import { FirebaseCustomers } from '../../../../firebase/functions';
import AddressForm from '../../../AddressForm';

const CartAddressForm = ({
  userId,
  addressToEdit,
  setClientAddress,
  setAddressToEdit,
  selectedNeighborhood,
  addAdressOnAdressGroup,
  editAdressOnAdressGroup,
}) => {
  const handleOnSubmit = async (data) => {
    if (addressToEdit?.street) {
      setClientAddress(data);
      editAdressOnAdressGroup(data, addressToEdit?.indexPosition);
      setAddressToEdit({});
      if (userId) {
        await FirebaseCustomers.updateCustomerAddress(
          userId,
          addressToEdit.id,
          data,
        );
      }
      return;
    }

    setClientAddress(data);
    addAdressOnAdressGroup(data);

    if (userId) {
      await FirebaseCustomers.postAddCustomerAddress(
        userId,
        data,
      );
    }
  };

  return (
    <AddressForm
      addressToEdit={addressToEdit}
      handleOnSubmit={handleOnSubmit}
      selectedNeighborhood={selectedNeighborhood}
    />
  );
};

CartAddressForm.propTypes = {
  addressToEdit: PropTypes.any,
  userId: PropTypes.string.isRequired,
  selectedNeighborhood: PropTypes.string,
  setClientAddress: PropTypes.func.isRequired,
  setAddressToEdit: PropTypes.func.isRequired,
  addAdressOnAdressGroup: PropTypes.func.isRequired,
  editAdressOnAdressGroup: PropTypes.func.isRequired,
};

CartAddressForm.defaultProps = {
  selectedNeighborhood: '',
  addressToEdit: {},
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions, ...AddressesActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartAddressForm);
