import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as S from './styles';
import { DevelopBy } from '../../DevelopedBy';
import EstablishmentBrand from '../../EstablishmentBrand';

const HomeHeader = ({
  name, brand, banner,
}) => (
  <S.HomeHeader>
    <DevelopBy />

    <S.HomeHeaderBanner>
      {banner && (
        <img src={banner} alt={`${name} banner`} />
      )}
    </S.HomeHeaderBanner>

    {brand && (
      <EstablishmentBrand
        size={95}
        smallSize={115}
        margin="-70px 0 0 0"
      />
    )}
  </S.HomeHeader>
);

HomeHeader.propTypes = {
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  banner: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  name: state.establishment.name,
  brand: state.establishment.brand,
  banner: state.establishment.banner,
});

export default connect(mapStateToProps, null)(HomeHeader);
