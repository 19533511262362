import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeliveryTypeChooseModal from './ChooseModal';
import { getOrderDeliveryTypeIcon } from '../../../utils/getOrderDeliveryTypeIcon';
import {
  getDeliveryRate,
  getFormattedDeliveryRate,
  getFormattedCouponValue,
} from '../../../store/selectors';
import {
  UIPrice,
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../UI';

const CartDeliveryType = ({
  deliveryRate,
  cartDelivery,
  isOnlineCommand,
  informationalUse,
  userDeliveryMethod,
  formattedCouponValue,
  formattedDeliveryRate,
}) => {
  const [showModal, setShowModal] = useState(false);

  if (!userDeliveryMethod?.name && informationalUse) {
    return null;
  }

  return (
    <>
      <UICustomInputLabel
        labelTitle="Modo de entrega"
        isValited={userDeliveryMethod?.name}
        isRequired={!isOnlineCommand && !informationalUse}
      />
      {userDeliveryMethod?.name ? (
        <UIButtonToEdit
          className="mb-20"
          disabled={informationalUse}
          noRightIcon={informationalUse}
          onClick={() => setShowModal(true)}
          icon={getOrderDeliveryTypeIcon(userDeliveryMethod.label)}
          firstElement={(
            <strong className="infos__title">
              {`${userDeliveryMethod.name}`}
              {userDeliveryMethod.label === 'delivery' ? (
                <>
                  {cartDelivery.estimatedTime && deliveryRate !== null ? ` (${cartDelivery.estimatedTime})` : ''}
                </>
              ) : (
                <>
                  {cartDelivery.estimatedTime ? ` (${cartDelivery.estimatedTime})` : ''}
                </>
              )}
            </strong>
          )}
          secondElement={(
            <>
              {userDeliveryMethod.label === 'delivery'
              && deliveryRate > 0
              && formattedDeliveryRate && (
                <div>
                  <UIPrice
                    smallType
                    price={deliveryRate}
                    free={formattedCouponValue === 'FRETE GRÁTIS'}
                  />
                </div>
              )}

              {userDeliveryMethod.label === 'delivery' && cartDelivery.rateToBeAgreed && (
                <small>
                  Taxa a combinar
                </small>
              )}
            </>
          )}
        />
      ) : (
        <UIButton
          outline
          className="mb-20"
          text="Modo de entrega"
          onClick={() => setShowModal(true)}
        />
      )}
      <DeliveryTypeChooseModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

CartDeliveryType.propTypes = {
  deliveryRate: PropTypes.number,
  formattedCouponValue: PropTypes.string,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  formattedDeliveryRate: PropTypes.string.isRequired,
  cartDelivery: PropTypes.shape({
    clientDistance: PropTypes.string,
    estimatedTime: PropTypes.string,
    formattedRate: PropTypes.string,
    rate: PropTypes.node,
    rateToBeAgreed: PropTypes.bool,
  }).isRequired,
  userDeliveryMethod: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

CartDeliveryType.defaultProps = {
  deliveryRate: null,
  formattedCouponValue: null,
};

const mapStateToProps = (state) => ({
  cartDelivery: state.cart.delivery,
  deliveryRate: getDeliveryRate(state),
  userDeliveryMethod: state.user.deliveryMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
  formattedCouponValue: getFormattedCouponValue(state),
  formattedDeliveryRate: getFormattedDeliveryRate(state),
});

export default connect(mapStateToProps, null)(CartDeliveryType);
