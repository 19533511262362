import styled, { css } from 'styled-components';

export const PriceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .text-price-from {
    font-size: 12px;
    margin-right: 5px;
  }
`;

export const MenuItemPrice = styled.div`
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
  color: var(--text-color);

  

  .promotional-price {
    font-weight: 500;
    margin-right: 5px;
    color: var(--promotional-color);
  }

  ${({ hasPromotionalPrice }) => hasPromotionalPrice
    && css`
      .price {
        font-size: 12px;
        text-decoration: line-through;
        color: var(--text-secondary-color);
      }
    `}
`;
