import React from 'react';
import PropTypes from 'prop-types';

import './MenuItem.scss';
import { Link, useParams } from 'react-router-dom';
import { MenuItemPrice } from './Price';

const MenuItem = ({
  item,
}) => {
  const { establishment } = useParams();
  const {
    id, image, description, name,
  } = item;
  const getCustomRoute = () => `/${establishment}/produtos/${id}`;

  return (
    <Link to={getCustomRoute()} className="MenuItem">
      <div className="MenuItem__infos">
        <h2 className="infos__name">{name}</h2>
        <p className="infos__description">
          {description}
        </p>

        <MenuItemPrice item={item} />
      </div>

      {image && (
        <img className="MenuItem__image" src={image} alt="Americana" />
      )}
    </Link>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    menu: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    amount: PropTypes.number,
    description: PropTypes.string,
    observation: PropTypes.string,
    priceIsNotFixed: PropTypes.bool,
    promotionalPrice: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.string),
    variations: PropTypes.arrayOf(PropTypes.object),
    priceType: PropTypes.oneOf(['single', 'multiple']),
    selectedVariations: PropTypes.objectOf(PropTypes.object),
  }),
};

MenuItem.defaultProps = {
  item: {},
};

export default MenuItem;
