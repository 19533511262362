import React from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import creditoDiners from '../assets/images/credito-diners.png';
import creditoElo from '../assets/images/credito-elo.png';
import creditoAmex from '../assets/images/credito-amex.png';
import creditoBanricompras from '../assets/images/credito-banricompras.png';
import creditoGoodCard from '../assets/images/credito-good-card.png';
import creditoMastercard from '../assets/images/credito-mastercard.png';
import creditoVerdecard from '../assets/images/credito-verdecard.png';
import creditoHipercard from '../assets/images/credito-hipercard.png';
import creditoNugo from '../assets/images/credito-nugo.png';
import creditoVisa from '../assets/images/credito-visa.png';
import sodexo from '../assets/images/vale-refeicao-sodexo.png';
import alelo from '../assets/images/vale-refeicao-alelo.png';
import ticket from '../assets/images/vale-refeicao-ticket.png';
import verocard from '../assets/images/vale-refeicao-verocard.png';
import nutricard from '../assets/images/vale-refeicao-nutricard.png';
import vr from '../assets/images/vale-refeicao-vr.png';
import ben from '../assets/images/vale-refeicao-ben-refeicao.png';
import valecard from '../assets/images/vale-refeicao-valecard.png';
import greencard from '../assets/images/vale-refeicao-greencard.png';
import { ReactComponent as PixSvg } from '../assets/svgs/pix.svg';
import { UIFlagImage } from '../components/UI';

export const getCardFlagsIcon = (flagName) => {
  if (flagName === 'Diners Club') {
    return <UIFlagImage src={creditoDiners} alt="Diners Club" />;
  }

  if (flagName === 'Elo') {
    return <UIFlagImage src={creditoElo} alt="Elo" />;
  }

  if (flagName === 'American Express') {
    return <UIFlagImage src={creditoAmex} alt="American Express" />;
  }

  if (flagName === 'Banricompras') {
    return <UIFlagImage src={creditoBanricompras} alt="Banricompras" />;
  }
  if (flagName === 'Goodcard') {
    return <UIFlagImage src={creditoGoodCard} alt="Goodcard" />;
  }

  if (flagName === 'Mastercard') {
    return <UIFlagImage src={creditoMastercard} alt="Mastercard" />;
  }

  if (flagName === 'Verdecard') {
    return <UIFlagImage src={creditoVerdecard} alt="Verdecard" />;
  }

  if (flagName === 'Hipercard') {
    return <UIFlagImage src={creditoHipercard} alt="Hipercard" />;
  }

  if (flagName === 'Nugo') {
    return <UIFlagImage src={creditoNugo} alt="Nugo" />;
  }

  if (flagName === 'Visa') {
    return <UIFlagImage src={creditoVisa} alt="Visa" />;
  }

  if (flagName === 'Sodexo') {
    return <UIFlagImage src={sodexo} alt="Sodexo" />;
  }

  if (flagName === 'Alelo Refeição/Visa Vale') {
    return <UIFlagImage src={alelo} alt="Alelo Refeição/Visa Vale" />;
  }

  if (flagName === 'Ticket Restaurante') {
    return <UIFlagImage src={ticket} alt="Ticket Restaurante" />;
  }

  if (flagName === 'Verocard') {
    return <UIFlagImage src={verocard} alt="Verocard" />;
  }

  if (flagName === 'Nutricard Refeição e Alimentação') {
    return (
      <UIFlagImage src={nutricard} alt="Nutricard Refeição e Alimentação" />
    );
  }

  if (flagName === 'VR Smart') {
    return <UIFlagImage src={vr} alt="VR Smart" />;
  }

  if (flagName === 'Ben Visa Refeição') {
    return <UIFlagImage src={ben} alt="Ben Visa Refeição" />;
  }

  if (flagName === 'Vale Card') {
    return <UIFlagImage src={valecard} alt="Vale Card" />;
  }

  if (flagName === 'Green Card') {
    return <UIFlagImage src={greencard} alt="Green Card" />;
  }

  return <BsCreditCard />;
};

export const getPaymentIcon = (paymentMethod) => {
  if (paymentMethod?.flag?.name) {
    return getCardFlagsIcon(paymentMethod.flag.name);
  }

  if (paymentMethod.id === 'dinheiro') {
    return <FaRegMoneyBillAlt />;
  }

  if (paymentMethod.id === 'pix') {
    return <PixSvg style={{ width: 20, height: 20 }} />;
  }

  return <BsCreditCard />;
};
