import { app } from '../../index';
import { getCustomerById } from './get';

export const deleteCustomerAddress = async (customerId, addressId) => {
  const customer = await getCustomerById(customerId);

  if (customer?.id) {
    const filteredAddresses = customer.addresses.filter((address) => address.id !== addressId);
    const db = app.firestore();
    await db
      .collection('customers')
      .doc(customerId)
      .set({ addresses: filteredAddresses }, { merge: true });

    return true;
  }

  return false;
};
