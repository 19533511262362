import { orderBySmallestToLargest } from '../../../../utils/orderBy';
import { app } from '../../../index';
import { store } from '../../../../store';

export const getAdditionalsByIds = async (additionalIds) => {
  const db = app.firestore();
  const { establishment: { id: establishmentId } } = store.getState();
  const getRefs = additionalIds.map((id) => db.collection('additionals').doc(id).get());

  try {
    const allAdditionals = await Promise.all(getRefs);
    const additionalsData = allAdditionals.reduce((acc, additional) => {
      const additionalData = additional.data();

      if (additionalData && additionalData.establishmentId === establishmentId) {
        if (additionalData.options && additionalData.options.length > 0) {
          acc.push({
            ...additionalData,
            options: orderBySmallestToLargest(additionalData.options, 'sequence'),
          });
        }
      }

      return acc;
    }, []);

    return additionalsData;
  } catch (err) {
    console.log(err);
    return [];
  }
};
