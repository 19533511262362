import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../../../store/modules/user/actions';
import { UIInput } from '../../../UI';

const CartFormName = ({
  userName,
  addUserName,
  orderOrigin,
  isOnlineCommand,
}) => (
  <UIInput
    type="text"
    value={userName}
    placeholder="Ex: José"
    required={orderOrigin !== 'table' && !isOnlineCommand}
    label={(isOnlineCommand) ? 'Nome do cliente' : 'Qual o seu nome?'}
    description={orderOrigin === 'table' ? 'Informação necessária para cadastrar o cliente no programa de fidelidade' : ''}
    setValue={(newName) => {
      addUserName(newName);
    }}
  />
);

CartFormName.propTypes = {
  userName: PropTypes.string.isRequired,
  addUserName: PropTypes.func.isRequired,
  orderOrigin: PropTypes.string.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  userName: state.user.name,
  orderOrigin: state.cart.orderOrigin,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormName);
