import React from 'react';
import PropTypes from 'prop-types';

import { CgMathMinus, CgMathPlus } from 'react-icons/cg';
import * as S from './styles';
import UIButton from '../Button';

const UIQuantityControl = ({
  quantity,
  setQuantity,
  canBeZero,
  simpleModel,
  disablePlus,
  disableButtons,
  customHeight,
  disabled,
}) => (
  <S.UIQuantityControl
    simpleModel={simpleModel}
    customHeight={customHeight}
    disableButtons={disableButtons || disabled}
  >

    {quantity > 0 && (
      <>
        <UIButton
          disabled={disabled}
          icon={<CgMathMinus />}
          isSimpleModel={simpleModel}
          disabledAppearance={disableButtons}
          className="UIQuantityControl__button"
          onClick={() => { if ((quantity - 1) >= 1 || canBeZero) setQuantity(quantity -= 1); }}
        />
        <span className="UIQuantityControl__quantity">{quantity}</span>
      </>
    )}

    <UIButton
      icon={<CgMathPlus />}
      isSimpleModel={simpleModel}
      disabled={disabled || disablePlus}
      disabledAppearance={disableButtons}
      className="UIQuantityControl__button"
      onClick={() => { setQuantity(quantity += 1); }}
    />

  </S.UIQuantityControl>
);

UIQuantityControl.propTypes = {
  disabled: PropTypes.bool,
  canBeZero: PropTypes.bool,
  simpleModel: PropTypes.bool,
  disablePlus: PropTypes.bool,
  disableButtons: PropTypes.bool,
  customHeight: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

UIQuantityControl.defaultProps = {
  disabled: false,
  canBeZero: false,
  simpleModel: false,
  disablePlus: false,
  customHeight: null,
  disableButtons: false,
};

export default UIQuantityControl;
