const INITIAL_STATE = {
  id: '', // used on online command select client
  name: '',
  phone: '',
  table: null,
  tableSelectedByDefault: false,
  deliveryMethod: {
    name: '',
    label: '',
  },
  paymentMethod: {
    id: '',
    name: '',
    pixKey: '',
    changeValue: '',
    accountOwner: '',
    flag: {
      id: '',
      name: '',
    },
  },
  address: {
    uf: '',
    city: '',
    number: '',
    street: '',
    latitude: 0,
    longitude: 0,
    complement: '',
    neighborhood: '',
    referencePoint: '',
    addressTooFar: false,
    addressNotFound: false,
  },
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@user/ADD_ID':
      return {
        ...state,
        id: action.id,
      };
    case '@user/ADD_PHONE':
      return {
        ...state,
        phone: action.phone,
      };
    case '@user/ADD_NAME':
      return {
        ...state,
        name: action.name,
      };
    case '@user/ADD_DELIVERY_METHOD':
      return {
        ...state,
        deliveryMethod: action.deliveryMethod,
      };
    case '@user/ADD_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.paymentMethod,
      };
    case '@user/RESET_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: INITIAL_STATE.paymentMethod,
      };
    case '@user/ADD_TABLE':
      return {
        ...state,
        table: action.tableNumber,
      };
    case '@user/ADD_ADDRESS':
      return {
        ...state,
        address: {
          ...action.address,
          addressTooFar: false,
          addressNotFound: false,
        },
      };
    case '@user/RESET_ADDRESS':
      return {
        ...state,
        address: INITIAL_STATE.address,
      };
    case '@user/ADD_ADRESS_TOO_FAR':
      return {
        ...state,
        address: {
          ...action.address,
          addressTooFar: true,
          addressNotFound: false,
        },
      };
    case '@user/ADD_ADRESS_NOT_FOUND':
      return {
        ...state,
        address: {
          ...INITIAL_STATE.address,
          addressTooFar: false,
          addressNotFound: true,
        },
      };
    case '@user/ADD_INFOS_FROM_LAST_ORDER':
      return {
        ...state,
        table: action.lastOrder.userTable,
        name: action.lastOrder.client.name,
        phone: action.lastOrder.client.phone,
        address: action.lastOrder.deliveryAddress,
        deliveryMethod: action.lastOrder.deliveryType,
        paymentMethod: action.lastOrder.paymentMethod,
      };
    case '@user/RESET':
      return INITIAL_STATE;
    case '@user/ADD_TABLE_SELECTED_BY_DEFAULT':
      return {
        ...state,
        tableSelectedByDefault: action.tableSelectedByDefault,
      };
    default:
      return state;
  }
}
