import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IoTicketOutline } from 'react-icons/io5';
import { formatNumberToReal } from '../../../utils/formatting';
import * as S from './styles';

const MenuCoupon = ({ coupon }) => {
  if (!coupon || !coupon.name) {
    return null;
  }

  return (
    <S.MenuCoupon>
      <S.CouponTitle>
        <IoTicketOutline size={18} stroke="var(--primary-color)" />
        <strong>
          {`CUPOM ${coupon.name}`}
        </strong>
      </S.CouponTitle>
      <div>
        <span>
          {coupon.couponType === 'delivery' && (
            'FRETE GRÁTIS'
          )}
          {coupon.couponType === 'fixed' && (
            `${formatNumberToReal(coupon.value)} de desconto`
          )}
          {coupon.couponType === 'percentage' && (
            `${coupon.value}% de desconto`
          )}
          {coupon.minOrderValue ? (
            ` a partir de ${formatNumberToReal(coupon.minOrderValue)}`
          ) : null}
        </span>
      </div>
    </S.MenuCoupon>
  );
};

MenuCoupon.propTypes = {
  coupon: PropTypes.shape({
    name: PropTypes.string,
    couponType: PropTypes.string,
    value: PropTypes.number,
    maxValue: PropTypes.number,
    minOrderValue: PropTypes.number,
    quantity: PropTypes.number,
    availability: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.arrayOf(PropTypes.string),
    hour: PropTypes.shape({
      final: PropTypes.string,
      initial: PropTypes.string,
    }),
    date: PropTypes.shape({
      final: PropTypes.number,
      initial: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  coupon: state.cart.activeCoupon,
});

export default connect(mapStateToProps, null)(MenuCoupon);
