const infosFromAPI = {
  id: '',
  businessHours: [],
  hideAddress: false,
  forceClosingDate: '',
  allowObservationOnProducts: true,
  deliveryRates: {
    type: '',
    rateUnique: [],
    rateToBeAgreed: [],
    ratesByDistance: [],
    ratesByNeighborhood: [],
  },
  establishmentCategories: [],
  brand: '',
  location: {
    complement: '',
    longitude: 0,
    city: '',
    number: '',
    cep: '',
    street: '',
    uf: '',
    neighborhood: '',
    latitude: 0,
  },
  scheduleOrders: {
    active: false,
    scheduleDaysOptions: [],
    allowJustScheduledOrders: false,
    hasValidDaysToScheduleOrders: false,
    maxAntecedence: {
      format: '',
      value: 0,
    },
    minAntecedence: {
      format: '',
      value: 0,
    },
  },
  paymentMethods: [],
  slug: '',
  deliveryOptions: [],
  minOrder: 0,
  name: '',
  phoneForOrder: '',
  banner: '',
  color: '',
  timezone: '',
  welcomeText: '',
  facebookPixelId: '',
};

const customInfos = {
  isOpen: true,
  iframeRunningOn: '',
  isOnlineCommand: false,
  hasActiveCoupons: false,
};

const INITIAL_STATE = {
  ...infosFromAPI,
  ...customInfos,
};

export default function establishment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@establisment/ESTABLISHMENT_ADD':
      return action.establishment;

    case '@establisment/RESET':
      return INITIAL_STATE;

    default:
      return state;
  }
}
