import styled from 'styled-components';
import { UIContainer } from '../../UI';

export const Container = styled(UIContainer)`
  background: #F4F4F4;
  height: calc(100vh - 32px);
`;

export const InfosContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 60px;
  align-items: center;
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 18px;
  margin-top: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-bottom: 60px;
`;

export const TextSubtitle = styled(Text)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-right: auto;
  font-weight: normal;
  margin-bottom: 30px;
`;

export const List = styled.ul`
  margin-right: auto;
  margin-bottom: 40px;
  li {
    display: flex;
    align-items: baseline;
    padding: 8px 0;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const ListText = styled.p`
  font-size: 14px;
  margin-left: 7px;
  letter-spacing: 0;
  line-height: 20px;
`;
