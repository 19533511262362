import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ShopWindow = styled(Swiper)`
  width: 100%;
  display: flex;

  .swiper-wrapper {
    display: flex;
  }

  .slide {
    margin-right: 12px;
  }
`;
