import styled, { css } from 'styled-components';
import { Link } from 'react-scroll';
import { Swiper } from 'swiper/react';

export const NewMenuChangeItemType = styled.div`
  z-index: 10;
  background: var(--primary-color);
`;

export const NewMenuChangeItemTypeTop = styled.div`
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  padding: 12px var(--grid-margin-space) 8px var(--grid-margin-space);
  & > h2 {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }
  ${(props) => props.$isSticky && css`
    padding: 15px var(--grid-margin-space);

    & > h2 {
      font-size: 14px;
      line-height: 16px;
    }
  `}
`;

export const NewMenuChangeItemTypeItems = styled.div`
  width: 100%;
  z-index: 10;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  background: var(--primary-color);
  padding: 12px var(--grid-margin-space) 22px var(--grid-margin-space);
  ${(props) => props.$isSticky && css`
    background: #FFF;
    padding: 12px var(--grid-margin-space);
  `}
`;

export const NewMenuChangeItemTypeSlide = styled(Swiper)`
  width: 100%;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  background: var(--primary-color);
  .swiper-wrapper {
    display: flex;
  }
  ${(props) => props.$isSticky && css`
    background: #FFF;
  `}
`;

export const NewMenuChangeItemTypeLink = styled(Link)`
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  margin-right: 7px;
  border-radius: 4px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-transform: capitalize;

  color: #FFF;
  border: 1px solid #FFF;
  background: var(--primary-color);

  &.active {
    color: #FFF;
    background: var(--primary-color);
  }
  ${(props) => props.$isSticky && css`
    background: #FFF;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  `}
`;

export const DisplaySearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }
  a {
    height: 20px;
  }
`;
