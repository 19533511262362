import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export const UICard = ({
  children, color, className, bordered,
}) => (
  <S.UICard color={color} className={className} bordered={bordered}>
    {children}
  </S.UICard>
);

UICard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
  bordered: PropTypes.bool,
  className: PropTypes.string,
};

UICard.defaultProps = {
  color: '',
  className: '',
  bordered: false,
};
