import styled, { css } from 'styled-components';
import { UIContainer } from '../../UI';

export const HomeInfos = styled(UIContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Welcome = styled.span`
  color: #5E5E5E;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const Title = styled.h1`
  color: #5E5E5E;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
`;

export const OpenBorder = styled.div`
  color: #fff;
  display: flex;
  min-height: 32px;
  padding: 5px 25px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 16px;
  background-color: green;
  justify-content: center;
  ${({ isOpen }) => !isOpen && css`
    background-color: red;
  `}
  & > svg {
    stroke: white;
    font-size: 18px;
    margin-right: 7px;
  }
`;
