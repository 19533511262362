import styled, { css } from 'styled-components';

export const Option = styled.button`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 16px var(--grid-margin-space);
  &:not(:last-of-type) {
    &:after {
      content: "";
      left: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
      margin: 0 var(--grid-margin-space);
      border-bottom: 1px solid var(--default-gray);
      width: calc(100% - 2 * var(--grid-margin-space));
    }
  }
  .option {
    &__infos {
      width: 77%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .infos {
        &__title {
          font-size: 1rem;
          font-weight: 500;
          text-align: start;
        }
        &__description {
          width: 77%;
          text-align: start;
          font-size: 0.85rem;
          color: var(--text-secondary-color);
        }
        &__price {
          display: flex;
          margin-top: 5px;
          font-weight: 500;
          align-items: center;
          .price {
            &__plus {
              margin-right: 5px;
            }
          }
        }
      }
    }
    &__custom-info {
      display: flex;
      font-size: 12px;
      margin-right: 15px;
      white-space: nowrap;
    }
    &__selection {
      z-index: 1;
      .selection {
        &__radio {
          width: 25px;
          height: 25px;
          display: flex;
          border-radius: 25px;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--default-gray);
          .radio {
            &__background {
              width: 13px;
              height: 13px;
              background: white;
              border-radius: 13px;
            }
          }
        }
        &__checkbox {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--default-gray);
          svg {
            fill: #FFF;
            color: #FFF;
            stroke: #FFF;
            font-size: 14px;
          }
        }
      }
    }
  }

  ${({ simpleStyle }) => simpleStyle === 'true' && css`
    padding: 10px var(--grid-margin-space); 
    .option {
      &__infos {
        .infos {
          &__title {
            font-size: 14px;
          }
          &__description {
            font-size: 12px;
            color: var(--text-secondary-color);
          }
        }
      }
    }
  `}

  ${({ isSelected }) => isSelected === 'true' && css`
    .option {
      &__selection {
        z-index: 1;
        .selection {
          &__radio {
            width: 25px;
            height: 25px;
            border-radius: 25px;
            background: var(--primary-color);
          }
          &__checkbox {
            width: 25px;
            height: 25px;
            border: none;
            background: var(--primary-color);
          }
        }
      }
    }
  `}
`;
