import 'dayjs/locale/pt-br';
import { getIfThisWeekdayEstablishmentIsOpen } from './getIfThisWeekdayEstablishmentIsOpen';

export const getDaysOptions = (minDate, maxDate) => {
  const daysDifference = maxDate.diff(minDate, 'day');
  const daysBetween = [...Array(daysDifference)].map((_, index) => minDate.add(index, 'day'));

  // +1 to include the last day
  daysBetween.push(minDate.add(daysDifference, 'day'));

  const daysOptions = daysBetween.reduce((acc, date) => {
    const isOpenOnThisWeekday = getIfThisWeekdayEstablishmentIsOpen(date.format('dddd'));

    if (isOpenOnThisWeekday) {
      acc.push(
        {
          value: date.startOf('D').valueOf(),
          subtitle: date.startOf('D').format('DD/MM'),
          title: date.startOf('D').locale('pt-br').format('ddd'),
        },
      );
    }

    return acc;
  }, []);

  return daysOptions;
};
