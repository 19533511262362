import styled from 'styled-components';

export const MenuInfos = styled.div`
  display: flex;
  margin-top: 5px;
  flex-direction: column;
`;

export const MenuInfosLine = styled.div`
  display: flex;
  padding: 5px 0;
  position: relative;
  align-items: flex-start;
  span {
    display: flex;
    color: #5E5E5E;
    font-size: 14px;
    align-items: center;
    text-transform: capitalize;
  }
  svg {
    font-size: 16px;
    position: absolute;
  }
`;
