import React from 'react';
import { Element } from 'react-scroll';
import PropTypes from 'prop-types';
import ItemsOptions from '../Options';
import MenuItemsGroupHeader from './Header';

const MenuItemsGroup = ({ categoriesWithProducts, promotionalProducts }) => (
  categoriesWithProducts && categoriesWithProducts.length > 0 ? (
    <>
      {promotionalProducts.length > 0 && (
        <Element name="custom-promotions">
          <MenuItemsGroupHeader
            groupName="Promoções"
          />

          <ItemsOptions
            items={promotionalProducts}
          />
        </Element>
      )}
      {categoriesWithProducts.map(({
        id, name, products,
      }) => (
        products.length > 0 ? (
          <Element key={id} name={id.toString()}>
            <MenuItemsGroupHeader
              groupName={name}
            />

            <ItemsOptions
              items={products}
            />
          </Element>
        ) : null
      ))}
    </>
  ) : null
);

MenuItemsGroup.propTypes = {
  promotionalProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    establishmentId: PropTypes.string,
    name: PropTypes.string,
    sequence: PropTypes.number,
    updatedAt: PropTypes.number,
    createdAt: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      establishmentId: PropTypes.string,
      createdAt: PropTypes.number,
      promotionalPrice: PropTypes.number,
      sequence: PropTypes.number,
      additionals: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
      updatedAt: PropTypes.number,
      paused: PropTypes.bool,
      description: PropTypes.string,
      priceType: PropTypes.string,
      categoryId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        promotionalPrice: PropTypes.number,
      })),
    })),
  })).isRequired,
  categoriesWithProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    establishmentId: PropTypes.string,
    name: PropTypes.string,
    sequence: PropTypes.number,
    updatedAt: PropTypes.number,
    createdAt: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      establishmentId: PropTypes.string,
      createdAt: PropTypes.number,
      promotionalPrice: PropTypes.number,
      sequence: PropTypes.number,
      additionals: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
      updatedAt: PropTypes.number,
      paused: PropTypes.bool,
      description: PropTypes.string,
      priceType: PropTypes.string,
      categoryId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        promotionalPrice: PropTypes.number,
      })),
    })),
  })).isRequired,
};

export default MenuItemsGroup;
