import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as S from '../styles';
import CustomInputLabel from '../../CustomInputLabel';

const UIInputHookForm = ({
  type,
  label,
  name,
  errors,
  validate,
  required,
  register,
  minLength,
  maxLength,
  readOnly,
  hideInput,
  className,
  infoMessage,
  placeholder,
  errorMessage,
  defaultValue,
  validateErrorMsg,
}) => {
  const [inputError, setInputError] = useState({});
  const [customVal, setCustomVal] = useState('');
  const [customDefaultVal, setCustomDefaultVal] = useState(defaultValue);

  useEffect(() => {
    if (errors[name]) {
      setInputError(errors[name]);
    }
  }, [errors, name]);

  const handleOnChangeInput = useCallback((val) => {
    setInputError(errors[name]);
    if (customDefaultVal) {
      setCustomDefaultVal('');
    }
    setCustomVal(val);
  }, [errors, customDefaultVal, name]);

  return (
    <S.UIInput
      hideInput={hideInput}
      className={`UIInput ${className}`}
      error={inputError && Object.keys(inputError).length > 0}
    >
      <CustomInputLabel
        labelTitle={label}
        isRequired={required}
        isValited={!!(customVal || customDefaultVal)}
      />
      <div className="UIInput__input-container">
        <input
          name={name}
          type={type}
          autoComplete="on"
          readOnly={readOnly}
          maxLength={maxLength}
          placeholder={placeholder}
          className="UIInput__input"
          defaultValue={customDefaultVal}
          onChange={(e) => handleOnChangeInput(e.target.value)}
          ref={
                register({
                  required: required || null,
                  validate: validate || null,
                  maxLength: maxLength || null,
                  minLength: minLength || null,
                })
              }
        />
      </div>

      {inputError && inputError.type === 'required' && (
        <span className="UIInput__error-msg">{errorMessage || 'Este campo é obrigatório'}</span>
      )}

      {inputError && inputError.type === 'minLength' && (
        <span className="UIInput__error-msg">
          Mínimo de letras
          {' '}
          {minLength}
        </span>
      )}

      {inputError && inputError.type === 'maxLength' && (
        <span className="UIInput__error-msg">
          Máximo de letras
          {' '}
          {maxLength}
        </span>
      )}

      {inputError && inputError.type === 'validate' && (
        <span className="UIInput__error-msg">
          {validateErrorMsg}
        </span>
      )}

      {infoMessage && (
        <span className="UIInput__info-msg">{infoMessage}</span>
      )}
    </S.UIInput>
  );
};

UIInputHookForm.propTypes = {
  hideInput: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  validate: PropTypes.func,
  infoMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  validateErrorMsg: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  register: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'password', 'url', 'search', 'number', 'date', 'range', 'hidden']).isRequired,
};

UIInputHookForm.defaultProps = {
  validate: null,
  readOnly: false,
  required: false,
  minLength: null,
  maxLength: null,
  className: '',
  infoMessage: '',
  placeholder: '',
  errorMessage: '',
  defaultValue: null,
  validateErrorMsg: '',
};

export default UIInputHookForm;
