/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import { formatPhoneNumber } from 'react-phone-number-input';
import { createWhatsMessageItems } from './items';
import { createWhatsMessageValues } from './values';
import { getPaymentMethodName } from '../getPaymentMethodName';
import { getAddressInfosInSeparatedLines } from '../addressInfos';
import { getScheduledDeliveryDateText } from '../getScheduledDeliveryDateText';
import { formatRealToNumber } from '../formatting';

const getChangeValue = (paymentMethod, cartTotal) => {
  const changeValue = formatRealToNumber(paymentMethod.changeValue);
  const cartTotalNum = formatRealToNumber(cartTotal);

  if (cartTotalNum === changeValue) {
    return `*Pagamento:* Dinheiro 
*Não precisa de troco*`;
  }

  return `*Pagamento:* Dinheiro 
*Troco para:* ${paymentMethod.changeValue}`;
};

export default function createWhatsMsg(order) {
  const {
    items,
    client,
    coupon,
    delivery,
    cartTotal,
    itemsTotal,
    isScheduled,
    deliveryType,
    scheduledData,
    paymentMethod,
    deliveryAddress,
  } = order;
  const orderTime = dayjs(order.timestamp).format('DD/MM/YYYY - HH:mm');

  const message = `
_Confira as informações do pedido:_
---------------------------------------
*Pedido* - _${client.name}_
${orderTime}

${(isScheduled) ? (
    `*${getScheduledDeliveryDateText(scheduledData)}*`
  ) : ''}

---------------------------------------

${createWhatsMessageItems(items)}

${createWhatsMessageValues(deliveryType, delivery, itemsTotal, cartTotal, coupon)}

---------------------------------------

*${client.name}*
${formatPhoneNumber(client.phone)}

${deliveryType.label === 'delivery' ? (
    `${delivery.estimatedTime ? `${deliveryType.name} (${delivery.estimatedTime})` : deliveryType.name}
${getAddressInfosInSeparatedLines(deliveryAddress)}`
  ) : (
    `${delivery.estimatedTime ? `${deliveryType.name} (${delivery.estimatedTime})` : deliveryType.name}`
  )}

${paymentMethod.id === 'dinheiro' ? getChangeValue(paymentMethod, cartTotal) : `*Pagamento:* ${getPaymentMethodName(paymentMethod)}`}

${(paymentMethod.id === 'pix' && paymentMethod.pixKey) ? (
    `Copie a chave PIX abaixo para realizar o pagamento de ${cartTotal}, encaminhe o comprovante para o restaurante e logo após eles iniciarão o preparo do pedido.`
  ) : ''}

${(paymentMethod.id === 'pix' && paymentMethod.pixKey) ? (
    `*Chave PIX:* ${paymentMethod.pixKey}
*Nome do titular da conta:* ${paymentMethod.accountOwner}`
  ) : ''}
`;

  return message;
}
