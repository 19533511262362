import { createSelector } from 'reselect';
import { getProductsTotal } from '../products';
import { formatNumberToReal } from '../../../utils/formatting';

const activeCouponState = (state) => state.cart.activeCoupon;

export const getIfActiveCouponIsValidToUse = createSelector(
  [getProductsTotal, activeCouponState],
  (productsTotal, activeCoupon) => {
    if (activeCoupon?.name) {
      if (productsTotal > activeCoupon.minOrderValue) {
        return true;
      }
      return false;
    }

    return false;
  },
);

export const getFixedCouponValue = createSelector(
  [activeCouponState],
  (activeCoupon) => {
    if (activeCoupon.couponType === 'delivery') {
      return 'FRETE GRÁTIS';
    }

    if (activeCoupon.couponType === 'percentage') {
      return `${activeCoupon.value}%`;
    }

    return `${formatNumberToReal(activeCoupon.value)}`;
  },
);

export const getCouponValue = createSelector(
  [activeCouponState, getProductsTotal],
  (activeCoupon, productsTotal) => {
    if (!activeCoupon || !activeCoupon.name) {
      return null;
    }

    if (activeCoupon.couponType === 'delivery') {
      return 'FRETE GRÁTIS';
    }

    if (activeCoupon.couponType === 'percentage') {
      const discountBasedOnPercentage = Number(
        ((productsTotal * activeCoupon.value) / 100).toFixed(2),
      );

      if (discountBasedOnPercentage < activeCoupon.maxValue) {
        return discountBasedOnPercentage;
      }

      return activeCoupon.maxValue;
    }

    return activeCoupon.value;
  },
);

export const getFormattedCouponValue = createSelector(
  [getCouponValue],
  (couponValue) => {
    if (couponValue && typeof couponValue === 'number') {
      return formatNumberToReal(couponValue);
    }

    return couponValue;
  },
);
