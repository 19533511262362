import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UIModal } from '../../UI';
import CartAddressForm from './Form';
import CartAddressSelection from './Selection';
import CartAddressNeighborhoodSelection from './NeighborhoodSelection';
import * as UserActions from '../../../store/modules/user/actions';
import * as AddressesActions from '../../../store/modules/addresses/actions';
import { getModalCloseFunc, getModalTitle } from './constants';

const CartAddress = ({
  deliveryRates,
  getUserAddressDistance,
  showAddressSelectionModal,
  setShowAddressSelectionModal,
}) => {
  const { type } = deliveryRates;
  const [clientAddress, setClientAddress] = useState({});
  const [addressToEdit, setAddressToEdit] = useState({});
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('');

  useEffect(() => {
    setAddNewAddress(false);
  }, []);

  useEffect(() => {
    if (clientAddress.street) {
      if (type === 'rateByNeighborhood') {
        const customClientAddress = {
          ...clientAddress,
          neighborhood: selectedNeighborhood,
        };
        getUserAddressDistance(customClientAddress);
        setAddNewAddress(false);
        setShowAddressSelectionModal(false);
      } else {
        getUserAddressDistance(clientAddress);
        setAddNewAddress(false);
        setShowAddressSelectionModal(false);
      }
    }
  }, [
    type,
    clientAddress,
    getUserAddressDistance,
    selectedNeighborhood,
    setShowAddressSelectionModal,
  ]);

  return (
    <UIModal
      showModal={showAddressSelectionModal}
      setShowModal={getModalCloseFunc(
        addNewAddress,
        addressToEdit,
        setAddNewAddress,
        setAddressToEdit,
        setShowAddressSelectionModal,
      )}
      modalTitle={getModalTitle(addNewAddress, addressToEdit)}
    >
      {type === 'rateByNeighborhood' && !selectedNeighborhood && (
        <CartAddressNeighborhoodSelection
          setSelectedNeighborhood={setSelectedNeighborhood}
        />
      )}

      {(type !== 'rateByNeighborhood'
        || (type === 'rateByNeighborhood' && selectedNeighborhood)) && (
        <>
          {addNewAddress || addressToEdit?.street ? (
            <CartAddressForm
              addressToEdit={addressToEdit}
              setAddressToEdit={setAddressToEdit}
              setClientAddress={setClientAddress}
              selectedNeighborhood={selectedNeighborhood}
              setShowAddressSelectionModal={setShowAddressSelectionModal}
            />
          ) : (
            <CartAddressSelection
              setClientAddress={setClientAddress}
              setAddNewAddress={setAddNewAddress}
              setAddressToEdit={setAddressToEdit}
              setShowAddressSelectionModal={setShowAddressSelectionModal}
            />
          )}
        </>
      )}
    </UIModal>
  );
};

CartAddress.propTypes = {
  getUserAddressDistance: PropTypes.func.isRequired,
  showAddressSelectionModal: PropTypes.bool.isRequired,
  setShowAddressSelectionModal: PropTypes.func.isRequired,
  deliveryRates: PropTypes.shape({
    type: PropTypes.string.isRequired,
    rates: PropTypes.arrayOf(PropTypes.shape({
      active: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      maxTime: PropTypes.number,
      minTime: PropTypes.number,
      neighborhood: PropTypes.string,
      rate: PropTypes.number,
      rateToBeAgreed: PropTypes.bool.isRequired,
    })).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  deliveryRates: state.establishment.deliveryRates,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...UserActions, ...AddressesActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartAddress);
