import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const UILabel = ({ text, className }) => (
  <S.UILabel className={className}>
    {text}
  </S.UILabel>
);

UILabel.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

UILabel.defaultProps = {
  className: '',
};

export default UILabel;
