import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import { bindActionCreators } from 'redux';
import { UIButton, UIFixedBottom, UILoader } from '../../UI';
import {
  FirebaseCoupons,
  FirebaseOrders,
  FirebaseTables,
  FirebaseCustomers,
} from '../../../firebase/functions';
import {
  getCouponValue,
  getFormattedCartTotal,
  getFormattedProductsTotal,
  getIfActiveCouponIsValidToUse,
  getIfCartProductsIsBiggerThanMinOrder,
} from '../../../store/selectors';
import PixKeyModal from './PixKeyModal';
import MinValueAlertModal from './MinValueAlertModal';
import useCheckIfhaveAllrequiredInfos from './useCheckIfHaveAllRequiredInfos';
import SuccessOrderModal from './SuccessOrderModal';
import { formatRealToNumber } from '../../../utils/formatting';
import { whatsappSendMessage } from '../../../utils/whatsapp/sendMessage';
import * as SystemActions from '../../../store/modules/system/actions';

const CartFixedButton = ({
  userId,
  userName,
  userPhone,
  cartTotal,
  cartItems,
  userTable,
  itemsTotal,
  isScheduled,
  orderOrigin,
  userAddress,
  activeCoupon,
  scheduledData,
  deliveryInfos,
  addLastOrderId,
  isOnlineCommand,
  userPaymentMethod,
  userDeliveryMethod,
  tableSelectedByDefault,
  activeCouponIsValidToUse,
  cartProductsBiggerThanMinOrder,
}) => {
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showPixKeyModal, setShowPixKeyModal] = useState(false);
  const hasAllRequiredInfos = useCheckIfhaveAllrequiredInfos();
  const [showSuccessOrderModal, setShowSuccessOrderModal] = useState(false);

  const handleCheckOut = useCallback(async (pixKeyCopied) => {
    if (!hasAllRequiredInfos) {
      return;
    }

    if (!cartProductsBiggerThanMinOrder) {
      setShowAlertModal(true);
      return;
    }

    if (
      !isOnlineCommand
      && userPaymentMethod.id === 'pix'
      && userPaymentMethod.pixKey
      && !pixKeyCopied
    ) {
      setShowPixKeyModal(true);
      return;
    }

    try {
      setLoading(true);
      const dateOnISOString = new Date().toISOString();
      const ISOTimestamp = new Date(dateOnISOString).getTime();
      const orderMock = {
        userTable,
        cartTotal,
        itemsTotal,
        printed: false,
        status: 'open',
        refused: false,
        isOnlineCommand,
        origin: orderOrigin,
        statusAcceptedAt: '',
        statusFinishedAt: '',
        statusPreparingAt: '',
        statusDeliveringAt: '',
        date: dateOnISOString,
        delivery: deliveryInfos,
        timestamp: ISOTimestamp,
        isScheduled: !!isScheduled,
        deliveryAddress: userAddress,
        deliveryType: userDeliveryMethod,
        paymentMethod: userPaymentMethod,
        coupon: activeCouponIsValidToUse ? activeCoupon : {},
        scheduledData: isScheduled && scheduledData?.deliveryDateTimeEnd ? scheduledData : '',
        client: {
          name: userName,
          phone: userPhone,
        },
        items: cartItems.map(
          ({
            id,
            unit,
            name,
            price,
            image,
            amount,
            weight,
            idOnCart,
            observation,
            promotionalPrice,
            selectedVariations,
            selectedAdditionals,
          }) => ({
            id,
            unit,
            name,
            image,
            price,
            weight,
            amount,
            idOnCart,
            promotionalPrice,
            observation: observation || '',
            variations: selectedVariations || [],
            additionals: selectedAdditionals || [],
          }),
        ),
      };

      const newOrder = await FirebaseOrders.addOrder(orderMock);

      if (activeCouponIsValidToUse) {
        await FirebaseCoupons.updateCouponQuantity(activeCoupon.id);
      }

      if (orderOrigin === 'table') {
        await FirebaseTables.updateTableOrders(
          orderMock.userTable,
          newOrder.id,
        );
      }

      if (isOnlineCommand) {
        if (userId) {
          await FirebaseCustomers.updateCustomerOrders(userId, {
            ...orderMock,
            id: newOrder.id,
          });
        }

        setLoading(false);
        setOrderId(newOrder.id);
        setShowSuccessOrderModal(true);
        return;
      }

      if (!isOnlineCommand) {
        const customer = await FirebaseCustomers.getCustomerByPhone(
          orderMock.client.phone,
        );

        if (customer.id) {
          await FirebaseCustomers.updateCustomerOrders(customer.id, {
            ...orderMock,
            id: newOrder.id,
          });
        } else {
          await FirebaseCustomers.postCustomer({
            name: orderMock.client.name,
            phone: orderMock.client.phone,
            order: {
              ...orderMock,
              id: newOrder.id,
            },
          });
        }
      }

      ReactPixel.track('Purchase', {
        value: formatRealToNumber(orderMock.cartTotal),
        currency: 'BRL',
      });

      if (orderOrigin === 'table' && tableSelectedByDefault) {
        setLoading(false);
        setShowSuccessOrderModal(true);
        return;
      }

      addLastOrderId(newOrder.id);

      whatsappSendMessage(newOrder);
    } catch (err) {
      setLoading(false);
      toast.error('Ops, houve um erro ao finalizar o pedido.');
      console.log(err);
    }
  }, [
    hasAllRequiredInfos,
    cartProductsBiggerThanMinOrder,
    userTable,
    cartTotal,
    itemsTotal,
    isScheduled,
    isOnlineCommand,
    orderOrigin,
    deliveryInfos,
    userAddress,
    userDeliveryMethod,
    userPaymentMethod,
    activeCouponIsValidToUse,
    activeCoupon,
    userName,
    userPhone,
    cartItems,
    tableSelectedByDefault,
    userId,
    addLastOrderId,
    scheduledData,
  ]);

  return (
    <>
      <UIFixedBottom>
        <UIButton
          type="button"
          disabled={loading}
          onClick={() => handleCheckOut()}
          disabledAppearance={!hasAllRequiredInfos}
        >
          {loading ? (
            <UILoader width={24} height={24} color="#FFF" />
          ) : (
            <span>Finalizar pedido</span>
          )}
        </UIButton>
        <MinValueAlertModal
          showModal={showAlertModal}
          setShowModal={setShowAlertModal}
        />
      </UIFixedBottom>

      {showPixKeyModal && (
        <PixKeyModal
          showModal={showPixKeyModal}
          handleCheckOut={handleCheckOut}
          setShowModal={setShowPixKeyModal}
        />
      )}

      <SuccessOrderModal orderId={orderId} showModal={showSuccessOrderModal} />
    </>
  );
};

CartFixedButton.propTypes = {
  userId: PropTypes.string,
  userTable: PropTypes.number,
  userName: PropTypes.string.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  userPhone: PropTypes.string.isRequired,
  cartTotal: PropTypes.string.isRequired,
  itemsTotal: PropTypes.string.isRequired,
  orderOrigin: PropTypes.string.isRequired,
  addLastOrderId: PropTypes.func.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  tableSelectedByDefault: PropTypes.bool.isRequired,
  activeCouponIsValidToUse: PropTypes.bool.isRequired,
  cartProductsBiggerThanMinOrder: PropTypes.bool.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  userAddress: PropTypes.objectOf(PropTypes.node).isRequired,
  deliveryInfos: PropTypes.objectOf(PropTypes.node).isRequired,
  userDeliveryMethod: PropTypes.objectOf(PropTypes.node).isRequired,
  scheduledData: PropTypes.shape({
    deliveryDateTimeEnd: '',
    deliveryDateTimeStart: '',
  }).isRequired,
  userPaymentMethod: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    pixKey: PropTypes.string,
    accountOwner: PropTypes.string,
    changeValue: PropTypes.string,
  }).isRequired,
  activeCoupon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    couponType: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    parsedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

CartFixedButton.defaultProps = {
  userId: '',
  userTable: null,
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
  userName: state.user.name,
  userPhone: state.user.phone,
  userTable: state.user.table,
  cartItems: state.cart.products,
  userAddress: state.user.address,
  deliveryInfos: state.cart.delivery,
  orderOrigin: state.cart.orderOrigin,
  isScheduled: state.cart.isScheduled,
  scheduledData: state.cart.scheduledData,
  cartTotal: getFormattedCartTotal(state),
  userPaymentMethod: state.user.paymentMethod,
  itemsTotal: getFormattedProductsTotal(state),
  userDeliveryMethod: state.user.deliveryMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
  tableSelectedByDefault: state.user.tableSelectedByDefault,
  activeCouponIsValidToUse: getIfActiveCouponIsValidToUse(state),
  cartProductsBiggerThanMinOrder:
    getIfCartProductsIsBiggerThanMinOrder(state),
  activeCoupon: {
    id: state.cart.activeCoupon.id,
    name: state.cart.activeCoupon.name,
    parsedValue: getCouponValue(state),
    value: state.cart.activeCoupon.value,
    couponType: state.cart.activeCoupon.couponType,
  },
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...SystemActions,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFixedButton);
