import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BiUser } from 'react-icons/bi';
import SelectCustomerModal from './SelectCustomerModal';
import {
  UIButton,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../UI';

const CartFormSelectCustomer = ({ user }) => {
  const [showSelectCustomerModal, setShowSelectCustomerModal] = useState(false);

  return (
    <>
      <UICustomInputLabel
        isValited={user.name}
        labelTitle="Selecionar cliente"
      />
      {user.name ? (
        <UIButtonToEdit
          className="mb-20"
          icon={<BiUser size={18} />}
          onClick={() => setShowSelectCustomerModal(true)}
          firstElement={(
            <strong>
              {user.name}
            </strong>
          )}
          secondElement={(
            <span>
              {user.phone}
            </span>
          )}
        />
      ) : (
        <UIButton
          outline
          className="mb-20"
          text="Selecionar cliente"
          onClick={() => setShowSelectCustomerModal(true)}
        />
      )}
      {showSelectCustomerModal && (
        <SelectCustomerModal
          showSelectCustomerModal={showSelectCustomerModal}
          setShowSelectCustomerModal={setShowSelectCustomerModal}
        />
      )}
    </>
  );
};

CartFormSelectCustomer.propTypes = {
  user: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(CartFormSelectCustomer);
