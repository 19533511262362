import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UILabel } from '../../../../UI';
import { getFixedCouponValue, getProductsTotal } from '../../../../../store/selectors';
import { formatNumberToReal } from '../../../../../utils/formatting';

const CartCouponInfosCustomMessage = ({
  activeCoupon, couponValue, productsTotal,
}) => {
  if (activeCoupon.minOrderValue > productsTotal) {
    return (
      <span>
        Faltam
        {' '}
        {formatNumberToReal(activeCoupon.minOrderValue - productsTotal)}
        {' '}
        em compras para ativar o cupom
      </span>
    );
  }

  if (activeCoupon.maxValue > 0) {
    return (
      <span>
        Este desconto está sendo aplicado até o valor máximo de
        {' '}
        {formatNumberToReal(activeCoupon.maxValue)}
        {' '}
        do total
      </span>
    );
  }

  return (
    couponValue === 'FRETE GRÁTIS' ? (
      <UILabel text={`${couponValue} aplicado`} />
    ) : (
      <UILabel text={`${couponValue} de desconto aplicado`} />
    )
  );
};

CartCouponInfosCustomMessage.propTypes = {
  couponValue: PropTypes.string.isRequired,
  productsTotal: PropTypes.number.isRequired,
  activeCoupon: PropTypes.shape({
    name: PropTypes.string,
    couponType: PropTypes.string,
    value: PropTypes.string,
    maxValue: PropTypes.string,
    minOrderValue: PropTypes.string,
    quantity: PropTypes.number,
    availability: PropTypes.arrayOf(PropTypes.string),
    origin: PropTypes.arrayOf(PropTypes.string),
    hour: PropTypes.shape({
      final: PropTypes.string,
      initial: PropTypes.string,
    }),
    date: PropTypes.shape({
      final: PropTypes.number,
      initial: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  activeCoupon: state.cart.activeCoupon,
  productsTotal: getProductsTotal(state),
  couponValue: getFixedCouponValue(state),
});

export default connect(mapStateToProps, null)(CartCouponInfosCustomMessage);
