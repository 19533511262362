import React from 'react';
import { VscClose } from 'react-icons/vsc';
import { useHistory, useParams } from 'react-router-dom';

import './CartHeader.scss';

const CartHeader = () => {
  const history = useHistory();
  const { establishment } = useParams();

  return (
    <header className="CartHeader">
      <button
        type="button"
        className="CartHeader__close-icon"
        onClick={() => history.push(`/${establishment}/produtos`)}
      >
        {/* eslint-disable-next-line */}
        <VscClose />
      </button>

      <span className="CartHeader__title">Meu pedido</span>
    </header>
  );
};

export default CartHeader;
