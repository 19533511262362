import React from 'react';
import HomeHeader from '../../components/Home/Header';
import HomeInfos from '../../components/Home/Infos';

export const ScreensHome = () => (
  <div style={{ minHeight: '100vh' }}>
    <HomeHeader />
    <HomeInfos />
  </div>
);
