import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../../../store/modules/user/actions';
import * as CartActions from '../../../../store/modules/cart/actions';
import { UICustomInputLabel } from '../../../UI';
import AddressStateBlock from '../../../AddressStateBlock';

const CartFormSelectAddress = ({
  userAddress,
  isOnlineCommand,
  informationalUse,
  userDeliveryMethod,
}) => {
  if (userDeliveryMethod?.label !== 'delivery') {
    return null;
  }

  const getLabel = () => {
    if (isOnlineCommand) return 'Endereço do cliente';

    if (informationalUse) return 'Endereço';

    return 'Qual seu endereço?';
  };

  const isValidated = (userAddress.street
    && !userAddress.addressTooFar
    && !userAddress.addressNotFound);

  return (
    <>
      <UICustomInputLabel
        isValited={isValidated}
        labelTitle={getLabel()}
        isRequired={!isOnlineCommand && !informationalUse}
        customLabelError={(userAddress.addressTooFar || userAddress.addressNotFound) ? 'Endereço inválido' : ''}
      />

      <AddressStateBlock
        marginBottom={20}
        isItBeingUsedOnCart
        informationalUse={informationalUse}
      />
    </>
  );
};

CartFormSelectAddress.propTypes = {
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
  userAddress: PropTypes.shape({
    addressNotFound: PropTypes.bool,
    addressTooFar: PropTypes.bool,
    city: PropTypes.string,
    complement: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    neighborhood: PropTypes.string,
    number: PropTypes.string,
    referencePoint: PropTypes.string,
    street: PropTypes.string,
    uf: PropTypes.string,
  }).isRequired,
  userDeliveryMethod: PropTypes.objectOf(PropTypes.node).isRequired,
};

const mapStateToProps = (state) => ({
  userAddress: state.user.address,
  userDeliveryMethod: state.user.deliveryMethod,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartFormSelectAddress);
