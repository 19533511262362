import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BiMap } from 'react-icons/bi';
import { PropCustomerAddress } from '../../../../../types/customer';
import { FirebaseCustomers } from '../../../../../firebase/functions';
import * as UserActions from '../../../../../store/modules/user/actions';
import * as CartActions from '../../../../../store/modules/cart/actions';
import * as AddressesActions from '../../../../../store/modules/addresses/actions';
import { getNeighborhoodCityAndUF } from '../../../../../utils/addressInfos';
import { getSeparetedStrings } from '../../../../../utils/getSeparetedStrings';
import {
  UIModal,
  UIInput,
  UIButton,
  UIInputPhone,
  UIFixedBottom,
  UIButtonToEdit,
  UICustomInputLabel,
} from '../../../../UI';
import AddressForm from '../../../../AddressForm';

const ClientFormModal = ({
  addUserId,
  showModal,
  addUserName,
  setShowModal,
  addUserPhone,
  selectedCustomer,
  resetUser,
  resetCartDeliveryState,
  closeChooseClientModal,
  addAdressesOnAdressGroup,
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [addressToEdit, setAddressToEdit] = useState({});
  const [userAddresses, setUserAddresses] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);

  const [phoneAlreadyUsed, setPhoneAlreadyUsed] = useState(false);
  const hasAllRequiredInfos = name?.length >= 3 && [13, 14].includes(phone?.length);
  const isEditCustomerForm = selectedCustomer?.id;

  useEffect(() => {
    if (isEditCustomerForm) {
      setName(selectedCustomer.name);
      setPhone(selectedCustomer.phone);
      setUserAddresses(selectedCustomer.addresses);
    }
  }, [
    selectedCustomer,
    isEditCustomerForm,
  ]);

  const handleSubmitUser = async () => {
    try {
      if (isEditCustomerForm) {
        await FirebaseCustomers.updateCustomer(selectedCustomer?.id, {
          name,
          phone,
          addresses: userAddresses,
        });

        resetUser();
        addUserId(selectedCustomer?.id);
      } else {
        const newCustomerId = await FirebaseCustomers.postCustomer({
          name,
          phone,
          addresses: userAddresses,
        });

        resetUser();
        addUserId(newCustomerId);
        setPhoneAlreadyUsed(false);
      }

      resetCartDeliveryState();

      addUserName(name);
      addUserPhone(phone);
      if (userAddresses.length >= 1) {
        addAdressesOnAdressGroup(userAddresses);
      }

      setShowModal(false);
      closeChooseClientModal();
    } catch (err) {
      if (err.message === 'Error: This phone number is already been used') {
        setPhoneAlreadyUsed(true);
      } else {
        console.log(err);
      }
    }
  };

  const handleOnSubmitAddressForm = async (data) => {
    if (addressToEdit?.street) {
      const newEdittedAddresses = await FirebaseCustomers.updateCustomerAddress(
        selectedCustomer?.id,
        addressToEdit.id,
        data,
      );
      setUserAddresses(newEdittedAddresses);
    } else {
      setUserAddresses((prevState) => [...prevState, data]);
    }

    setShowAddressModal(false);
  };

  return (
    <>
      <UIModal
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
        }}
        modalTitle={isEditCustomerForm ? 'Editar cliente' : 'Adicionar cliente'}
      >
        <UIInput
          required
          type="text"
          value={name}
          customValidation
          setValue={setName}
          placeholder="Ex: José"
          label="Nome do cliente"
          validatedValue={name?.length >= 3}
          errorMessage={
            name?.length >= 3 || name?.length === 0
              ? ''
              : 'O nome do cliente precisa ter no mínimo 3 caracteres'
          }
        />

        <UIInputPhone
          required
          value={phone}
          setValue={setPhone}
          placeholder="Ex: (11) 90000-0000"
          label="Número do celular do cliente"
          errorMessage={
            phoneAlreadyUsed
              ? 'Já existe um cliente cadastrado com esse telefone'
              : ''
          }
        />

        <UICustomInputLabel labelTitle="Endereços" />

        <div>
          {userAddresses?.length >= 1 && (
            <>
              {userAddresses.map((userAddress) => {
                const neighborhoodCityAndUF = getNeighborhoodCityAndUF(userAddress);

                return (
                  <UIButtonToEdit
                    icon={<BiMap />}
                    className="mb-10"
                    firstElement={
                      <strong>{`${userAddress?.street}, ${userAddress?.number}`}</strong>
                    }
                    secondElement={
                      neighborhoodCityAndUF ? (
                        <span>{neighborhoodCityAndUF}</span>
                      ) : (
                        ''
                      )
                    }
                    thirdElement={(
                      <span>
                        {getSeparetedStrings(
                          [userAddress.complement, userAddress.referencePoint],
                          '-',
                        )}
                      </span>
                    )}
                    onClick={() => {
                      setAddressToEdit(userAddress);
                      setShowAddressModal(true);
                    }}
                  />
                );
              })}
            </>
          )}

          <UIButton
            outline
            className="mb-20"
            text="Adicionar endereço"
            onClick={() => setShowAddressModal(true)}
          />
        </div>

        <UIFixedBottom>
          <UIButton
            type="button"
            onClick={handleSubmitUser}
            disabledAppearance={!hasAllRequiredInfos}
            text={isEditCustomerForm ? 'Salvar edições' : 'Adicionar cliente'}
          />
        </UIFixedBottom>
      </UIModal>

      {showAddressModal && (
        <UIModal
          showModal={showAddressModal}
          modalTitle="Adicionar endereço"
          setShowModal={() => {
            setShowAddressModal(false);
            if (addressToEdit?.street) {
              setAddressToEdit({});
            }
          }}
        >
          <AddressForm
            addressToEdit={addressToEdit}
            handleOnSubmit={handleOnSubmitAddressForm}
          />
        </UIModal>
      )}
    </>
  );
};

ClientFormModal.propTypes = {
  addUserId: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  addUserName: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  addUserPhone: PropTypes.func.isRequired,
  resetUser: PropTypes.func.isRequired,
  closeChooseClientModal: PropTypes.func.isRequired,
  resetCartDeliveryState: PropTypes.func.isRequired,
  addAdressesOnAdressGroup: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    firstOrderDate: PropTypes.string.isRequired,
    addresses: PropTypes.arrayOf(PropCustomerAddress),
    orders: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalSpent: PropTypes.number.isRequired,
    establishmentId: PropTypes.string.isRequired,
    lastOrderDate: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
  { ...UserActions, ...CartActions, ...AddressesActions },
  dispatch,
);

export default connect(null, mapDispatchToProps)(ClientFormModal);
