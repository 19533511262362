import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CartFormName from './Name';
import CartCoupon from '../Coupon';
import CartFormPhone from './Phone';
import CartFormTable from './Table';
import CartOrderOrigin from '../OrderOrigin';
import CartFixedButton from '../FixedButton';
import CartDeliveryType from '../DeliveryType';
import CartFormSelectCustomer from './AddClient';
import CartFormPaymentMethod from './PaymentMethod';
import CartFormSelectAddress from './SelectAddress';
import CartFormScheduleOrder from './ScheduleOrder';
import CartFormHowToReceive from './HowToReceive';
import CartFormAddCustomDeliveryRate from './DeliveryRate';
import * as UserActions from '../../../store/modules/user/actions';
import * as CartActions from '../../../store/modules/cart/actions';

const CartForm = ({
  orderOrigin, isOnlineCommand, informationalUse,
}) => (
  <div>
    {(!isOnlineCommand || (isOnlineCommand && orderOrigin)) && (
      <CartCoupon informationalUse={informationalUse} />
    )}

    {isOnlineCommand && (
      <CartOrderOrigin />
    )}

    {(isOnlineCommand && orderOrigin) && (
      <CartFormSelectCustomer />
    )}

    {(!isOnlineCommand || (isOnlineCommand && orderOrigin)) && (
      <>
        {orderOrigin === 'table' && (
          <CartFormTable />
        )}

        {orderOrigin !== 'table' && (
          <>
            <CartDeliveryType informationalUse={informationalUse} />
            <CartFormSelectAddress informationalUse={informationalUse} />
            <CartFormHowToReceive informationalUse={informationalUse} />
            <CartFormScheduleOrder informationalUse={informationalUse} />
            <CartFormAddCustomDeliveryRate />
            <CartFormPaymentMethod informationalUse={informationalUse} />
          </>
        )}
      </>
    )}

    {(!isOnlineCommand && !informationalUse) && (
      <>
        <CartFormName />
        <CartFormPhone />
      </>
    )}

    {!informationalUse && (
      <CartFixedButton />
    )}
  </div>
);

CartForm.propTypes = {
  orderOrigin: PropTypes.string.isRequired,
  isOnlineCommand: PropTypes.bool.isRequired,
  informationalUse: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  orderOrigin: state.cart.orderOrigin,
  lastOrderId: state.system.lastOrderId,
  isOnlineCommand: state.establishment.isOnlineCommand,
});

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...CartActions, ...UserActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CartForm);
