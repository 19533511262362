import { app } from '../../../index';
import { store } from '../../../../store';

const getOrdersBasedOnNowTime = async () => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();
  const todayDate = new Date();

  if (todayDate.getHours() < 5) {
    const yersterdayOn5Am = new Date();
    yersterdayOn5Am.setDate(todayDate.getDate() - 1);
    yersterdayOn5Am.setHours(5, 0, 0);

    const timestampYersterdayOn5Am = yersterdayOn5Am.getTime();

    const data = await db
      .collection('orders')
      .where('establishmentId', '==', id)
      .where('timestamp', '>=', timestampYersterdayOn5Am)
      .orderBy('timestamp')
      .get();

    return data;
  }

  const todayOn5Am = new Date().setHours(5, 0, 0);

  const data = await db
    .collection('orders')
    .where('establishmentId', '==', id)
    .where('timestamp', '>=', todayOn5Am)
    .orderBy('timestamp')
    .get();

  return data;
};

export const getOrderById = async (orderId) => {
  const db = app.firestore();
  const { establishment: { id } } = store.getState();

  const data = await db
    .collection('orders')
    .doc(orderId)
    .get();

  const orderData = data.data();

  if (orderData && orderData.establishmentId === id) {
    return {
      ...orderData,
      id: data.id,
    };
  }

  return false;
};

export const getAllTodayOrdersData = async () => {
  const ordersBasedOnNowTime = await getOrdersBasedOnNowTime();

  const orders = ordersBasedOnNowTime.docs.map((doc) => (
    { id: doc.id, ...doc.data() }
  ));

  return orders;
};

export const getAllTodayOrdersLength = async () => {
  const ordersBasedOnNowTime = await getOrdersBasedOnNowTime();

  return ordersBasedOnNowTime.size;
};
