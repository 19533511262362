import styled, { css } from 'styled-components';
import { Element } from 'react-scroll';

export const UIRequiredBlock = styled(Element)`
  display: inline-flex;
  span {
    font-size: 12px;
    font-weight: bold;
    padding: 2px 10px;
    border-radius: 10px;
    background: var(--default-gray);
  }
  
  ${({ $darkStyle }) => $darkStyle && css`
      span {
        color: #FFF;
        background: var(--default-dark-gray);
      }
    `}
  

  &.UIRequiredBlock {
    &--emphasize {
      span {
        color: #fff;
        background: var(--alert-color);
      }
    }
  }

  svg {
    font-size: 20px;
    fill: var(--sucess-color);
  }
`;
