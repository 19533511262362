import styled from 'styled-components';

export const MenuCoupon = styled.div`
  display: flex;
  padding: 16px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: var(--primary-color-opacity);
  span, strong {
    text-align: center;
    color: var(--primary-color);
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 10px;
    }
  }
`;

export const CouponTitle = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  strong {
    margin-left: 5px;
    line-height: 13px;
  }
`;
